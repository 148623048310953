import { Typo } from '@digital-at-vallourec/steel-design-system-react';
import styled from '@emotion/styled';
import React from 'react';

import { $grey20 } from '../../../styles/colors';

interface GridOverlayContainerProps {
  text: string;
  color?: string;
}

const Container = styled.div`
  align-items: center;
  height: 100%;
  justify-content: center;
  width: 100%;
  display: flex;
`;

export const GridOverlayContainer = (props: GridOverlayContainerProps) => (
  <Container data-testid="grid-overlay-container">
    <Typo
      variant="buttonMedium"
      fontSize={18}
      color={props.color || $grey20}
      data-testid="grid-overlay-container-text"
    >
      {props.text}
    </Typo>
  </Container>
);
