import { TypeOf, array, boolean, date, number, object, string } from 'zod';

import { REQUIRED_FIELD } from '../../utils';

const sideSchema = object({
  end: string().min(1, REQUIRED_FIELD),
  connection: string().min(1, REQUIRED_FIELD),
  od_inch: string().min(1, REQUIRED_FIELD),
  weight: number(),
  application: string().min(1, REQUIRED_FIELD),
  special_bevel: boolean(),
  oriented_connection: boolean(),
});

export const newJobSchema = object({
  job_type: string().min(1, REQUIRED_FIELD),
  type_of_part: string().min(1, REQUIRED_FIELD),
  date: date(),
  country_of_delivery: string().min(1, REQUIRED_FIELD),
  final_destination: string().min(1, REQUIRED_FIELD),
  customer: string().min(1, REQUIRED_FIELD),
  end_user: string().min(1, REQUIRED_FIELD),
  work_order: string().min(1, REQUIRED_FIELD),
  waiver_request: string().optional(),
  grade_family: string().min(1, REQUIRED_FIELD),
  yield_strength: number().optional(),
  grade_name: string().min(1, REQUIRED_FIELD),
  sides: array(sideSchema),
  displayed_sides: array(sideSchema),
  number_of_parts: number(),
});

export const newJobDefaultValues: FormSchemaType = {
  job_type: '',
  type_of_part: '',
  date: new Date(),
  country_of_delivery: '',
  final_destination: '',
  customer: '',
  end_user: '',
  work_order: '',
  waiver_request: '',
  grade_family: '',
  grade_name: '',
  yield_strength: '' as any,
  sides: [],
  displayed_sides: [],
  number_of_parts: 1,
};

// infer schema to generate typescript type
export type FormSchemaType = TypeOf<typeof newJobSchema>;
