import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { Address, PickupAddress } from '../../interfaces/address';
import { UserDetails, UserMyVamRole, UserStore } from '../../interfaces/user';
import { AddressType } from '../../utils';
import { INTERNAL_LICENSEE_TYPE } from '../../utils/constants/user';
import { mappingUserToState } from '../../utils/functions/user';
import * as userAPI from '../api/user-api';
import * as vegAPI from '../api/veg-api';
import { RootState } from './store';

/**
 * state model
 */
export interface UserState {
  userDetails: UserDetails | null;
  consigneeAddresses: Address[];
  deliveryAddresses: Address[];
  pickupAddresses: PickupAddress[];
  auth0Roles: UserMyVamRole[];
}

/**
 * set initial state
 */
const initialState: UserState = {
  userDetails: null,
  consigneeAddresses: [],
  deliveryAddresses: [],
  pickupAddresses: [],
  auth0Roles: [],
};

/**
 * selectors for this slice
 */
export const selectUser = (state: RootState) => state.user.userDetails;
// this selector is used only for internal licensee vam
export const selectDepotId = (state: RootState) => state.user.userDetails?.depotId;
// this selector is used for all licensee companies except internal licensee vam
export const selectPreferredPickupAddressId = (state: RootState) =>
  state.user.userDetails?.preferredPickupAddressId;
export const selectLicenseeNumber = (state: RootState) => state.user.userDetails?.licenseeNumber;
export const selectLicenseeName = (state: RootState) => state.user.userDetails?.licenseeName;
export const selectUserId = (state: RootState) => state.user.userDetails?.idUserMyVam;
export const selectIsInternalUser = (state: RootState) =>
  state.user.userDetails?.licenseeType === INTERNAL_LICENSEE_TYPE;
export const selectIsExternallUser = (state: RootState) =>
  state.user.userDetails?.licenseeType !== INTERNAL_LICENSEE_TYPE;
export const selectConsigneeAddresses = (state: RootState) => state.user.consigneeAddresses;
export const selectDeliveryAddresses = (state: RootState) => state.user.deliveryAddresses;
export const selectPickupAddresses = (state: RootState) =>
  state.user.pickupAddresses as PickupAddress[];
export const selectAuth0Roles = (state: RootState) => state.user.auth0Roles as UserMyVamRole[];

export const fetchConsigneeAddressesThunk = createAsyncThunk<
  Address[],
  number,
  {
    rejectValue: string;
  }
>('user/fetchConsigneeAddresses', async (licenseeNumber: number, thunkApi) => {
  try {
    const response = await vegAPI.fetchAddresses(licenseeNumber, AddressType.Consignee);
    // return value becomes the `fulfilled` action payload
    return response.data;
  } catch (error) {
    // return error becomes the `rejected` action payload
    // axios error?
    if (error instanceof AxiosError && error.response) {
      // return error message
      return thunkApi.rejectWithValue(`${error.response.data}`);
    }
    // return error
    throw error;
  }
});

export const fetchDeliveryAddressesThunk = createAsyncThunk<
  Address[],
  number,
  {
    rejectValue: string;
  }
>('user/fetchDeliveryAddresses', async (licenseeNumber: number, thunkApi) => {
  try {
    const response = await vegAPI.fetchAddresses(licenseeNumber, AddressType.Delivery);
    // return value becomes the `fulfilled` action payload
    return response.data;
  } catch (error) {
    // return error becomes the `rejected` action payload
    // axios error?
    if (error instanceof AxiosError && error.response) {
      // return error message
      return thunkApi.rejectWithValue(`${error.response.data}`);
    }
    // return error
    throw error;
  }
});

export const fetchPickupAddressesThunk = createAsyncThunk<
  PickupAddress[],
  null,
  {
    rejectValue: string;
  }
>('user/fetchPickupAddresses', async (_, thunkApi) => {
  try {
    const response = await vegAPI.fetchPickupAddresses();
    // return value becomes the `fulfilled` action payload
    return response.data;
  } catch (error) {
    // return error becomes the `rejected` action payload
    // axios error?
    if (error instanceof AxiosError && error.response) {
      // return error message
      return thunkApi.rejectWithValue(`${error.response.data}`);
    }
    // return error
    throw error;
  }
});

export const fetchAuth0RolesThunk = createAsyncThunk<
  UserMyVamRole[],
  null,
  {
    rejectValue: string;
  }
>('user/fetchAuth0Roles', async (_, thunkApi) => {
  try {
    const response = await userAPI.getAllAuth0Roles();
    // return value becomes the `fulfilled` action payload
    return response.data;
  } catch (error) {
    // return error becomes the `rejected` action payload
    // axios error?
    if (error instanceof AxiosError && error.response) {
      // return error message
      return thunkApi.rejectWithValue(`${error.response.data}`);
    }
    // return error
    throw error;
  }
});

/**
 * slice
 */
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserStore>) => {
      state.userDetails = mappingUserToState(action.payload);
    },
    setAuth0Roles: (state, action: PayloadAction<UserMyVamRole[]>) => {
      state.auth0Roles = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetch consignee addresses request
    // -- successfull
    builder.addCase(fetchConsigneeAddressesThunk.fulfilled, (state, action) => {
      // update state
      state.consigneeAddresses = action.payload;
    });
    // -- failure
    builder.addCase(fetchConsigneeAddressesThunk.rejected, (state) => {
      // reset state
      state.consigneeAddresses = [];
    });

    // fetch delivery addresses request
    // -- successfull
    builder.addCase(fetchDeliveryAddressesThunk.fulfilled, (state, action) => {
      // update state
      state.deliveryAddresses = action.payload;
    });
    // -- failure
    builder.addCase(fetchDeliveryAddressesThunk.rejected, (state) => {
      // reset state
      state.deliveryAddresses = [];
    });

    // fetch pickup addresses request
    // -- successfull
    builder.addCase(fetchPickupAddressesThunk.fulfilled, (state, action) => {
      // update state
      state.pickupAddresses = action.payload;
    });
    // -- failure
    builder.addCase(fetchPickupAddressesThunk.rejected, (state) => {
      // reset state
      state.pickupAddresses = [];
    });

    // fetch auth0 roles request
    // -- successfull
    builder.addCase(fetchAuth0RolesThunk.fulfilled, (state, action) => {
      // update state
      state.auth0Roles = action.payload;
    });
    // -- failure
    builder.addCase(fetchAuth0RolesThunk.rejected, (state) => {
      // reset state
      state.auth0Roles = [];
    });
  },
});

/**
 * actions for this slice
 */
export const { setUser, setAuth0Roles } = userSlice.actions;
