import { TFunction } from 'i18next';

import { LabelValue } from '../../interfaces/label-value';

export function arrayToLabelValuesByRef(
  arr: any[],
  valueRef: string,
  labelRef: string,
  labelKeyPrefix = ''
): LabelValue[] {
  return labelKeyPrefix
    ? arr.map((item) => ({
        value: item[valueRef],
        labelKey: labelKeyPrefix + item[valueRef].toString(),
      }))
    : arr.map((item) => ({
        value: item[valueRef],
        label: item[labelRef].toString(),
      }));
}

/**
 * @param arr
 * @returns labelKeyPrefix is ll be used for i18n.
 */
export function arrayToLabelValues(arr: any[] = [], labelKeyPrefix = ''): LabelValue[] {
  return labelKeyPrefix
    ? arr.map((value) => ({
        value,
        labelKey: labelKeyPrefix + value,
      }))
    : arr.map((value) => ({
        value,
        label: value,
      }));
}

export function toLabelValues(obj: any = {}, labelKeyPrefix = ''): LabelValue[] {
  return arrayToLabelValues(Object.values(obj), labelKeyPrefix);
}

export function getLabelByValue(
  arr: LabelValue[],
  valueRef: string,
  t?: TFunction<string, undefined>
): string {
  const item = arr.find((element) => element.value === valueRef);

  return t ? t(item?.labelKey) : item?.label;
}
