import {
  SectionTitle,
  SimpleButton,
  Tabs,
  Typo,
} from '@digital-at-vallourec/steel-design-system-react';
import { Download } from '@mui/icons-material';
import { GridPaginationModel, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import { saveAs } from 'file-saver';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useFetchShipmentsByStatus } from '../../../hooks/useFetchShipmentsByStatus';
import { ManageShipmentsResult } from '../../../interfaces/shipping';
import { exportDispatchAllDocuments } from '../../../services/api/shipping-api';
import { $secondaryBlue } from '../../../styles/colors';
import {
  BOOKING_STATUS_COLORS,
  BookingStatus,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGINATION_MODEL,
  ShippingSource,
} from '../../../utils';
import { redirectTo } from '../../../utils/functions/helpers';
import { VamDataGrid } from '../../data-grid/VamDataGrid';
import { ContainerOutlet } from '../../layout/ContainerOutlet/ContainerOutlet';
import { ContainerCard, MenuOptionsProps, VamMoreOptions } from '../../shared';
import { ManageBookingCommon, QuickAccessButtonProps } from '../manage-booking/ManageBookingCommon';
import ShowShipmentDetail from './ShowShipmentDetail';
import { accessButtonSubtitleKey, accessButtonTitleKey, manageShipmentsColumns } from './utils';

export const ManageShipments = () => {
  const { t } = useTranslation('shipment');
  const { t: tGauge } = useTranslation('gauge');
  const { t: tCommon } = useTranslation('common');
  const [status, setStatus] = React.useState<BookingStatus>(undefined);
  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>(DEFAULT_PAGINATION_MODEL);
  const [sort, setSort] = React.useState<GridSortModel>([]);
  const [shippingSource, setShippingSource] = React.useState<ShippingSource>(null);
  const [openShipmentDetails, setOpenShipmentDetails] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<ManageShipmentsResult>(null);

  const location = useLocation();
  const navigate = useNavigate();

  const { datagrid: shipments } = useFetchShipmentsByStatus(
    status,
    sort,
    paginationModel.page,
    shippingSource
  );
  const optionsMenuManageShipments: MenuOptionsProps[] = [
    {
      label: t('manageShipments.actions.confirmReturn'),
      handleItemClick: (_, __, row) => {
        if (row.shipping_source === ShippingSource.Dispatch) {
          enqueueSnackbar(t('manageShipments.actions.confirmReturnUnauthorized'), {
            variant: 'warning',
          });
          return;
        }
        redirectTo('confirm-return', navigate, {
          state: { from: location, currentSelectedShipment: row },
        });
      },
    },
  ];

  /* istanbul ignore next */
  const handleTabChange = (status: BookingStatus) => {
    setPaginationModel({ ...paginationModel, page: 0 });
    setStatus(status);
    switch (status) {
      case BookingStatus.ShipmentOngoing:
        setShippingSource(ShippingSource.Dispatch);
        break;
      case BookingStatus.ReturnOngoing:
        setShippingSource(ShippingSource.Return);
        break;
      default:
        setShippingSource(null);
        break;
    }
  };

  /* istanbul ignore next */
  const handleDetailShipment = ($event, row: ManageShipmentsResult) => {
    setOpenShipmentDetails(true);
    setSelectedRow(row);
  };

  /* istanbul ignore next */
  const handlePrintShipment = ($event, row: ManageShipmentsResult) => {
    exportDispatchAllDocuments(row.included_bookings)
      .then(({ data }) => {
        saveAs(data, `dispatch_documents_licensee_nb_${row.licensee_number}.zip`);
      })
      .catch((error) => {
        enqueueSnackbar(tCommon('notification.printError'), {
          preventDuplicate: true,
          variant: 'error',
        });
        console.error(error);
      });
  };

  /* istanbul ignore next */
  const tabs = [
    {
      label: t('manageShipments.tabs.all'),
      onClick: () => handleTabChange(undefined),
    },
    {
      label: t(`manageShipments.tabs.${BookingStatus.ShipmentOngoing}`),
      onClick: () => handleTabChange(BookingStatus.ShipmentOngoing),
    },
    {
      label: t(`manageShipments.tabs.${BookingStatus.ReturnOngoing}`),
      onClick: () => handleTabChange(BookingStatus.ReturnOngoing),
    },
    {
      label: t(`manageShipments.tabs.${BookingStatus.Delivered}`),
      onClick: () => handleTabChange(BookingStatus.Delivered),
    },
    {
      label: t(`manageShipments.tabs.${BookingStatus.ReturnReceived}`),
      onClick: () => handleTabChange(BookingStatus.ReturnReceived),
    },
  ];

  /* istanbul ignore next */
  const accessButtons: QuickAccessButtonProps[] = [
    {
      title: t(accessButtonTitleKey, { context: 'dispatch' }),
      subtitle: t(accessButtonSubtitleKey, { context: 'dispatch' }),
      dataTestId: 'manage-shipments-dispatch-access-button-data-test-id',
      handleClick: () => redirectTo('../dispatch', navigate, { state: { from: location } }),
    },
    {
      title: t(accessButtonTitleKey, { context: 'search' }),
      subtitle: t(accessButtonSubtitleKey, { context: 'search' }),
      dataTestId: 'manage-shipments-search-access-button-data-test-id',
      handleClick: () => redirectTo('../rental-data', navigate, { state: { from: location } }),
    },
    {
      title: t(accessButtonTitleKey, { context: 'currentRentals' }),
      subtitle: t(accessButtonSubtitleKey, { context: 'currentRentals' }),
      dataTestId: 'manage-shipments-current-rentals-access-button-data-test-id',
      handleClick: () => redirectTo('../current-rentals', navigate, { state: { from: location } }),
    },
  ];

  return (
    <ContainerOutlet>
      <ShowShipmentDetail
        isOpen={openShipmentDetails}
        setIsOpen={setOpenShipmentDetails}
        shipmentRow={selectedRow}
      />
      <ManageBookingCommon
        title={t('manageShipments.title')}
        subtitle={tGauge('tag')}
        breadcrumbRoutes={[
          {
            breadcrumb: t('manageShipments.title'),
            path: '#',
          },
        ]}
        accessButtons={accessButtons}
      />
      <Tabs
        color="secondary"
        className="tw-ml-4"
        tabs={tabs}
        sx={{
          '.MuiBadge-badge': {
            backgroundColor: $secondaryBlue,
          },
        }}
      />
      <ContainerCard
        width="100%"
        maxWidth="100%"
        containerCardClass="tw-pt-3"
        dataTestId="booking-container"
        avatarHeaderNode={
          <SectionTitle
            title={t(`manageShipments.card_${status || 'all'}.title`)}
            description={t(`manageShipments.card_${status || 'all'}.subtitle`)}
          />
        }
      >
        <VamDataGrid
          columns={manageShipmentsColumns.concat([
            {
              field: 'status',
              headerName: 'STATUS',
              renderCell: /* istanbul ignore next */ (
                params: GridRenderCellParams<any, BookingStatus>
              ) => (
                <Typo
                  variant="body2"
                  color={BOOKING_STATUS_COLORS[params.value]}
                  fontWeight={500}
                  className="text-ellipsis"
                  textTransform="uppercase"
                >
                  {tGauge(`manageBookingDetail.changeStatusDialog.status.${params.value}`)}
                </Typo>
              ),
              minWidth: 120,
              flex: 1,
            },
            {
              field: 'action',
              align: 'right',
              flex: 1,
              renderHeader: /* istanbul ignore next */ () => <></>,
              cellClassName: 'outline-focus-none',
              renderCell: /* istanbul ignore next */ ({ row }) => {
                return (
                  <div className="tw-flex tw-gap-2">
                    <SimpleButton
                      size="small"
                      variant="secondary-dark"
                      onClick={($event) => handleDetailShipment($event, row)}
                      data-testid="show-shipment-detail-btn"
                    >
                      <Typo variant="body1" fontWeight={400}>
                        {t('manageShipments.detailButton')}
                      </Typo>
                    </SimpleButton>
                    <SimpleButton
                      size="small"
                      variant="icon-secondary-dark-blue"
                      data-testid="print-documents-btn"
                      onClick={($event) => handlePrintShipment($event, row)}
                    >
                      <Download />
                    </SimpleButton>
                    <VamMoreOptions
                      size="small"
                      options={optionsMenuManageShipments}
                      row={row}
                      data-testid="more-options-icon"
                    />
                  </div>
                );
              },
              sortable: false,
              hideable: false,
              disableColumnMenu: true,
              minWidth: 240,
            },
          ])}
          rows={shipments}
          height={500}
          disableColumnFilter
          showRowLeftThickBorder
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSize={DEFAULT_PAGE_SIZE}
          paginationMode="server"
          sortingMode="server"
          filterMode="server"
          sortModel={sort}
          onSortModelChange={setSort}
          disableRowSelectionOnClick
        />
      </ContainerCard>
    </ContainerOutlet>
  );
};
