import { TypeOf, array, number, object, string } from 'zod';

import { Currency, DispatchBy, REQUIRED_FIELD, ShippingSource } from '../../../utils';
import { getDhlAccountNumber } from '../../../utils/functions/shipping';

export const dispatchSetFormSchema = object({
  application: string().min(1, REQUIRED_FIELD),
  business_number: string().min(1, REQUIRED_FIELD),
  case_weight: number(),
  connection: string().min(1, REQUIRED_FIELD),
  end: string().min(1, REQUIRED_FIELD),
  gauge_location: string().min(1, REQUIRED_FIELD),
  gauge_weight: number(),
  max_weight: number(),
  min_weight: number(),
  od_inch: number(),
  option: string().min(1, REQUIRED_FIELD),
  proforma_value: string().min(1, REQUIRED_FIELD),
  set_id: number(),
  total_weight: number(),
});

export const dispatchFormSchema = object({
  nb_boxes: number(),
  dispatch_by: string().min(1, REQUIRED_FIELD),
  account_number: string().optional(),
  air_way_bill: string().min(1, REQUIRED_FIELD),
  incoterms: string().min(1, REQUIRED_FIELD),
  sets: array(dispatchSetFormSchema),
  comments: string().optional(),
  currency: string().min(1, REQUIRED_FIELD),
});

export const defaultValues: FormSchemaType = {
  account_number: getDhlAccountNumber(ShippingSource.Dispatch),
  air_way_bill: '',
  comments: '',
  currency: Currency.EURO,
  dispatch_by: DispatchBy.DHL,
  incoterms: 'EXW',
  nb_boxes: 1,
  sets: [],
};

export type FormSchemaType = TypeOf<typeof dispatchFormSchema>;
