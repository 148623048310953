import { zodResolver } from '@hookform/resolvers/zod';
import { Check } from '@mui/icons-material';
import { Grid, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { PickupAddress } from '../../interfaces/address';
import { updatePickupAddress } from '../../services/api/veg-api';
import { fetchPickupAddressesThunk, selectUser } from '../../services/store/userSlice';
import { isoCountries } from '../../utils/constants/countries/countries_code_alpha_2';
import { strToNumber } from '../../utils/functions';
import { formatNameAndId, redirectTo } from '../../utils/functions/helpers';
import { ContainerOutlet } from '../layout/ContainerOutlet/ContainerOutlet';
import { CtaLoadingButton, VamPageTitle } from '../shared';
import { FormSchemaType, creationFormSchema } from './GaugeDepotForm';
import { GaugeDepotInformations } from './GaugeDepotInformations';
import { GeneralInformations } from './GeneralInformations';

export default function UpdateGaugeDepot() {
  const { t } = useTranslation('gauge_depot', { keyPrefix: 'updateDepot' });
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const { data } = useLoaderData() as { data: PickupAddress };
  const navigate = useNavigate();
  const params = useParams();
  const { firstName, lastName } = useAppSelector(selectUser);
  const pickupAddressId = params['pickupAddressId'];
  const defaultValues: FormSchemaType = {
    site: data?.site,
    street: data?.street,
    town: data?.town,
    country_code: data?.country_code,
    email: data?.email,
    zip_code: data?.zip_code,
    phone_number: data?.phone_number,
    is_active: data?.is_active,
    licensee_number: `${data?.licensee_number}`,
  };
  const methods = useForm<FormSchemaType>({
    defaultValues: defaultValues,
    resolver: zodResolver(creationFormSchema),
    mode: 'onChange',
  });
  const { handleSubmit } = methods;
  const dispatch = useAppDispatch();

  /* istanbul ignore next */
  const updateDepot: SubmitHandler<FormSchemaType> = (data) => {
    const dataApi: PickupAddress = {
      site: data.site,
      street: data.street,
      town: data.town,
      country_code: data.country_code,
      country: isoCountries[data.country_code],
      email: data.email,
      zip_code: data.zip_code,
      phone_number: data.phone_number,
      is_active: data.is_active,
      licensee_number: strToNumber(data.licensee_number) as number,
      modified_by: `${firstName} ${lastName}`,
    };
    updatePickupAddress(strToNumber(pickupAddressId) as number, dataApi)
      .then(() => {
        dispatch(fetchPickupAddressesThunk());
        redirectTo('../depot-listing', navigate, {
          state: { from: location },
        });
        enqueueSnackbar(t('updateDepotSuccess'), {
          preventDuplicate: true,
          variant: 'success',
        });
      })
      .catch((error) => {
        enqueueSnackbar(t('updateDepotError'), {
          preventDuplicate: true,
          variant: 'error',
        });
        console.error(error);
      });
  };

  return (
    <ContainerOutlet>
      <FormProvider {...methods}>
        <Stack direction="row" justifyContent="space-between" className="tw-mb-6">
          <Stack direction="row" spacing={2}>
            <VamPageTitle
              subTitle={t('tag')}
              title={t('title')}
              detailsLabelBox={[
                {
                  label: formatNameAndId(pickupAddressId, data.site),
                },
              ]}
              breadcrumbRoutes={[
                {
                  breadcrumb: t('breadcrumb.depotListing'),
                  path: '../depot-listing',
                },
                {
                  breadcrumb: t('breadcrumb.updateDepot'),
                  path: '#',
                },
              ]}
            />
          </Stack>
          <Stack direction="row" spacing={3} alignItems="center">
            <CtaLoadingButton
              buttonText={t('updateButton')}
              type="submit"
              variant="cta"
              data-testid="submit-update-depot-btn"
              startIcon={<Check />}
              onClick={handleSubmit(updateDepot)}
              disabled={
                methods.formState.isLoading ||
                !methods.formState.isValid ||
                !!Object.keys(methods.formState.errors).length
              }
            />
          </Stack>
        </Stack>
        <Grid container spacing={2} sx={{ backgroundColor: 'transparent' }}>
          <Grid item xs={3}>
            <GeneralInformations data={data} />
          </Grid>
          <Grid item xs={9}>
            <GaugeDepotInformations isUpdate />
          </Grid>
        </Grid>
      </FormProvider>
    </ContainerOutlet>
  );
}
