import { ApplicabilityValueElement } from '../../../interfaces/documentation';
import { filterFormSchemaType } from '../CreatePublicationForm';

export enum RowAction {
  remove = 'remove',
  add = 'add',
  update = 'update',
}

export enum FieldType {
  numeric = 'number',
  string = 'string',
}

export enum FieldOperator {
  EQUAL = 'equal',
  IN = 'in',
  IS_A_RANGE_BETWEEN = 'range',
}

export const applicabilityFields = [
  'connection_type',
  'connection',
  'option',
  'od_inch',
  'weight',
  'thickness',
  'end',
  'application',
  'special_clearance',
  'special_bevel',
  'design_option',
  'customer',
  'grade_family',
  'yield_strength',
  'grade_name',
];

export const applicabilityOperator = {
  connection_type: FieldType.string,
  connection: FieldType.string,
  option: FieldType.string,
  od_inch: FieldType.numeric,
  weight: FieldType.numeric,
  thickness: FieldType.numeric,
  grade_family: FieldType.string,
  yield_strength: FieldType.numeric,
  grade_name: FieldType.string,
  end: FieldType.string,
  application: FieldType.string,
};

export const operatorsOptions = {
  [FieldType.string]: [FieldOperator.EQUAL, FieldOperator.IN],
  [FieldType.numeric]: [FieldOperator.EQUAL, FieldOperator.IN, FieldOperator.IS_A_RANGE_BETWEEN],
};

export function mapToDistinctProducts(values: filterFormSchemaType): ApplicabilityValueElement {
  let value: string | string[] | { gte: string; lte: string };

  switch (values.field_operator) {
    case FieldOperator.EQUAL:
    case FieldOperator.IN:
      value = values.field_value;
      break;
    case FieldOperator.IS_A_RANGE_BETWEEN:
      value = {
        gte: values.gte_field_value,
        lte: values.lte_field_value,
      };
      break;
    default:
      throw new Error(`Unknown field operator: ${values.field_operator}`);
  }

  return {
    field: values.field_name,
    operator: values.field_operator as FieldOperator,
    value,
  };
}
