import { Button, CardActionArea, CardActions, CardHeader } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import styles from './Card.module.scss';

interface CardProps {
  title: string;
  subheader?: string;
  text?: string;
  functionComponent?: Function;
  imgUrl?: string;
  imgAlt?: string;
  action?: boolean;
  class?: string;
}

function CardAction(action?: boolean) {
  return action ? (
    <CardActions>
      <Button type="submit" className="btn nav-btn-red">
        Confirm
      </Button>
    </CardActions>
  ) : null;
}

export function VamCard(props: CardProps) {
  return (
    <div className={`${styles.card} tw-py-4 ${props.class}`}>
      <Card sx={{ maxWidth: 420, minWidth: 320 }}>
        <CardActionArea>
          <CardHeader
            className={styles.cardHeader}
            title={props.title}
            subheader={props.subheader}
          ></CardHeader>
          <CardContent className={styles.cardContent}>
            <Typography component={'div'} variant="body2" color="text.secondary">
              <div className={props.text ? 'tw-p-8' : ''}>
                {props.functionComponent ? props.functionComponent() : props.text}
              </div>
            </Typography>
          </CardContent>
        </CardActionArea>
        {CardAction(props.action)}
      </Card>
    </div>
  );
}
