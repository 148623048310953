import { TypeOf, array, object, string } from 'zod';

import { REQUIRED_FIELD } from '../../../../utils';
import { makeReturnSetFormSchema } from '../../make-return/MakeReturnForm';

export const confirmReturnFormSchema = object({
  has_return_delivery_cost: string().optional(),
  return_delivery_cost: string().optional().nullable(),
  sets: array(makeReturnSetFormSchema),
  comments: string().optional().nullable(),
  currency: string().min(1, REQUIRED_FIELD),
});

export const defaultValues: FormSchemaType = {
  has_return_delivery_cost: 'false',
  return_delivery_cost: null,
  sets: [],
  comments: '',
};

export type FormSchemaType = TypeOf<typeof confirmReturnFormSchema>;
