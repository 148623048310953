import { TypeOf, object, string } from 'zod';

import { INVALID_NUMERIC, REQUIRED_FIELD, numberRegexWithTwoDigit } from '../../utils';

export const creationFormSchema = object({
  designation: string().min(1, REQUIRED_FIELD),
  width: string()
    .min(1, REQUIRED_FIELD)
    .refine((value) => numberRegexWithTwoDigit.test(value), {
      message: INVALID_NUMERIC,
    }),
  length: string()
    .min(1, REQUIRED_FIELD)
    .refine((value) => numberRegexWithTwoDigit.test(value), {
      message: INVALID_NUMERIC,
    }),
  height: string()
    .min(1, REQUIRED_FIELD)
    .refine((value) => numberRegexWithTwoDigit.test(value), {
      message: INVALID_NUMERIC,
    }),
  weight: string()
    .min(1, REQUIRED_FIELD)
    .refine((value) => numberRegexWithTwoDigit.test(value), {
      message: INVALID_NUMERIC,
    }),
  price: string()
    .min(1, REQUIRED_FIELD)
    .refine((value) => numberRegexWithTwoDigit.test(value), {
      message: INVALID_NUMERIC,
    }),
  case_status: string().min(0, REQUIRED_FIELD),
});

export const defaultValues: FormSchemaType = {
  designation: '',
  height: '',
  width: '',
  length: '',
  weight: '',
  price: '',
  case_status: 'active',
};

// infer schema to generate typescript type
export type FormSchemaType = TypeOf<typeof creationFormSchema>;

const updateCaseForm = object({
  creation_date: string().optional().nullable(),
  modification_date: string().optional().nullable(),
  created_by: string().optional().nullable(),
  modified_by: string().optional().nullable(),
});

export const updateFormSchema = creationFormSchema.merge(updateCaseForm);
