import { GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';

import { columnRenderHeader } from '../../components/data-grid/utils/functions';
import { RentalTypeNode } from '../../components/shared';
import { RentalType } from '../../utils';
import { getDesignationProductWithSetId } from '../../utils/functions';

export const returnBookingsColumns: GridColDef[] = [
  {
    field: 'business_number',
    headerName: 'BUSINESS #',
    minWidth: 80,
    flex: 1,
  },
  {
    field: 'licensee_name',
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('LICENSEE', 'NAME'),
    minWidth: 120,
    flex: 1,
  },
  {
    field: 'rental_type',
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('RENTAL', 'TYPE'),
    minWidth: 80,
    renderCell: /* istanbul ignore next */ (params: GridRenderCellParams<any, RentalType>) =>
      RentalTypeNode(params.value),
  },
  {
    field: 'connection',
    headerName: 'CONNECTION',
    minWidth: 120,
    flex: 1,
  },
  {
    field: 'designation',
    headerName: 'DESIGNATION',
    minWidth: 300,
    flex: 1,
    renderCell: /* istanbul ignore next */ (params: GridRenderCellParams<any, RentalType>) =>
      getDesignationProductWithSetId(params.row.set_id, {
        connection: params.row.connection,
        application: params.row.application,
        end: params.row.end,
        od_txt: params.row.od_txt,
        weight_thickness: params.row.weight_thickness,
      }),
  },
  {
    field: 'gauge_location',
    headerName: 'Gauge location',
    minWidth: 120,
    flex: 1,
  },
  {
    field: 'end_date',
    minWidth: 120,
    flex: 1,
    headerName: 'END DATE',
  },
];

export const RETURN_BOOKINGS_SORT: GridSortModel = [{ field: 'end_date', sort: 'asc' }];
