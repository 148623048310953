/* istanbul ignore file */
import { AxiosInstance } from 'axios';

import { contextHttp, contextHttpWithSpinner } from './http-context';
import { docHttp, docHttpWithSpinner } from './http-documentation';
import { httpHypermediaAction } from './http-hypermedia-action';
import { userHttp, userHttpWithSpinner } from './http-user';
import { vegHttp, vegHttpWithSpinner } from './http-veg';

const AXIOS_INSTANCES = [
  userHttp,
  userHttpWithSpinner,
  vegHttp,
  vegHttpWithSpinner,
  httpHypermediaAction,
  contextHttp,
  contextHttpWithSpinner,
  docHttp,
  docHttpWithSpinner,
];

const setAxiosTokenInterceptor = async (
  axios: AxiosInstance,
  accessToken: string
): Promise<void> => {
  axios.interceptors.request.use(async (config) => {
    // TODO we check an access token if valid or not
    const requestConfig = config;
    if (accessToken) {
      requestConfig.headers.Authorization = `Bearer ${accessToken}`;
    }
    return requestConfig;
  });
};

export const setAllAxiosInstances = async (getAccessTokenSilently: Function): Promise<void> => {
  const accessToken = await getAccessTokenSilently();

  AXIOS_INSTANCES.forEach((instance) => {
    setAxiosTokenInterceptor(instance, accessToken);
  });
};
