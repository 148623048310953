import { Typo } from '@digital-at-vallourec/steel-design-system-react';
import { Sell } from '@mui/icons-material';
import { Box } from '@mui/material';
import { JSXElementConstructor, ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { $primaryBlack, $secondaryPaleBlue } from '../../../styles/colors';
import { isNeitherUndefinedNorNull } from '../../../utils/functions';

export function PriceBox({
  price,
}: {
  price: number;
}): ReactElement<any, string | JSXElementConstructor<any>> {
  const { t } = useTranslation('gauge');

  return (
    <Box
      sx={{
        display: 'flex',
        width: 182,
        height: 24,
        background: $secondaryPaleBlue,
        borderRadius: '4px',
        textAlign: 'center',
        lineHeight: 1,
      }}
    >
      <Sell
        fontSize="small"
        htmlColor={$primaryBlack}
        sx={{
          marginTop: '2px',
          marginLeft: '12px',
          marginRight: '8px',
        }}
      />
      <div>
        <Typo variant="button" color={$primaryBlack} fontWeight={400}>
          <Trans
            values={{ price: isNeitherUndefinedNorNull(price) ? price : '-' }}
            components={{ bold: <strong /> }}
          >
            {t('estimatePrice')}
          </Trans>
        </Typo>
      </div>
    </Box>
  );
}
