import { SimpleButton } from '@digital-at-vallourec/steel-design-system-react';
import { DialogActions, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { User } from '../../interfaces/user';
import { getLicenseeName } from '../../utils/functions';
import { VamDialog } from '../shared';

interface ChooseCompanyDialogProps {
  title: string;
  subTitle: string;
  accounts: User[];
  showChooseCompany: boolean;
  handleChooseCompany: Function;
}

export function ChooseCompanyDialog({
  title,
  subTitle,
  showChooseCompany,
  accounts,
  handleChooseCompany,
}: ChooseCompanyDialogProps) {
  const { t } = useTranslation('common');
  const [selectedCompany, setSelectedCompany] = React.useState<number>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCompany(+(event.target as HTMLInputElement).value);
  };

  return (
    <VamDialog
      title={title}
      subTitle={subTitle}
      isOpen={showChooseCompany}
      fullWidth
      maxWidth="sm"
      withCloseButton={false}
      dialogActions={
        <DialogActions className="!tw-justify-center ">
          <SimpleButton
            data-testid="confirm-change-licensee-button"
            variant="cta"
            id="confirm-change-licensee-button"
            disabled={!selectedCompany}
            size="large"
            onClick={() => {
              handleChooseCompany(selectedCompany);
            }}
          >
            {t('confirmSelectionCompany')}
          </SimpleButton>
        </DialogActions>
      }
    >
      <FormControl>
        <RadioGroup
          aria-labelledby="controlled-radio-licensee-group"
          data-testid="controlled-radio-licensee-datatest-id"
          name="controlled-radio-licensee-group"
          value={selectedCompany}
          onChange={handleChange}
        >
          {accounts.map((account) => (
            <FormControlLabel
              key={account.licensee_number}
              data-testid={`data_test_id_${account.licensee_number}`}
              value={account.licensee_number}
              control={<Radio color="primary" />}
              label={getLicenseeName(account.licensee_number, account.licensee_name)}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </VamDialog>
  );
}
