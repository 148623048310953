import { SectionTitle } from '@digital-at-vallourec/steel-design-system-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContainerCard } from '../shared';

export default function AllowedPayment() {
  const { t } = useTranslation('user', { keyPrefix: 'manageSingleUser' });
  return (
    <ContainerCard
      width="100%"
      maxWidth="100%"
      height="100%"
      avatarHeaderNode={
        <SectionTitle
          title={t('allowedPayment.title')}
          description={t('allowedPayment.subtitle')}
        />
      }
      dataTestId="manage-user-allowed-payment"
    ></ContainerCard>
  );
}
