import React from 'react';
import { useTranslation } from 'react-i18next';

import { Products } from '../../../interfaces/veg';
import { getSetApplicabilities } from '../../../services/api/applicability-api';
import { VamDataGrid } from '../../data-grid/VamDataGrid';
import { applicabilitiesCols } from '../../setModel/utils';
import { VamDialog } from '../../shared';

interface ShowApplicabilitiesProps {
  isOpen: boolean;
  setIsOpen: Function;
  hrefUrl: string;
}

function ShowApplicabilities({ isOpen, setIsOpen, hrefUrl }: ShowApplicabilitiesProps) {
  const { t } = useTranslation('gauge', { keyPrefix: 'setsManagement.actions' });

  const [searchApplications, setSearchApplications] = React.useState<Products[]>([]);
  const [loadingGrid, setLoadingGrid] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoadingGrid(true);
      getSetApplicabilities(hrefUrl)
        .then(({ data }) => {
          const { applicabilities } = data;
          setSearchApplications(applicabilities);
        })
        .catch((error) => {
          console.error('🚀 ~ file: ShowApplicabilities.tsx ~ fetch ~ error', error);
        })
        .finally(() => {
          setLoadingGrid(false);
        });
    };

    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  return (
    <VamDialog
      isOpen={isOpen}
      title={t('applicabilitiesTitle')}
      subTitle={t('applicabilitiesSubTitle')}
      fullWidth
      handleClose={
        /* istanbul ignore next */ () => {
          setIsOpen(false);
          setSearchApplications([]);
        }
      }
      withCloseButton
    >
      <VamDataGrid
        showRowLeftThickBorder
        disableColumnFilter
        height={400}
        disableRowSelectionOnClick
        columns={applicabilitiesCols}
        rows={searchApplications}
        loading={loadingGrid}
      />
    </VamDialog>
  );
}

export default ShowApplicabilities;
