/* istanbul ignore file */
import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { PageLoader } from '../shared/PageLoader/PageLoader';

export const AuthGuard = ({ component }) => {
  const location = useLocation();
  const Component = withAuthenticationRequired(component, {
    returnTo: location.pathname,
    onRedirecting: () => (
      <div className="page-layout">
        <PageLoader />
      </div>
    ),
  });

  return <Component />;
};
