import { TypeOf, number, object, string } from 'zod';

export const formSchema = object({
  connection: string().optional().array(),
  od_inch: number().optional().array(),
  end: string().optional().array(),
  min_weight_max_weight: string().optional().array(),
  application: string().optional().array(),
  option: string().optional().array(),
  location_id: number().optional().array(),
  website_status: string().optional(),
  set_state: string().optional(),
  availability_status: string().optional().array(),
  set_ids: string().optional(),
});

export const defaultValues: FormSchemaType = {
  connection: [],
  od_inch: [],
  end: [],
  min_weight_max_weight: [],
  application: [],
  option: [],
  location_id: [],
  website_status: '',
  availability_status: [],
  set_state: '',
  set_ids: '',
};

// infer schema to generate typescript type
export type FormSchemaType = TypeOf<typeof formSchema>;
