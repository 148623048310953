/* istanbul ignore file */
import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';

export const Auth0ProviderControl = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  if (!(domain && clientId)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin + '/callback',
        audience: audience,
        scope: 'openid email offline_access',
      }}
      useRefreshTokens={false}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
