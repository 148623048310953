import styled from '@emotion/styled';
import { Grid } from '@mui/material';

export const QuickAccessButtonsContainer = styled.div`
  display: flex;
  gap: 70px;
`;

export const StyledGridFilter = styled(Grid)`
  padding-top: 14px !important;
`;

export const backgroundActiveNode =
  'linear-gradient(76deg, #00CDFB 0%, #009EDC 100%) 0% 0% no-repeat padding-box';
