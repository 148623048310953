import { AxiosResponse } from 'axios';

import {
  ApplicabilityGradeDistinct,
  ApplicabilityProductDistinct,
  ApplicabilityValueElement,
  DocumentResult,
  DownloadFileParams,
  ManageDocumentParams,
  ManageDocumentResult,
} from '../../interfaces/documentation';
import { documentationEndpoints } from '../../utils/constants/endpoints/documentation-endpoints';
import { docHttpWithSpinner } from '../axios-services/http-documentation';

/**
 * Post to get distinct product
 * @param bodyRequest ApplicabilityValueElement[]
 * @returns Promise ApplicabilityProductDistinct
 */
export function getDistinctProductApps(
  bodyRequest: ApplicabilityValueElement[]
): Promise<AxiosResponse<ApplicabilityProductDistinct, any>> {
  return docHttpWithSpinner.post<ApplicabilityProductDistinct>(
    documentationEndpoints.distinct_products_applicability,
    bodyRequest
  );
}

/**
 * Post to get distinct product
 * @param bodyRequest ApplicabilityValueElement[]
 * @returns Promise ApplicabilityGradeDistinct
 */
export function getDistinctGradeApp(
  bodyRequest: ApplicabilityValueElement[]
): Promise<AxiosResponse<ApplicabilityGradeDistinct, any>> {
  return docHttpWithSpinner.post<ApplicabilityGradeDistinct>(
    documentationEndpoints.distinct_grades_applicability,
    bodyRequest
  );
}

/**
 * Post save new Publication
 * @param data FormData
 * @returns Promise id number
 */
export function saveDocumentPublication(data: FormData): Promise<AxiosResponse<string, any>> {
  return docHttpWithSpinner.post<string>(documentationEndpoints.create_publication, data, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });
}

/**
 * Get the list of documents filtered
 * @param params FilterDistinctDocumentParams
 * @returns Promise ManageDocumentResult
 */
export function getManageDocuments(
  params: ManageDocumentParams
): Promise<AxiosResponse<ManageDocumentResult, any>> {
  return docHttpWithSpinner.post<ManageDocumentResult>(documentationEndpoints.documents, params);
}

/**
 * Get download file
 * @param params file_uuid and licensee_number
 * @returns Promise Blob
 */
export function downloadFileDocumentation({
  file_uuid,
  licensee_number,
}: DownloadFileParams): Promise<AxiosResponse<Blob, any>> {
  return docHttpWithSpinner.get<Blob>(documentationEndpoints.download_file, {
    params: { file_uuid, licensee_number },
    responseType: 'blob',
  });
}

/**
 * Get the list of licensee documents
 * @param licensee_number
 * @param params FilterDistinctDocumentParams
 * @returns Promise ManageDocumentResult
 */
export function getLicenseeDocuments(
  licensee_number: number,
  params: ManageDocumentParams
): Promise<AxiosResponse<ManageDocumentResult, any>> {
  return docHttpWithSpinner.post<ManageDocumentResult>(
    documentationEndpoints.licensee_documents(licensee_number),
    params
  );
}

/**
 * Get publication
 * @param documentUUID UUID of the publication
 * @returns Promise DocumentResult
 */
export function getPublication(documentUUID: string): Promise<AxiosResponse<DocumentResult, any>> {
  return docHttpWithSpinner.get<DocumentResult>(
    documentationEndpoints.document_by_uuid(documentUUID)
  );
}

/**
 * Check Publication Exists
 * @param reference publication's reference
 * @param revision publication's revision
 * @returns Promise boolean
 */
export function checkPublicationExists(
  reference: string,
  revision: string
): Promise<AxiosResponse<boolean, any>> {
  return docHttpWithSpinner.get<boolean>(documentationEndpoints.verify_unicity_publication, {
    params: { reference, revision },
  });
}

/**
 * Revise Publication
 * @param data FormData
 * @returns Promise id number
 */
export function revisePublication(data: FormData): Promise<AxiosResponse<string, any>> {
  return docHttpWithSpinner.post<string>(documentationEndpoints.revise_publication, data, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });
}
