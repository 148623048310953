import React from 'react';

import { PickupAddress } from '../interfaces/address';
import { LabelValue } from '../interfaces/label-value';
import { fetchPickupAddressesThunk, selectPickupAddresses } from '../services/store/userSlice';
import { arrayToLabelValuesByRef } from '../utils/functions';
import { useAppDispatch, useAppSelector } from './redux';

export function useSelectOrFetchPuAddress() {
  const [puAddressesLabelValue, setPuAddressesLabelValue] = React.useState([] as LabelValue[]);
  const pickupAddress = useAppSelector(selectPickupAddresses);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const fetchData = async () => {
      const pickAddresses: PickupAddress[] = pickupAddress?.length
        ? pickupAddress
        : await dispatch(fetchPickupAddressesThunk()).unwrap();

      const puLabelValue = arrayToLabelValuesByRef(pickAddresses, 'id', 'town');
      setPuAddressesLabelValue(puLabelValue);
    };

    // fetch pickup addresses
    fetchData();
  }, []);

  return { puAddressesLabelValue, pickupAddress };
}
