import React from 'react';

import {
  fetchQualificationsThunk,
  selectAreQualificationsLoaded,
  selectQualifications,
} from '../services/store/licenseeContextSlice';
import { selectLicenseeNumber } from '../services/store/userSlice';
import { useAppDispatch, useAppSelector } from './redux';

export function useSelectOrFetchLicenseeQualifications() {
  const licenseeNumber = useAppSelector(selectLicenseeNumber);
  const qualifications = useAppSelector(selectQualifications);
  const areQualificationsLoaded = useAppSelector(selectAreQualificationsLoaded);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const fetchData = async () => {
      if (!areQualificationsLoaded) {
        await dispatch(fetchQualificationsThunk(licenseeNumber)).unwrap();
      }
    };
    // fetch licensee qualifications
    fetchData();
  }, []);

  return { qualifications };
}
