import {
  LabelValue,
  SelectInput,
  SimpleButton,
} from '@digital-at-vallourec/steel-design-system-react';
import { DialogActions, Grid, SelectChangeEvent } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { VamDialog } from '../../components/shared';
import { updateInvoiceStatus } from '../../services/api/invoice-api';
import { $grey40 } from '../../styles/colors';
import { InvoiceStatus } from '../../utils/constants/invoice';
import { getInvoiveStatusOptions } from './utils';

interface InvoiceStatusDialogProps {
  isOpen: boolean;
  setIsOpen: Function;
  rowData: any;
  onRefrechData: Function;
}

export function InvoiceStatusDialog({
  isOpen,
  setIsOpen,
  rowData,
  onRefrechData,
}: InvoiceStatusDialogProps) {
  const { t } = useTranslation('invoice', { keyPrefix: 'invoiceStatusDialog' });
  const { t: tInvoiceListing } = useTranslation('invoice', { keyPrefix: 'invoiceListing' });
  const { t: tCommon } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const [targetStatus, setTargetStatus] = React.useState<InvoiceStatus>(null);

  const targetOptions = getInvoiveStatusOptions(tInvoiceListing, rowData?.invoice_status);

  const onChangeStatus = (event: SelectChangeEvent<unknown>) => {
    setTargetStatus(event.target.value as InvoiceStatus);
  };

  /* istanbul ignore next */
  const onSaveChangeStatus = () => {
    updateInvoiceStatus(rowData.invoice_id, targetStatus)
      .then(() => {
        enqueueSnackbar(tCommon('updateStatusOk'), {
          preventDuplicate: true,
          variant: 'success',
        });
        onRefrechData();
        setIsOpen(false);
      })
      .catch(function (error) {
        console.error(error);
        enqueueSnackbar(tCommon('updateStatusFailed'), {
          preventDuplicate: true,
          variant: 'error',
        });
      });
  };

  return (
    <VamDialog
      isOpen={isOpen}
      title={t('title')}
      subTitle={t('subTitle', {
        invoice_id: rowData?.id,
      })}
      fullWidth
      maxWidth="sm"
      handleClose={
        /* istanbul ignore next */ () => {
          setIsOpen(false);
        }
      }
      withCloseButton
      dialogActions={
        <DialogActions className="!tw-justify-center">
          <SimpleButton
            data-testid="confirm-change-invoice-status-button"
            variant="cta"
            size="small"
            id="confirm-change-invoice-status-button"
            onClick={() => {
              onSaveChangeStatus();
            }}
          >
            {t('changeInvoiceStatusCta')}
          </SimpleButton>
        </DialogActions>
      }
    >
      <Grid
        container
        rowSpacing={4}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        className="tw-px-16 tw-py-8"
      >
        <Grid item xs={12}>
          <LabelValue
            labelColor={$grey40}
            label={t('currentStatus')}
            data-testid="current_status_value_information"
            value={tInvoiceListing(`invoiceStatus.${rowData?.invoice_status}`)}
            inputWidth={420}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            id="select_target_status"
            data-testid="select_target_status"
            label={`${t('targetStatus')} *`}
            options={targetOptions}
            isOptionsLabelValue={true}
            variant="standard"
            value={targetStatus || ''}
            onChange={(event) => onChangeStatus(event)}
          />
        </Grid>
      </Grid>
    </VamDialog>
  );
}
