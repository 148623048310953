import styled from '@emotion/styled';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { $primaryBlack, $primaryWhite } from '../../../styles/colors';
import { VamTitleDialog } from '../VamTitleDialog/VamTitleDialog';

interface VamDialogProps extends Omit<DialogProps, 'open'> {
  title: string;
  subTitle?: string;
  children?: React.ReactNode;
  isOpen: boolean;
  fullWidth?: boolean;
  withCloseButton?: boolean;
  headerIcon?: React.ReactNode;
  dialogActions?: React.ReactNode;
  handleClose?: Function;
}

export const CloseIconButton = styled(IconButton)`
  color: ${$primaryBlack};
  position: absolute;
  right: 0;
  top: 0;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0 6px 0;
`;

export function VamDialog({
  title,
  subTitle,
  children,
  isOpen,
  fullWidth,
  withCloseButton,
  headerIcon,
  dialogActions,
  handleClose,
  ...othersProps
}: VamDialogProps) {
  const close = () => {
    handleClose && handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth={fullWidth}
      onClose={close}
      maxWidth="lg"
      PaperProps={{
        style: {
          backgroundColor: $primaryWhite,
        },
      }}
      {...othersProps}
    >
      <DialogTitle>
        {headerIcon ? <IconContainer>{headerIcon}</IconContainer> : null}
        <VamTitleDialog title={title} subTitle={subTitle}></VamTitleDialog>
      </DialogTitle>

      {children && <DialogContent>{children}</DialogContent>}
      {withCloseButton ? (
        <CloseIconButton data-testid="dialog-close-button" onClick={close}>
          <Close />
        </CloseIconButton>
      ) : null}

      {dialogActions}
    </Dialog>
  );
}
