import { InformationBox, SectionTitle } from '@digital-at-vallourec/steel-design-system-react';
import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { VegCase } from '../../interfaces/case';
import { ContainerCard, FormDatePicker, FormTextField } from '../shared';

interface GeneralInformationsProps {
  data: VegCase;
}

export function GeneralInformations({ data }: GeneralInformationsProps) {
  const { t } = useTranslation('gauge_case');

  return (
    <ContainerCard
      width="100%"
      maxWidth="100%"
      height="100%"
      avatarHeaderNode={
        <SectionTitle
          title={t('updateCase.generalInfosTitle')}
          description={t('updateCase.generalInfosTag')}
        />
      }
    >
      <Grid container spacing={2} direction="column" lineHeight={0}>
        <Grid item xs={6} lineHeight={1}>
          <InformationBox
            data={[
              {
                name: data.nb_sets,
                title: 'SET ASSOCIATED',
              },
            ]}
          />
        </Grid>
        <hr />
        <Grid item xs={6}>
          <FormTextField
            id="case-created-by"
            name="created_by"
            label={t('createCase.caseInformation.form.createdBy')}
            inputDataTestId="case-created-by-textfield"
            disabled={true}
            value={data.created_by || '-'}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            id="case-created-on"
            name="creation_date"
            label={t('createCase.caseInformation.form.creationDate')}
            dataTestId="creation-date-case-textfield"
            disabled={true}
            value={new Date(data.creation_date || undefined)}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            id="case-modified-by"
            name="modified_by"
            label={t('createCase.caseInformation.form.modifiedBy')}
            inputDataTestId="case-modified-by-textfield"
            disabled={true}
            value={data.modified_by || '-'}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            id="case-modified-on"
            name="modification_date"
            label={t('createCase.caseInformation.form.modificationDate')}
            dataTestId="modification-date-case-textfield"
            disabled={true}
            value={new Date(data.modification_date || undefined)}
          />
        </Grid>
      </Grid>
    </ContainerCard>
  );
}
