export enum HttpVerbs {
  'GET' = 'get',
  'HEAD' = 'head',
  'POST' = 'post',
  'PUT' = 'put',
  'PATCH' = 'patch',
  'DELETE' = 'delete',
  'OPTIONS' = 'options',
}

export interface HypermediaAction {
  name: string;
  href: string;
  method: HttpVerbs;
}

// AsyncFunction is a type generic that receives two type variables -
// the type of the input(I), and the type of the output (O).
export type AsyncFunction<I, O> = (args: I) => Promise<O>;
