import { HttpVerbs, HypermediaAction } from '../../interfaces/api';

export function getHrefHypermedia(
  actions: HypermediaAction[],
  name: string,
  method: HttpVerbs
): string {
  const action = actions.filter((action) => action.name === name && action.method === method);
  return action.length === 1 ? action[0].href : null;
}
