import { array, boolean, number, object, string } from 'zod';

import { REQUIRED_FIELD } from './validators-message';

const gaugeModelRequiredSchema = object({
  gauge_model_id: number(),
  required: boolean(),
});

const applicationsSchema = object({
  connection: string(),
  od_inch: number(),
  weight: number(),
  end: string(),
  application: string(),
  option: string(),
  thickness: number(),
});

export const formSchema = object({
  connection: string().min(1, { message: REQUIRED_FIELD }),
  od_inch: number(),
  end: string().min(1, { message: REQUIRED_FIELD }),
  min_weight: number(),
  max_weight: number(),
  application: string().min(1, { message: REQUIRED_FIELD }),
  option: string().min(1, { message: REQUIRED_FIELD }),
  case_id: number(),
  applications: array(applicationsSchema).nonempty(),
  gauge_models: array(gaugeModelRequiredSchema).nonempty(),
  applicabilities_connection: string().optional(),
  applicabilities_od: number().nullable().optional(),
  total_weight: string(),
});

export enum CaseStatus {
  Active = 'active',
  Draft = 'draft',
  Inactive = 'inactive',
}
