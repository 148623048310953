export function groupBy(array: any[], key: string): Record<string, unknown>[] {
  return array.reduce((result, item) => {
    const groupKey = item[key];
    if (!result[groupKey]) {
      result[groupKey] = [];
    }
    result[groupKey].push(item);
    return result;
  }, {});
}

export function sortByMultipleKeys<T>(
  data: T[],
  keysWithComparators: Array<{ key: string; comparator: (a: any, b: any) => number }>
): T[] {
  return data.sort((a, b) => {
    for (let i = 0; i < keysWithComparators.length; i++) {
      const { key, comparator } = keysWithComparators[i];
      const comparison = comparator(a[key], b[key]);
      if (comparison !== 0) {
        return comparison;
      }
    }
    return 0;
  });
}
