import { Notification, SimpleButton } from '@digital-at-vallourec/steel-design-system-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Grid, Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../hooks';
import { useSelectOrFetchPuAddress } from '../../../hooks/useSelectOrFetchPuAddress';
import { PickupAddress } from '../../../interfaces/address';
import { SetGaugeModel } from '../../../interfaces/set-creation';
import { ManageBookingGrid, SetReturnInformations } from '../../../interfaces/veg';
import { getShippingReturnInformations, saveReturn } from '../../../services/api/shipping-api';
import { selectUserId } from '../../../services/store/userSlice';
import { $lightBlue } from '../../../styles/colors';
import { redirectTo } from '../../../utils/functions/helpers';
import { GridStatus } from '../../data-grid/utils';
import { ContainerOutlet } from '../../layout/ContainerOutlet/ContainerOutlet';
import { ContainerCard, VamPageTitle } from '../../shared';
import Comments from '../../shared/Comments/Comments';
import { ShippingInformations } from '../dispatch-summary/ShippingInformations';
import { ShippingOrderInformations } from '../dispatch-summary/ShippingOrderInformations';
import { mapReturnFormValues } from '../utils';
import { FormSchemaType, defaultValues, makeReturnFormSchema } from './MakeReturnForm';

export function MakeReturn() {
  const { t } = useTranslation('gauge');
  const { t: tCommon } = useTranslation('common');
  const location = useLocation();
  const { pickupAddress } = useSelectOrFetchPuAddress();
  const [deliveryAddress, setDeliveryAddress] = React.useState<PickupAddress>(null);
  const [setsInformations, setSetsInformations] = React.useState<SetReturnInformations[]>(null);
  const [errorInspect, setErrorInspect] = React.useState<boolean>(true);

  const currentBookings: ManageBookingGrid[] = location?.state?.currentSelectedBookings;
  const licenseeNumber = currentBookings?.[0]?.licensee_number;
  const userId = useAppSelector(selectUserId);
  const setIds: number[] = currentBookings?.map((booking) => booking.set_id);

  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchInformations();
      } catch (error) {
        /* istanbul ignore next */
        console.error('🚀 ~ file: MakeReturn.tsx ~ line 38 ~ fetchData ~ error', error);
      }
    };
    fetchData();
  }, [currentBookings]);

  const methods = useForm<FormSchemaType>({
    defaultValues: defaultValues,
    resolver: zodResolver(makeReturnFormSchema),
    mode: 'onChange',
  });

  const { getValues, formState, setValue, control, trigger, handleSubmit } = methods;

  const { replace } = useFieldArray({
    control: control,
    name: 'sets',
  });

  const fetchInformations = async () => {
    const { data } = await getShippingReturnInformations(setIds);
    const addressId = pickupAddress.find((pa) => pa.town === data?.[0]?.gauge_location)?.id;
    setValue('currency', data?.[0]?.currency);
    const defaultSets = data?.map((set, idx) => {
      const comments = set.comments || '';
      setValue(`sets.${idx}.proforma_value`, `${set.proforma_value}`);
      trigger(`sets.${idx}.proforma_value`);
      return {
        ...set,
        business_number: currentBookings?.find((booking) => booking.set_id === set.set_id)
          ?.business_number,
        proforma_value: `${set.proforma_value}`,
        gauges: set.gauges.map((gauge) => ({
          ...gauge,
          is_present: false,
          is_damaged: false,
          grid_status: GridStatus.KO,
        })),
        comments,
      };
    });
    replace(defaultSets);
    setDeliveryAddress(pickupAddress.find((address) => address.id === addressId));
    setSetsInformations(data);
  };

  const handleConfirmInspectDialog = (gauges: SetGaugeModel[], setNb: number) => {
    const newSets = getValues('sets').map((set, idx) => {
      if (idx === setNb) {
        return { ...set, gauges, grid_status: GridStatus.OK };
      }
      return set;
    });
    replace(newSets);
    setErrorInspect(!!newSets.find((set) => set.grid_status !== GridStatus.OK));
  };

  /* istanbul ignore next */
  const onSubmit: SubmitHandler<FormSchemaType> = (data) => {
    const vegData = mapReturnFormValues(data, currentBookings, licenseeNumber, userId);
    saveReturn(vegData)
      .then(() => {
        enqueueSnackbar(t('makeReturn.success'), {
          preventDuplicate: true,
          variant: 'success',
        });
        redirectTo(`../`, navigate, {
          state: { from: location },
        });
      })
      .catch(function (error) {
        enqueueSnackbar(tCommon('notification.error'), {
          preventDuplicate: true,
          variant: 'error',
        });
        console.error(error);
      });
  };

  return (
    <ContainerOutlet>
      <FormProvider {...methods}>
        <Stack direction="row" justifyContent="space-between" className="tw-mb-6">
          <Stack direction="row" justifyContent="space-between">
            <VamPageTitle
              title={t('makeReturn.title')}
              subTitle={t('tag')}
              breadcrumbRoutes={[
                {
                  breadcrumb: 'Return Bookings',
                  path: '/admin/vam-easy-gauge/return-bookings',
                },
                {
                  breadcrumb: 'Make Return',
                  path: '#',
                },
              ]}
            />
          </Stack>
          <Stack direction="row" spacing={3} alignItems="center">
            {errorInspect ? (
              <Notification
                title={t('makeReturn.notification.title')}
                description={
                  <Trans components={{ bold: <strong /> }}>
                    {t('makeReturn.notification.description')}
                  </Trans>
                }
                severity="error"
              />
            ) : null}
            <SimpleButton
              type="submit"
              variant="cta"
              data-testid="make-return-submit-btn"
              disabled={
                formState.isLoading ||
                !formState.isValid ||
                !!Object.keys(formState.errors)?.length ||
                !getValues('sets')?.length ||
                errorInspect
              }
              onClick={handleSubmit(onSubmit)}
            >
              {t('makeReturn.submit')}
            </SimpleButton>
          </Stack>
        </Stack>
        <Grid
          container
          rowSpacing={4}
          columnSpacing={{ xs: 2, sm: 3, md: 4 }}
          style={{
            backgroundColor: $lightBlue,
          }}
        >
          <Grid item xs={12}>
            <ContainerCard
              width="100%"
              maxWidth="100%"
              title={t('makeReturn.returnInformations.title')}
              stepNumber={1}
              subTitle={t('makeReturn.returnInformations.subtitle')}
              dataTestId="make-return-return-informations"
            >
              <ShippingInformations isReturn />
            </ContainerCard>
          </Grid>
          <Grid item xs={12}>
            <ContainerCard
              width="100%"
              maxWidth="100%"
              title={t('makeReturn.bookingInformations.title')}
              stepNumber={2}
              subTitle={t('makeReturn.bookingInformations.subtitle')}
              dataTestId="make-return-booking-informations"
            >
              <ShippingOrderInformations
                bookings={currentBookings}
                sets={setsInformations}
                isReturn
                deliveryAddress={deliveryAddress}
                handleConfirmInspectDialog={handleConfirmInspectDialog}
              />
            </ContainerCard>
          </Grid>
          <Grid item xs={12}>
            <Comments
              inputTestId="make-return-comments-textfield"
              triggerOnBlur
              title={t('makeReturn.comments.title')}
              subtitle={t('makeReturn.comments.subtitle')}
            />
          </Grid>
        </Grid>
      </FormProvider>
    </ContainerOutlet>
  );
}
