import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Welcome } from '../../components/Welcome/Welcome';
import { useAppSelector } from '../../hooks';
import { selectUser } from '../../services/store/userSlice';
import { redirectTo } from '../../utils/functions/helpers';

export function Home() {
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (user?.idUserMyVam) {
      redirectTo('/dashboard', navigate);
    }
  }, []);

  return <Welcome />;
}
