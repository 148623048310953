import { InformationBox, SimpleButton } from '@digital-at-vallourec/steel-design-system-react';
import { Divider, Grid } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import SetModelIcon from '../../assets/icons/icon-set-model.svg';
import { DistinctConnectionLabelValue } from '../../interfaces/distinct-product-label-value';
import { SetModelDetails } from '../../interfaces/set-model';
import { fetchDistinctProduct } from '../../services/api/veg-api';
import { VegWebsiteStatus } from '../../utils';
import {
  getDesignationProduct,
  mapToProductFilter,
  removeFalsyProperty,
  toConnectionsOptionsFilters,
} from '../../utils/functions';
import { ContainerCard, FormSelect, FormTextField } from '../shared';

export interface SetConnectionProps {
  setModel?: SetModelDetails;
  websiteStatus?: VegWebsiteStatus;
}

function SetConnection(props: SetConnectionProps) {
  const { t } = useTranslation('gauge');
  const { control, reset, setValue } = useFormContext();
  const [distinctProductLabelValue, setDistinctProduct] = React.useState({
    connection: props.setModel
      ? [{ label: props.setModel.connection, value: props.setModel.connection }]
      : [],
    od_inch: props.setModel
      ? [{ label: `${props.setModel.od_inch}`, value: props.setModel.od_inch }]
      : [],
    end: props.setModel ? [{ label: props.setModel.end, value: props.setModel.end }] : [],
    min_weight: props.setModel
      ? [
          {
            label: `${
              // To avoid displaying a 0 decimal (like 169.0)
              // because it causes a warning in MUI Select
              props.setModel?.min_weight % 1 === 0
                ? Math.floor(props.setModel.min_weight)
                : props.setModel?.min_weight
            }`,
            value:
              props.setModel?.min_weight % 1 === 0
                ? Math.floor(props.setModel.min_weight)
                : props.setModel?.min_weight,
          },
        ]
      : [],
    max_weight: props.setModel
      ? [
          {
            label: `${
              props.setModel?.max_weight % 1 === 0
                ? Math.floor(props.setModel.max_weight)
                : props.setModel?.max_weight
            }`,
            value:
              props.setModel?.max_weight % 1 === 0
                ? Math.floor(props.setModel.max_weight)
                : props.setModel?.max_weight,
          },
        ]
      : [],
    application: props.setModel
      ? [{ label: props.setModel.application, value: props.setModel.application }]
      : [],
    option: props.setModel ? [{ label: props.setModel.option, value: props.setModel.option }] : [],
  } as DistinctConnectionLabelValue);
  const { _formValues } = control;
  const [designation, setDesignation] = React.useState(getDesignationProduct(_formValues));

  useEffect(() => {
    const fetchData = async () => {
      try {
        await handleChangeDistinctProduct();
      } catch (error) {
        /* istanbul ignore next */
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleChangeDistinctProduct = useCallback(async (_?: any, controlName?: string) => {
    const { _formValues } = control;
    setDesignation(getDesignationProduct(_formValues));
    const filter = removeFalsyProperty(mapToProductFilter(_formValues));
    if (controlName === 'connection') {
      setValue('applicabilities_connection', _formValues.connection);
    }
    if (controlName === 'od_inch') {
      setValue('applicabilities_od', _formValues.od_inch);
    }
    try {
      const { data } = await fetchDistinctProduct(filter);
      /* istanbul ignore next */
      const labelValueOptions = toConnectionsOptionsFilters(
        data,
        _formValues.min_weight,
        _formValues.max_weight
      );
      /* istanbul ignore next */
      setDistinctProduct(labelValueOptions);
    } catch (error) {
      /* istanbul ignore next */
      console.error(error);
    }
  }, []);

  return (
    <ContainerCard
      title={t('setModel.createSetContent.title')}
      icon={SetModelIcon}
      subTitle={t('setModel.createSetContent.subtitle', {
        context: props.setModel?.id ? 'update' : 'create',
      })}
      width="100%"
      maxWidth="100%"
      actionHeaderNode={
        <div className="tw-mr-4">
          <InformationBox
            data={[
              {
                title: t('setModel.createSetContent.bannerHeader'),
                name: designation,
              },
            ]}
          />
        </div>
      }
    >
      <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
        <Grid item xs={3}>
          <FormSelect
            id="connection-select"
            variant="standard"
            name="connection"
            label={t('details.connection')}
            options={distinctProductLabelValue?.connection}
            handleChange={handleChangeDistinctProduct}
            disabled={
              props.setModel?.website_status !== VegWebsiteStatus.Draft &&
              props.websiteStatus !== VegWebsiteStatus.Draft
            }
          />
        </Grid>

        <Grid item xs={3}>
          <FormSelect
            id="od-select"
            variant="standard"
            name="od_inch"
            label={`${t('details.od')}`}
            options={distinctProductLabelValue?.od_inch}
            handleChange={handleChangeDistinctProduct}
            disabled={
              props.setModel?.website_status !== VegWebsiteStatus.Draft &&
              props.websiteStatus !== VegWebsiteStatus.Draft
            }
          />
        </Grid>

        <Grid item xs={3}>
          <FormSelect
            id="min-weight-select"
            variant="standard"
            name="min_weight"
            label={`${t('details.minWeight')}`}
            options={distinctProductLabelValue?.min_weight}
            handleChange={handleChangeDistinctProduct}
            disabled={
              props.setModel?.website_status !== VegWebsiteStatus.Draft &&
              props.websiteStatus !== VegWebsiteStatus.Draft
            }
          />
        </Grid>

        <Grid item xs={3}>
          <FormSelect
            id="max-weight-select"
            variant="standard"
            name="max_weight"
            label={`${t('details.maxWeight')}`}
            options={distinctProductLabelValue?.max_weight}
            handleChange={handleChangeDistinctProduct}
            disabled={
              props.setModel?.website_status !== VegWebsiteStatus.Draft &&
              props.websiteStatus !== VegWebsiteStatus.Draft
            }
          />
        </Grid>

        <Grid item xs={3}>
          <FormSelect
            id="end-select"
            variant="standard"
            name="end"
            label={t('details.end')}
            options={distinctProductLabelValue?.end}
            handleChange={handleChangeDistinctProduct}
            disabled={
              props.setModel?.website_status !== VegWebsiteStatus.Draft &&
              props.websiteStatus !== VegWebsiteStatus.Draft
            }
          />
        </Grid>

        <Grid item xs={3}>
          <FormSelect
            id="application-select"
            variant="standard"
            name="application"
            label={t('details.application')}
            options={distinctProductLabelValue?.application}
            handleChange={handleChangeDistinctProduct}
            disabled={
              props.setModel?.website_status !== VegWebsiteStatus.Draft &&
              props.websiteStatus !== VegWebsiteStatus.Draft
            }
          />
        </Grid>

        <Grid item xs={3}>
          <FormSelect
            id="option-select"
            variant="standard"
            name="option"
            label={t('details.option')}
            options={distinctProductLabelValue?.option}
            handleChange={handleChangeDistinctProduct}
            disabled={
              props.setModel?.website_status !== VegWebsiteStatus.Draft &&
              props.websiteStatus !== VegWebsiteStatus.Draft
            }
          />
        </Grid>
        <Grid item xs={3} className="tw-flex tw-items-end">
          <SimpleButton
            data-testid="reset-set-model-form-btn"
            onClick={() => {
              reset();
              handleChangeDistinctProduct();
            }}
            disabled={
              props.setModel?.website_status !== VegWebsiteStatus.Draft &&
              props.websiteStatus !== VegWebsiteStatus.Draft
            }
          >
            {t('reset')}
          </SimpleButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={2}>
          <FormTextField
            id="total_weight"
            name="total_weight"
            label={t('details.totalWeight')}
            size="small"
            type="number"
          />
        </Grid>
      </Grid>
    </ContainerCard>
  );
}

export default SetConnection;
