import { PageTitle } from '@digital-at-vallourec/steel-design-system-react';
import { LabelBoxProps } from '@digital-at-vallourec/steel-design-system-react/dist/components/atoms/LabelBox/LabelBox';
import { useNavigate } from 'react-router-dom';

export interface BreadcrumbRoute {
  path: string;
  breadcrumb: string;
}

export function VamPageTitle(props: {
  title: string;
  subTitle?: string;
  breadcrumbRoutes?: BreadcrumbRoute[];
  detailsLabelBox?: LabelBoxProps[];
}) {
  const navigate = useNavigate();
  return (
    <PageTitle
      buttonVariant="icon-secondary-dark-blue"
      handleArrowBack={() => {
        navigate(-1);
      }}
      isArrowBackVisible
      isBreadcrumbVisible
      subTitle={props.subTitle}
      title={props.title}
      breadcrumbRoutes={props.breadcrumbRoutes}
      detailsLabelBox={props.detailsLabelBox}
    />
  );
}
