import { Typo } from '@digital-at-vallourec/steel-design-system-react';
import styled from '@emotion/styled';
import React from 'react';

import { $grey10, $primaryNavy } from '../../../styles/colors';

interface TitleBarProps {
  title: string;
  children?: React.ReactNode;
}

const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 12px;
`;

const Separator = styled.div`
  border-bottom: 1px solid ${$grey10};
  flex: 1;
  height: 0px;
`;

export const TitleBar = (props: TitleBarProps) => {
  return (
    <Container data-testid="title-bar">
      <Typo data-testid="title" variant="subtitle2" color={$primaryNavy}>
        {props.title}
      </Typo>
      <Separator data-testid="separator" />
      {props.children}
    </Container>
  );
};
