import { TypeOf, object, string } from 'zod';

import { REQUIRED_FIELD } from '../../utils';

export const updateUserInformationsFormSchema = object({
  first_name: string().min(1, REQUIRED_FIELD),
  last_name: string().min(1, REQUIRED_FIELD),
  phone_number: string().min(1, REQUIRED_FIELD),
  job: string().min(1, REQUIRED_FIELD),
});

export type FormSchemaType = TypeOf<typeof updateUserInformationsFormSchema>;
