import { AxiosResponse } from 'axios';

import {
  AxiosInvoiceDetails,
  Invoice,
  InvoiceDetails,
  InvoiceListing,
  InvoiceParams,
  InvoiceUpdate,
  InvoicesByStatusCount,
} from '../../interfaces/invoice';
import { endpoints } from '../../utils/constants/endpoints/veg-endpoints';
import { InvoiceStatus } from '../../utils/constants/invoice';
import { vegHttp, vegHttpWithSpinner } from '../axios-services/http-veg';

/**
 * Get Invoice listing.
 * @param params InvoiceParams
 * @return Promise InvoiceListing
 */
export function getListingInvoice(
  dataParams: InvoiceParams,
  depotId?: number
): Promise<AxiosResponse<InvoiceListing, any>> {
  return vegHttp.post<InvoiceListing>(endpoints.invoices_listing, dataParams, {
    params: { depot_id: depotId || null },
  });
}

/**
 * Get all Invoices count
 * @param params InvoiceStatus[]
 * @param params number
 * @returns Promise InvoicesByStatusCount
 */
export function getInvoicesCountByStatus(
  statuses: InvoiceStatus[],
  depotId?: number
): Promise<AxiosResponse<InvoicesByStatusCount, any>> {
  return vegHttpWithSpinner.post<InvoicesByStatusCount>(endpoints.invoices_count, statuses, {
    params: { depot_id: depotId || null },
  });
}

/**
 * Patch modify invoice status
 * @params invoice_id in path query
 * @params target_status in body request
 * @returns Promise number
 */
export function updateInvoiceStatus(
  invoice_id: number,
  target_status: InvoiceStatus
): Promise<AxiosResponse<number, any>> {
  return vegHttpWithSpinner.patch<number>(endpoints.invoice_by_id(invoice_id), {
    target_status,
  });
}

/**
 * Get Detail of an invoice by invoice_id
 * @param invoice_id number
 * @returns Promise InvoiceDetails
 */
export function getInvoiceDetails(invoice_id: number): Promise<AxiosInvoiceDetails> {
  return vegHttpWithSpinner.get<InvoiceDetails>(endpoints.invoice_by_id(invoice_id));
}

/**
 * Post export generel2 files
 * @params Array of invoice_id
 * @returns Promise blob
 */
export function exportGenerel2File(invoice_ids: number[]): Promise<AxiosResponse<Blob, any>> {
  return vegHttpWithSpinner.post<Blob>(endpoints.export_invoice_generel2, invoice_ids);
}

/**
 * Post split export generel2 files
 * @params Array of invoice_id
 * @returns Promise blob
 */
export function splitExportGenerel2File(invoice_ids: number[]): Promise<AxiosResponse<Blob, any>> {
  return vegHttpWithSpinner.post<Blob>(endpoints.export_and_zip_invoice_generel2, invoice_ids, {
    responseType: 'blob',
  });
}

/**
 * Patch update invoice
 * @params invoice in body request
 * @returns Promise number
 */
export function updateInvoice(
  invoice_id: number,
  invoice: InvoiceUpdate
): Promise<AxiosResponse<number, any>> {
  return vegHttpWithSpinner.put<number>(endpoints.invoice_by_id(invoice_id), invoice);
}

/**
 * Save new invoice by InvoiceType
 * @params invoice in body request
 * @returns Promise number
 */
export function saveNewInvoice(invoice: Invoice): Promise<AxiosResponse<number, any>> {
  return vegHttpWithSpinner.post<number>(endpoints.invoices, invoice);
}
