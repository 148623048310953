import { SaveConfirmReturnShipment, ShipmentBaseInfo } from '../../../../interfaces/shipping';
import { ManageBookingGrid } from '../../../../interfaces/veg';
import { getBookingDetail } from '../../../../services/api/veg-api';
import { ShippingSource } from '../../../../utils';

export function fetchBookingsDetails(bookingIds): Promise<ManageBookingGrid[]> {
  const bookingPromises = bookingIds.map(async (bookingId) => {
    const { data } = await getBookingDetail(bookingId);
    return data;
  });

  return Promise.all(bookingPromises);
}

export function mapConfirmReturnFormValues(
  dataForm: Record<string, any>,
  shipmentData: ShipmentBaseInfo,
  userId: number
): SaveConfirmReturnShipment {
  return {
    sets_informations: dataForm.sets,
    has_return_delivery_cost: dataForm.has_return_delivery_cost,
    comments: dataForm.comments,
    return_delivery_cost: dataForm.return_delivery_cost,
    return_shipping_id: shipmentData.id,
    licensee_number: shipmentData.licensee_number,
    shipping_source: ShippingSource.ReturnConfirmed,
    user_id: userId,
  };
}
