// color list https://xd.adobe.com/view/725e3df2-bf0b-45a5-b0cd-3865d690cdad-270d/specs/
export const $primaryWhite = '#fff';
export const $primaryBrand = '#009ede';
export const $primaryNavy = '#1A2981';
export const $primaryBlack = '#000';

export const $secondaryBlue = '#00cdfd';
export const $secondaryPaleBlue = '#aceafd';
export const $secondaryLessBlue = '#eafaff';
export const $secondaryDarkBlue = '#030b42';

export const $grey80 = '#404040';
export const $grey70 = '#4c4c4c';
export const $grey60 = '#666';
export const $grey50 = '#7f7f7f';
export const $grey40 = '#999';
export const $grey30 = '#b2b2b2';
export const $grey20 = '#ccc';
export const $grey10 = '#e5e5e5';
export const $grey5 = '#f2f2f2';

export const $darkGreen = '#1f8600';
export const $green = '#34a06e';
export const $lightGreen = '#c7e1bf';

export const $darkBerry = '#93117e';
export const $lightRuby = '#e4c3df';

export const $darkRed = '#c50c29';
export const $red = '#f05050';
export const $lightRed = '#ffd2d1';
export const $lightestRed = '#fff1f1';

export const $orange = '#f17105';
export const $lightOrange = '#fdd2af';
export const $yellow = '#ffcc00';

export const $lightYellow = '#ffefad';

export const $lightBlue = '#f8f9fa';

export const $darkGardient = '#030b42';
export const $focusedInput = '#009EDC';

export const $contentCardShadow = '#03071C24';

export const $greyBoxShadow = '#00000029';

export const $blueRowGridSelected = '#eafaff';

export const $blackBoxShadow = '#03071C24';
