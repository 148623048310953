import { $grey40 } from '@digital-at-vallourec/steel-design-system-react/dist/styles/colors';

import { $darkGreen } from '../../styles/colors';

export enum JobStatus {
  OPEN = 'OPEN',
  COMPLETE = 'COMPLETE',
}

export const JOB_STATUS_COLORS = {
  OPEN: $grey40,
  COMPLETE: $darkGreen,
};

export enum JobType {
  REPAIR = 'REPAIR',
  NEW_PART = 'NEW PART',
}

export enum TypeOfPart {
  LIFTING_HANDLING_EQUIPMENT = 'Lifting / Handling equipment',
  WATERBUSHING_CIRCULATING_HEAD = 'Waterbushing / Circulating head',
  TEST_CAP_PLUG = 'Test cap / plug',
  CROSSOVER = 'Crossover',
  PUP_JOINT = 'Pup joint',
  NIPPLE = 'Nipple',
  COUPLING = 'Coupling',
  FLOAT_SHOE = 'Float shoe',
  FLOAT_COLLAR = 'Float collar',
  CASING_TUBING_HANGER = 'Casing / Tubing hanger',
  FLOW_COUPLING_BLAST_JOINT = 'Flow coupling / Blast joint',
  THREE_WAY_CROSSOVER_Y_TOOL = '3-way crossover / Y tool',
  BULL_PLUG_MULE_SHOE_REENTRY_GUIDE = 'Bull plug / Mule shoe / Re-entry guide',
  LANDING_NIPPLE_NO_GO_LOCATOR = 'Landing nipple / No-go locator',
  SAFETY_VALVE = 'Safety valve',
  ECCENTRIC_COMPLETION_EQUIPMENT = 'Eccentric completion equipment',
  PACKER = 'Packer',
  LINER_HANGER = 'Liner hanger',
  FULL_LENGTH_BASE_PIPE = 'Full-length / Base pipe',
  OTHER = 'Other',
}
