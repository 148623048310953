import { TFunction } from 'i18next';
import { FieldError, FieldValues } from 'react-hook-form';

import { LabelValue } from '../../../interfaces/label-value';
import { getLabelByValue } from '../../../utils/functions';

export function getMultiSelectLabels(
  options: LabelValue[],
  selected: any,
  withUniqueSelection: boolean,
  t?: TFunction<string, undefined>
): string {
  if (withUniqueSelection) {
    return getLabelByValue(options, selected, t);
  } else {
    const selectedItems: any[] = selected
      .map((item) => getLabelByValue(options, item, t))
      // To remove the empty values and the useless ','
      .filter((item) => `${item}`.length > 0 && ![undefined, null].includes(item));
    return selectedItems.join(', ');
  }
}

export function isInFormValues(option: LabelValue, formValues: FieldValues): boolean {
  return !!Object.values(formValues)?.filter((formValue) => {
    return Array.isArray(formValue) && formValue?.includes(option?.value);
  })?.length;
}

export function getError(errors: FieldValues, name: string): FieldError | null {
  const fieldArray = name.split('.');
  let currentError: any = errors;

  for (const segment of fieldArray) {
    if (currentError[segment]) {
      currentError = currentError[segment];
    } else {
      return null;
    }
  }

  return currentError;
}

export function ensureArray(value: string | string[]): string[] {
  return typeof value === 'string' ? [value] : value;
}
