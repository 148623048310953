import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

import { contextSlice } from './contextSlice';
import { gaugeSlice } from './gaugeSlice';
import { licenseeQualificationsSlice } from './licenseeContextSlice';
import { userSlice } from './userSlice';

export const store = configureStore({
  reducer: {
    gauge: gaugeSlice.reducer,
    user: userSlice.reducer,
    context: contextSlice.reducer,
    licenseeContext: licenseeQualificationsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
