import { PickupAddress, VegAddressApi } from '../../interfaces/address';

export function getTownById(addresses: PickupAddress[], id: number): string {
  return addresses.find((address) => address.id === id)?.town;
}

export function mappingAddressToUserInformationsAddress(address: VegAddressApi) {
  if (address) {
    return {
      address: {
        street: address.street,
        zipCode: address.zip_code,
        town: address.town,
        state: address.country,
      },
    };
  }
}
