import { useEffect, useState } from 'react';

import { getInvoicesCountByStatus } from '../services/api/invoice-api';
import { InvoiceStatus } from '../utils/constants/invoice';

export function useSelectOrFetchBookingInvoicesCount(
  statusFilters: InvoiceStatus[],
  depotId?: number
) {
  const [statusCount, setStatusCount] = useState<Record<InvoiceStatus, number> | {}>({});

  useEffect(() => {
    const fetchData = async () => {
      getInvoicesCountByStatus(statusFilters, depotId).then(({ data }) => {
        const statusCount: Record<InvoiceStatus, number> | {} = {};
        data?.invoice_status_count?.forEach((value) => {
          statusCount[value.invoice_status] = value.count;
        });
        statusCount['all_invoices'] = data.total_count;
        setStatusCount(statusCount);
      });
    };

    fetchData();
  }, []);

  return { statusCount };
}
