import styled from '@emotion/styled';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';

import { $grey50, $primaryBrand, $red } from '../../../styles/colors';
import { getError } from './utils';

type FormPhoneNumberProps = {
  name: string;
  label: string;
  requiredMessage?: string;
  handleChange?: any;
  inputDataTestId?: string;
  defaultCountryCode?: string;
} & PhoneInputProps;

const FormPhoneNumberWrapper = styled(FormControl)`
  position: relative;
  margin-top: 10px !important;
  height: 38px;
  padding-bottom: 4px !important;

  .phone-input-label {
    position: absolute;
    // top: 6px; // -6px
    left: -16px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    background-color: white;
    z-index: 1;
    padding-left: 5px;
    padding-right: 5px;
  }

  .phone-input-container {
    width: 100%;
    height: 100%;

    .phone-input {
      width: 100% !important;
      height: 100% !important;
      font-size: 16px !important;
      border: none;
      border-bottom: 1px solid ${$grey50} !important;
      border-radius: 0px !important;
      padding: 0 12px 0 50px !important;
      background-color: transparent !important;

      &:hover {
        border-color: #000 !important;
        border-width: 2px !important;
        box-shadow: none !important;
      }

      &:focus {
        border-color: ${$primaryBrand} !important;
        border-width: 2px !important;
        box-shadow: none !important;
      }
    }

    .phone-input-dropdown {
      background-color: transparent !important;
      border: none !important;
      display: flex;
      align-items: center;
    }

    .selected-flag {
      vertical-align: middle;
    }
  }

  .phone-input-error {
    color: ${$red};
    margin-left: 14px;
    margin-top: 3px;
  }

  // Override react-phone-input-2 styles
  .react-tel-input .special-label {
    display: none;
  }

  .react-tel-input .form-control {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    line-height: 30px;
  }

  .react-tel-input .country-list .country.highlight {
    background-color: rgba(0, 158, 222, 0.08);
  }
`;

export const FormPhoneNumber: FC<FormPhoneNumberProps> = ({
  name,
  label,
  requiredMessage,
  handleChange,
  inputDataTestId,
  defaultCountryCode,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [isFocused, setIsFocused] = useState(false);

  const onChangePhoneNumber = (e: any) => {
    /* istanbul ignore next */
    if (handleChange) {
      handleChange(e);
    }
    return e;
  };

  const error = getError(errors, name);

  return (
    <FormPhoneNumberWrapper fullWidth variant="outlined" className="form-phone-number-wrapper">
      <InputLabel
        shrink
        htmlFor={name}
        className={`phone-input-label ${isFocused ? 'focused' : ''}`}
      >
        {label}
      </InputLabel>
      <Controller
        control={control}
        name={name}
        rules={{
          required: requiredMessage,
        }}
        render={({ field: { onChange, value } }) => (
          <PhoneInput
            value={value}
            onFocus={/* istanbul ignore next */ () => setIsFocused(true)}
            onBlur={/* istanbul ignore next */ () => setIsFocused(false)}
            onChange={(e) => {
              onChange(e);
              onChangePhoneNumber(e);
            }}
            country={defaultCountryCode || 'fr'}
            inputProps={{
              name: name,
              'data-testid': inputDataTestId || 'form-phone-number-input',
            }}
            containerClass="phone-input-container"
            inputClass="phone-input"
            buttonClass="phone-input-dropdown"
            {...otherProps}
          />
        )}
      />
      {error && (
        <FormHelperText error className="phone-input-error">
          {error.message}
        </FormHelperText>
      )}
    </FormPhoneNumberWrapper>
  );
};
