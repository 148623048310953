import { SideMenu } from '@digital-at-vallourec/steel-design-system-react';
import { SideMenuProps } from '@digital-at-vallourec/steel-design-system-react/dist/components/molecules/SideMenu/SideMenu';
import React from 'react';

interface SideMenuAppProps extends SideMenuProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function SideMenuApp({ items, open, setOpen, ...otherProps }: SideMenuAppProps) {
  const currentYear = new Date().getFullYear();

  return (
    <SideMenu
      {...otherProps}
      open={open}
      onClose={/* istanbul ignore next */ () => setOpen(!open)}
      footerText={`Copyright © ${currentYear}`}
      items={items}
      handleLinkClick={/* istanbul ignore next */ () => setOpen(false)}
    />
  );
}

export default SideMenuApp;
