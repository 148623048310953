import { AxiosResponse } from 'axios';

import { Applicabilities } from '../../interfaces/applicability';
import { httpHypermediaAction } from '../axios-services/http-hypermedia-action';

/**
 * get applicabilities by set_id
 * @param endpointUrl getting from hypermedia controls for restApi
 * @returns Applicabilities
 */
export function getSetApplicabilities(
  endpointUrl: string
): Promise<AxiosResponse<Applicabilities, any>> {
  return httpHypermediaAction.get<Applicabilities>(endpointUrl);
}
