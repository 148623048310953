import { $darkGreen, $darkRed, $grey40 } from '../../styles/colors';

export enum CaseStatus {
  Active = 'active',
  Inactive = 'inactive',
  Draft = 'draft',
}

export const CASE_STATUS_COLORS = {
  active: $darkGreen, // ok
  inactive: $darkRed, // ko
  draft: $grey40,
};
