import {
  Notification,
  SectionTitle,
  Tabs,
  Typo,
} from '@digital-at-vallourec/steel-design-system-react';
import { Stack } from '@mui/material';
import { GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../hooks';
import { LicenseeGauge } from '../../../interfaces/veg';
import { getLicenseeGauges, getLicenseeGaugesCount } from '../../../services/api/veg-api';
import { selectUser } from '../../../services/store/userSlice';
import { $secondaryBlue } from '../../../styles/colors';
import {
  BOOKING_STATUS_COLORS,
  BookingStatus,
  DEFAULT_PAGE_SIZE,
  DeliveryMode,
  MAP_DELIVERY_MODE_I18N,
  RentalType,
} from '../../../utils';
import { redirectTo } from '../../../utils/functions/helpers';
import { VamDataGrid } from '../../data-grid/VamDataGrid';
import { GridStatus } from '../../data-grid/utils';
import { ContainerOutlet } from '../../layout/ContainerOutlet/ContainerOutlet';
import { ContainerCard, VamMoreOptions, VamPageTitle } from '../../shared';
import { VamQuickAccessButton } from '../../shared/VamQuickAccessButton/VamQuickAccessButton';
import { mappingMyGauges, myGaugesColumns } from '../utils';

export function MyGauges() {
  const { t } = useTranslation('gauge');
  const [bookings, setBookings] = React.useState<LicenseeGauge[]>([]);
  const [status, setCurrentStatus] = React.useState<BookingStatus | BookingStatus[]>(null);
  const [bookingsCount, setBookingsCount] = React.useState<Record<BookingStatus, number> | {}>({});
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector(selectUser);
  const allStatuses = [
    BookingStatus.Delivered,
    BookingStatus.ShipmentOngoing,
    BookingStatus.ReturnOngoing,
    BookingStatus.NewOrder,
    BookingStatus.Terminated,
    BookingStatus.Gps,
    BookingStatus.ToBeExtended,
    BookingStatus.WaitingForCollection,
    BookingStatus.UnderPreparation,
    BookingStatus.ReplacementRequested,
    BookingStatus.ReplacementDone,
  ];

  React.useEffect(() => {
    fetchBookingsData();
  }, [status]);

  React.useEffect(() => {
    fetchCountData();
  }, []);

  const fetchBookingsData = async () => {
    try {
      const { data } = await getLicenseeGauges(user.licenseeNumber, {
        all_statuses: allStatuses,
        current_status: status,
      });
      setBookings(data?.map((booking) => mappingMyGauges(booking)));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCountData = async () => {
    try {
      const { data } = await getLicenseeGaugesCount(user.licenseeNumber, allStatuses);
      const statusCount: Record<BookingStatus, number> | {} = {};
      data?.booking_status_count?.forEach((value) => {
        statusCount[value.booking_status] = value.count;
      });
      statusCount['all_reservations'] = data.total_count;
      setBookingsCount(statusCount);
    } catch (error) {
      console.error(error);
    }
  };

  /* istanbul ignore next */
  async function redirectToBookingDetail(row: LicenseeGauge) {
    const { booking_id, business_number } = row;
    redirectTo(`../booking/${booking_id}`, navigate, {
      state: { from: location, business_number },
    });
  }

  /* istanbul ignore next */
  async function requestBookingReplacement(row: LicenseeGauge) {
    const { booking_id } = row;
    redirectTo('../replacement-booking', navigate, {
      state: { from: location, booking_id },
    });
  }

  const calibrationWarning = bookings.find(
    (booking) =>
      booking.grid_status === GridStatus.KO &&
      booking.rental_type === RentalType.Long &&
      booking.status === BookingStatus.Delivered
  );

  /* istanbul ignore next */
  const handleTabChange = (status: BookingStatus | BookingStatus[]) => {
    setCurrentStatus(status);
  };

  /* istanbul ignore next */
  const tabs = [
    {
      label: t('myGauges.tabs.all_reservations'),
      onClick: () => handleTabChange(undefined),
      counter: bookingsCount['all_reservations'],
    },
    {
      label: t(`myGauges.tabs.${BookingStatus.NewOrder}`),
      onClick: () => handleTabChange(BookingStatus.NewOrder),
      counter: bookingsCount[BookingStatus.NewOrder],
    },
    {
      label: t(`myGauges.tabs.${BookingStatus.ShipmentOngoing}`),
      onClick: () =>
        handleTabChange([
          BookingStatus.ShipmentOngoing,
          BookingStatus.WaitingForCollection,
          BookingStatus.UnderPreparation,
        ]),
      counter:
        (bookingsCount[BookingStatus.ShipmentOngoing] ?? 0) +
        (bookingsCount[BookingStatus.WaitingForCollection] ?? 0) +
        (bookingsCount[BookingStatus.UnderPreparation] ?? 0),
    },
    {
      label: t(`myGauges.tabs.${BookingStatus.Delivered}`),
      onClick: () => handleTabChange(BookingStatus.Delivered),
      counter: bookingsCount[BookingStatus.Delivered],
    },
    {
      label: t(`myGauges.tabs.${BookingStatus.ReturnOngoing}`),
      onClick: () => handleTabChange(BookingStatus.ReturnOngoing),
      counter: bookingsCount[BookingStatus.ReturnOngoing],
    },
    {
      label: t(`myGauges.tabs.${BookingStatus.Gps}`),
      onClick: () => handleTabChange(BookingStatus.Gps),
      counter: bookingsCount[BookingStatus.Gps],
    },
    {
      label: t('myGauges.tabs.replacement'),
      onClick: () =>
        handleTabChange([BookingStatus.ReplacementRequested, BookingStatus.ReplacementDone]),
      counter:
        (bookingsCount[BookingStatus.ReplacementRequested] ?? 0) +
        (bookingsCount[BookingStatus.ReplacementDone] ?? 0),
    },
    {
      label: t(`myGauges.tabs.${BookingStatus.ToBeExtended}`),
      onClick: () => handleTabChange(BookingStatus.ToBeExtended),
      counter: bookingsCount[BookingStatus.ToBeExtended],
    },
    {
      label: t(`myGauges.tabs.${BookingStatus.Terminated}`),
      onClick: () => handleTabChange(BookingStatus.Terminated),
      counter: bookingsCount[BookingStatus.Terminated],
    },
  ];

  let statusKey;
  if (Array.isArray(status)) {
    statusKey = status
      .map((s) => t(`manageBookingDetail.changeStatusDialog.status.${s}`))
      .join(', ');
  } else if (!status) {
    statusKey = t('manageBookingDetail.changeStatusDialog.status.all_reservations');
  } else {
    statusKey = t(`manageBookingDetail.changeStatusDialog.status.${status}`);
  }

  return (
    <ContainerOutlet>
      <Stack direction="row" justifyContent="space-between" className="tw-mb-6">
        <VamPageTitle
          subTitle={t('tag')}
          title={t('myGauges.title')}
          breadcrumbRoutes={[
            {
              breadcrumb: 'My Gauges',
              path: '#',
            },
          ]}
        />
        <Stack direction="row" justifyContent="center" alignItems="center" gap={16}>
          {calibrationWarning && (
            <Notification
              title={t('myGauges.notification.title')}
              description={
                <Trans components={{ bold: <strong /> }}>
                  {t('myGauges.notification.description', {
                    set_id: calibrationWarning.case,
                  })}
                </Trans>
              }
              severity="error"
            />
          )}
          <VamQuickAccessButton
            title={t('myGauges.quickAccessButton.title')}
            subTitle={t('myGauges.quickAccessButton.subtitle')}
            width={300}
            right={0}
            data-testid="vam-my-gauges-quick-access-button"
          />
        </Stack>
      </Stack>
      <Tabs
        color="secondary"
        className="tw-ml-4"
        tabs={tabs}
        sx={{
          '.MuiBadge-badge': {
            backgroundColor: $secondaryBlue,
          },
        }}
      />
      <ContainerCard
        width="100%"
        maxWidth="100%"
        containerCardClass="tw-pt-3"
        dataTestId="my-gauges-container"
        avatarHeaderNode={
          <SectionTitle
            title={t('myGauges.containerTitle', {
              status: statusKey,
            })}
            description={t('myGauges.containerDescription', {
              status: statusKey,
              count: status ? 1 : 2,
            })}
          />
        }
      >
        <VamDataGrid
          columns={myGaugesColumns.concat([
            {
              field: 'delivery_mode',
              headerName: 'DELIVERY MODE',
              minWidth: 80,
              flex: 1,
              renderCell: /* istanbul ignore next */ (
                params: GridRenderCellParams<any, DeliveryMode>
              ) => (
                <Typo
                  variant="body2"
                  color={BOOKING_STATUS_COLORS[params.value]}
                  fontWeight={400}
                  className="text-ellipsis"
                >
                  {t(`mode.${MAP_DELIVERY_MODE_I18N[params.value]}`)}
                </Typo>
              ),
            },
            {
              field: 'status',
              headerName: 'STATUS',
              renderCell: /* istanbul ignore next */ (
                params: GridRenderCellParams<any, BookingStatus>
              ) => (
                <Typo
                  variant="body2"
                  color={BOOKING_STATUS_COLORS[params.value]}
                  fontWeight={500}
                  className="text-ellipsis"
                >
                  {t(`manageBookingDetail.changeStatusDialog.status.${params.value}`)}
                </Typo>
              ),
              minWidth: 120,
              flex: 1,
            },
            {
              field: 'action',
              align: 'right',
              flex: 0.25,
              renderHeader: /* istanbul ignore next */ () => <></>,
              renderCell: /* istanbul ignore next */ ({ row }) => {
                return (
                  <VamMoreOptions
                    size="small"
                    options={[
                      {
                        name: 'seeDetails',
                        label: t('myGauges.actions.request'),
                        handleItemClick: (_, __, row) => requestBookingReplacement(row),
                        disabled:
                          row.rental_type !== RentalType.Long ||
                          row.status !== BookingStatus.Delivered,
                      },
                    ]}
                    row={row}
                  />
                );
              },
              sortable: false,
              hideable: false,
              disableColumnMenu: true,
            },
          ])}
          rows={bookings}
          height={500}
          disableColumnFilter
          onRowDoubleClick={({ row }: GridRowParams) => {
            /* istanbul ignore next */
            redirectToBookingDetail(row);
          }}
          rowCount={bookings.length}
          pageSize={DEFAULT_PAGE_SIZE}
          paginationMode="client"
          sortingMode="client"
          showRowLeftThickBorder
          disableRowSelectionOnClick
        />
      </ContainerCard>
    </ContainerOutlet>
  );
}
