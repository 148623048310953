import { Typo } from '@digital-at-vallourec/steel-design-system-react';
import { Stack, StackProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { $primaryBrand, $primaryNavy } from '../../../styles/colors';
import { LeftBar } from '../LeftBar/LeftBar';

interface CountResultFilterProps extends StackProps {
  count: number;
}

export function CountResultFilter({ count, ...otherProps }: CountResultFilterProps) {
  const { t } = useTranslation('common');

  return (
    <Stack direction="row" {...otherProps}>
      <LeftBar color={$primaryNavy} />
      <Stack direction="row" alignItems="center">
        <Typo color={$primaryBrand} variant="sectionTitle">
          {count}
        </Typo>
        <Typo color={$primaryNavy} variant="sectionTitle" className="tw-pl-2">
          {t('countResultFound')}
        </Typo>
      </Stack>
    </Stack>
  );
}
