import { Notification, SimpleButton, Typo } from '@digital-at-vallourec/steel-design-system-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormControlLabel, Grid, Radio, RadioGroup, Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks';
import { useSelectOrFetchPuAddress } from '../../../../hooks/useSelectOrFetchPuAddress';
import { PickupAddress } from '../../../../interfaces/address';
import { SetGaugeModel } from '../../../../interfaces/set-creation';
import {
  ConfirmReturnShipmentInformations,
  ManageShipmentsResult,
} from '../../../../interfaces/shipping';
import { ManageBookingGrid, SetReturnInformations } from '../../../../interfaces/veg';
import {
  getConfirmReturnShipmentInformations,
  saveConfirmReturn,
} from '../../../../services/api/shipping-api';
import { selectUserId } from '../../../../services/store/userSlice';
import { $lightBlue } from '../../../../styles/colors';
import { priceRegex } from '../../../../utils';
import { redirectTo } from '../../../../utils/functions/helpers';
import { GridStatus } from '../../../data-grid/utils';
import { ContainerOutlet } from '../../../layout/ContainerOutlet/ContainerOutlet';
import { ContainerCard, FormTextField, VamPageTitle } from '../../../shared';
import Comments from '../../../shared/Comments/Comments';
import { ShippingOrderInformations } from '../../dispatch-summary/ShippingOrderInformations';
import { FormSchemaType, confirmReturnFormSchema, defaultValues } from './ConfirmReturnForm';
import { fetchBookingsDetails, mapConfirmReturnFormValues } from './utils';

export function ConfirmReturn() {
  const { t } = useTranslation('shipment', { keyPrefix: 'confirmNewReturn' });
  const { t: tCommon } = useTranslation('common');
  const location = useLocation();
  const [setsInformations, setSetsInformations] = React.useState<SetReturnInformations[]>([]);
  const [errorInspect, setErrorInspect] = React.useState<boolean>(true);
  const [bookingsInformations, setBookingsInformations] = React.useState<ManageBookingGrid[]>([]);
  const { pickupAddress } = useSelectOrFetchPuAddress();
  const [deliveryAddress, setDeliveryAddress] = React.useState<PickupAddress>(null);
  const userId = useAppSelector(selectUserId);

  const currentShipment: ManageShipmentsResult = location?.state?.currentSelectedShipment;
  const shipmentId: number = currentShipment.id;

  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchData = async () => {
      fetchBookingsDetails(currentShipment.included_bookings)
        .then((bookingsData) => {
          setBookingsInformations(bookingsData);
          getConfirmReturnShipmentInformations(shipmentId).then(({ data }) => {
            fetchInformations(bookingsData, data);
          });
        })
        .catch((error) => {
          /* istanbul ignore next */
          console.error('🚀 ~ file: ConfirmRetur0n.tsx ~ line 32 ~ fetchData ~ error', error);
        });
    };
    fetchData();
  }, [shipmentId]);

  const methods = useForm<FormSchemaType>({
    defaultValues: defaultValues,
    resolver: zodResolver(confirmReturnFormSchema),
    mode: 'onChange',
  });

  const { getValues, formState, setValue, control, trigger, handleSubmit } = methods;

  const { replace } = useFieldArray({
    control: control,
    name: 'sets',
  });

  const fetchInformations = (
    bookingData: ManageBookingGrid[],
    sets: ConfirmReturnShipmentInformations
  ) => {
    const sets_informations = sets.sets_informations;
    const addressId = pickupAddress.find(
      (pa) => pa.town === sets_informations?.[0]?.gauge_location
    )?.id;
    setValue('currency', sets_informations?.[0]?.currency);

    const defaultSets = sets_informations?.map((set, idx) => {
      setValue(`sets.${idx}.proforma_value`, `${set.proforma_value}`);
      trigger(`sets.${idx}.proforma_value`);
      const business_number = bookingData?.find(
        (booking) => booking.set_id === set.set_id
      )?.business_number;
      return {
        ...set,
        business_number: business_number,
        proforma_value: `${set.proforma_value}`,
        comments: set.comments || '',
        gauges: set.gauges.map((gauge) => ({
          ...gauge,
          is_present: gauge.is_present ? true : false,
          is_damaged: gauge.is_damaged ? true : false,
          grid_status: GridStatus.KO,
        })),
      };
    });
    setDeliveryAddress(pickupAddress.find((pa) => pa.id === addressId));
    replace(defaultSets);
    setValue('comments', sets.comments);
    trigger(['sets', 'comments']);
    setSetsInformations(defaultSets as any);
  };

  /* istanbul ignore next */
  const handleConfirmInspectDialog = (gauges: SetGaugeModel[], setNb: number) => {
    const newSets = getValues('sets').map((set, idx) => {
      if (idx === setNb) {
        return { ...set, gauges, grid_status: GridStatus.OK };
      }
      return set;
    });
    replace(newSets);
    trigger('sets');
    setErrorInspect(!!newSets.find((set) => set.grid_status !== GridStatus.OK));
  };

  const handleChangeRadioButtonCost = (e) => {
    setValue('has_return_delivery_cost', e.target.value);
    trigger('has_return_delivery_cost');
  };

  /* istanbul ignore next */
  const onSubmit: SubmitHandler<FormSchemaType> = (data) => {
    const confirmReturnData = mapConfirmReturnFormValues(data, currentShipment, userId);
    saveConfirmReturn(confirmReturnData)
      .then(() => {
        enqueueSnackbar(t('saveConfirm.success'), {
          preventDuplicate: true,
          variant: 'success',
        });
        redirectTo(`../`, navigate, {
          state: { from: location },
        });
      })
      .catch(function (error) {
        enqueueSnackbar(t('saveConfirm.error'), {
          preventDuplicate: true,
          variant: 'error',
        });
        console.error(error);
      });
  };

  return (
    <ContainerOutlet>
      <FormProvider {...methods}>
        <Stack direction="row" justifyContent="space-between" className="tw-mb-6">
          <Stack direction="row" justifyContent="space-between">
            <VamPageTitle
              title={t('title')}
              subTitle={t('tag')}
              breadcrumbRoutes={[
                {
                  breadcrumb: 'Manage shipments',
                  path: '/admin/vam-easy-gauge/manage-shipments',
                },
              ]}
            />
          </Stack>
          <Stack direction="row" spacing={3} alignItems="center">
            {errorInspect ? (
              <Notification
                title={t('notification.title')}
                description={
                  <Trans components={{ bold: <strong /> }}>{t('notification.description')}</Trans>
                }
                severity="error"
              />
            ) : null}
            <SimpleButton
              type="submit"
              variant="cta"
              data-testid="confirm-new-return-submit-btn"
              onClick={handleSubmit(onSubmit)}
              disabled={
                formState.isLoading ||
                !formState.isValid ||
                !!Object.keys(formState.errors)?.length ||
                !getValues('sets')?.length ||
                errorInspect
              }
            >
              {t('submit')}
            </SimpleButton>
          </Stack>
        </Stack>
        <Grid
          container
          rowSpacing={4}
          columnSpacing={{ xs: 2, sm: 3, md: 4 }}
          style={{
            backgroundColor: $lightBlue,
          }}
        >
          <Grid item xs={12}>
            <ContainerCard
              width="100%"
              maxWidth="100%"
              title={t('returnInformations.title')}
              stepNumber={1}
              subTitle={t('returnInformations.subTitle')}
              dataTestId="confirm-return-informations-section-1"
            >
              <Stack direction="row" spacing={4} alignItems="center">
                <Typo variant="tableTitle">{t('returnInformations.hasInvoiceDeliveryCost')}</Typo>
                <RadioGroup
                  value={getValues('has_return_delivery_cost')}
                  className="tw-gap-12"
                  row
                  onChange={handleChangeRadioButtonCost}
                  data-testid="has-return-delivery-cost-radio-group"
                >
                  <FormControlLabel
                    data-testid="has-return-delivery-cost-radio-yes"
                    value={true}
                    control={<Radio />}
                    label={tCommon('switchBoolean.yes')}
                  />
                  <FormControlLabel
                    data-testid="has-return-delivery-cost-radio-no"
                    value={false}
                    control={<Radio />}
                    label={tCommon('switchBoolean.no')}
                  />
                </RadioGroup>
              </Stack>
              <FormTextField
                withFixedLabel
                inputProps={{ 'data-testid': 'return-delivery-cost-input' }}
                labelValue={`${t('returnInformations.cost')} ${getValues('currency')} :`}
                name="return_delivery_cost"
                patternValue={priceRegex}
                patternMessage="Error format price"
                placeholder="Fill return delivery cost if needed"
                type="number"
              />
            </ContainerCard>
          </Grid>
          <Grid item xs={12}>
            <ContainerCard
              width="100%"
              maxWidth="100%"
              title={t('bookingInformations.title')}
              stepNumber={2}
              subTitle={t('bookingInformations.subTitle')}
              dataTestId="confirm-return-booking-informations"
            >
              <ShippingOrderInformations
                bookings={bookingsInformations}
                sets={setsInformations}
                isReturn
                deliveryAddress={deliveryAddress}
                handleConfirmInspectDialog={handleConfirmInspectDialog}
              />
            </ContainerCard>
          </Grid>
          <Grid item xs={12}>
            <Comments
              inputTestId="confirm-return-comments-textfield"
              triggerOnBlur={true}
              title={tCommon('commentsSection.title')}
              subtitle={tCommon('commentsSection.subtitle')}
            />
          </Grid>
        </Grid>
      </FormProvider>
    </ContainerOutlet>
  );
}
