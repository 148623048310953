import { TextField, TextFieldProps } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { $grey60, $primaryBlack } from '../../../styles/colors';
import { TextFieldVariant } from '../../../utils';

// 👇 Type of Props the FormInput will receive
type FormInputProps = {
  name: string;
  label: string;
  variant?: string;
} & TextFieldProps;

export const FormLabelField: FC<FormInputProps> = ({ name, label, variant, ...otherProps }) => {
  // 👇 Utilizing useFormContext to have access to the form Context
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <TextField
          sx={{
            '.MuiFormLabel-root': {
              color: $grey60,
            },
            '.MuiInputBase-input': {
              color: $primaryBlack,
              WebkitTextFillColor: $primaryBlack,
            },
          }}
          fullWidth
          label={label}
          variant={variant || TextFieldVariant.Standard}
          error={!!errors?.name}
          helperText={(errors?.name ? errors[name]?.message : '') as any}
          {...field}
          {...otherProps}
          disabled
        />
      )}
    />
  );
};
