/* istanbul ignore file */
import axios from 'axios';

import { handleAxiosError, handleAxiosRequest, handleAxiosResponse } from './functions';

const userHttp = axios.create({
  baseURL: process.env.REACT_APP_USER_API,
  withCredentials: true,
});

const userHttpWithSpinner = axios.create({
  baseURL: process.env.REACT_APP_USER_API,
  withCredentials: true,
});

// Do something before request is sent
userHttpWithSpinner.interceptors.request.use(
  // TODO handle this
  (config) => handleAxiosRequest(config),
  (error) => handleAxiosError(error)
);

// Do something with response
userHttpWithSpinner.interceptors.response.use(
  (response) => handleAxiosResponse(response),
  (error) => handleAxiosError(error)
);
export { userHttp, userHttpWithSpinner };
