import { GridSortModel } from '@mui/x-data-grid';
import React from 'react';

import { InvoiceGrid } from '../interfaces/invoice';
import { getListingInvoice } from '../services/api/invoice-api';
import { DEFAULT_PAGE_SIZE } from '../utils';
import { INVOICE_STATUS_GRID_STATUS, InvoiceStatus, InvoiceType } from '../utils/constants/invoice';
import { formatDate } from '../utils/functions';
import { isNotNullOrUndefined } from '../utils/functions/object';

export function useFetchBookingInvoicesByStatus(
  statusFilter: InvoiceStatus,
  sort: GridSortModel,
  page?: number,
  depotId?: number
) {
  const [datagrid, setDatagrid] = React.useState<InvoiceGrid[]>([]);

  React.useEffect(() => {
    const fetchData = async () => {
      getListingInvoice(
        {
          data_filter: {
            invoice_status: statusFilter ? [statusFilter] : [],
            invoice_type: [InvoiceType.BOOKING],
          },
          pagination: {
            skip: isNotNullOrUndefined(page) ? DEFAULT_PAGE_SIZE * page : null,
            limit: isNotNullOrUndefined(page) ? DEFAULT_PAGE_SIZE : null,
          },
          sort,
        },
        depotId
      ).then(({ data }) => {
        const dataGrid = data.invoices.map((row, idx) => ({
          ...row,
          id: idx,
          creation_date: formatDate(row.creation_date, false),
          modification_date: row.modification_date ? formatDate(row.modification_date, false) : '-',
          start_date: row.start_date ? formatDate(row.start_date, false) : '-',
          end_date: row.end_date ? formatDate(row.end_date, false) : '-',
          grid_status:
            INVOICE_STATUS_GRID_STATUS[row.invoice_status || InvoiceStatus.TO_BE_TREATED],
        }));
        setDatagrid(dataGrid);
      });
    };

    fetchData();
  }, [statusFilter, page, sort]);

  return { datagrid };
}
