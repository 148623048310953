import { Typo } from '@digital-at-vallourec/steel-design-system-react';
import PanToolIcon from '@mui/icons-material/PanTool';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ContainerOutlet } from '../ContainerOutlet/ContainerOutlet';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';

export const NotAuthorized = () => {
  const navigate = useNavigate();
  // we put -2 to return twice in the history router manager
  const goBack = () => navigate('/');

  // TODO waiting for mockup page
  return (
    <>
      <Header />
      <ContainerOutlet>
        <section className="tw-p-8">
          <Typo variant="h1Light">Unauthorized</Typo>
          <br />
          <p>
            <PanToolIcon /> You do not have access to the requested page.
          </p>
          <div className="tw-flex tw-grow tw-my-8">
            <Button variant="secondary-dark" onClick={goBack}>
              Go to home page
            </Button>
          </div>
        </section>
      </ContainerOutlet>
      <Footer />
    </>
  );
};
