import { GridStatus } from '../../components/data-grid/utils';
import {
  $darkBerry,
  $green,
  $primaryBlack,
  $primaryNavy,
  $red,
  $yellow,
} from '../../styles/colors';

export const DEFAULT_PROFORMA_VALUE = 500;

export enum SetAvailabilityStatus {
  Available = 'available',
  Reserved = 'reserved',
  Validated = 'validated',
  AtLicensee = 'at_licensee',
  ReturnOngoing = 'return_ongoing',
  Quarantine = 'quarantine',
  UnderCalibration = 'under_calibration',
  Missing = 'missing',
}

export const SET_AVAILABILITY_STATUS_COLOR = {
  available: $green,
  reserved: $primaryNavy,
  at_licensee: $primaryNavy,
  returned: $yellow,
  quarantine: $primaryBlack,
  under_calibration: $darkBerry,
  missing: $red,
};

export enum SetState {
  Complete = 'complete',
  CompleteNotCalibrated = 'completeNotCalibrated',
  Incomplete = 'incomplete',
  IncompleteNotCalibrated = 'incompleteNotCalibrated',
}

export const SET_STATE_GRID_MAPPING = {
  complete: GridStatus.OK,
  completeNotCalibrated: GridStatus.EXPIRE,
  incomplete: GridStatus.KO,
  incompleteNotCalibrated: GridStatus.KO,
};
