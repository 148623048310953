import { MoreVert } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';

import { MenuOptionsProps, VamMenu } from '../VamMenu/VamMenu';

export interface VamMoreOptionsProps {
  handleOpen?: Function;
  handleClose?: Function;
  options: MenuOptionsProps[];
  row?: unknown;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
}

export function VamMoreOptions({
  handleOpen,
  handleClose,
  options,
  row,
  size,
  disabled,
  ...otherProps
}: VamMoreOptionsProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  /* istanbul ignore next */
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    handleOpen && handleOpen();
    event.stopPropagation();
  };
  /* istanbul ignore next */
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleClose && handleClose();
  };

  return (
    <>
      <Button
        id="btn-more-action"
        data-testid="btn-more-action-testid"
        variant="icon-secondary-dark-blue"
        size={size || 'medium'}
        aria-controls={openMenu ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        onClick={handleMenuClick}
        disabled={disabled}
      >
        <MoreVert />
      </Button>
      <VamMenu
        anchorEl={anchorEl}
        open={openMenu}
        handleClose={handleMenuClose}
        options={options}
        data-testid="vam-menu-detail-actions"
        row={row}
        {...otherProps}
      />
    </>
  );
}
