import { zodResolver } from '@hookform/resolvers/zod';
import { Check } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { PickupAddress } from '../../interfaces/address';
import { savePickupAddress } from '../../services/api/veg-api';
import { fetchPickupAddressesThunk, selectUser } from '../../services/store/userSlice';
import { isoCountries } from '../../utils/constants/countries/countries_code_alpha_2';
import { strToNumber } from '../../utils/functions';
import { ContainerOutlet } from '../layout/ContainerOutlet/ContainerOutlet';
import { CtaLoadingButton, VamPageTitle } from '../shared';
import { FormSchemaType, creationFormSchema, defaultValues } from './GaugeDepotForm';
import { GaugeDepotInformations } from './GaugeDepotInformations';

export default function GaugeDepotCreation() {
  const { firstName, lastName } = useAppSelector(selectUser);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('gauge_depot');
  const dispatch = useAppDispatch();

  const methods = useForm<FormSchemaType>({
    defaultValues: defaultValues,
    resolver: zodResolver(creationFormSchema),
    mode: 'onChange',
  });
  const { handleSubmit, reset } = methods;

  /* istanbul ignore next */
  const addNewGaugeDepot: SubmitHandler<FormSchemaType> = (data) => {
    const dataApi: PickupAddress = {
      site: data.site,
      street: data.street,
      town: data.town,
      country_code: data.country_code,
      country: isoCountries[data.country_code],
      email: data.email,
      zip_code: data.zip_code,
      phone_number: `+${data.phone_number}`,
      is_active: data.is_active,
      licensee_number: strToNumber(data.licensee_number) as number,
      created_by: `${firstName} ${lastName}`,
    };
    savePickupAddress(dataApi)
      .then(() => {
        reset();
        dispatch(fetchPickupAddressesThunk());
        enqueueSnackbar(t('createDepot.saveDepotSuccess'), {
          preventDuplicate: true,
          variant: 'success',
        });
      })
      .catch((error) => {
        enqueueSnackbar(t('createDepot.saveDepotError'), {
          preventDuplicate: true,
          variant: 'error',
        });
        console.error(error);
      });
  };

  return (
    <ContainerOutlet>
      <FormProvider {...methods}>
        <Stack direction="row" justifyContent="space-between" className="tw-mb-6">
          <Stack direction="row" spacing={2}>
            <VamPageTitle
              subTitle={t('createDepot.tag')}
              title={t('createDepot.title')}
              breadcrumbRoutes={[
                {
                  breadcrumb: t('createDepot.breadcrumb.depotListing'),
                  path: '../depot-listing',
                },
                {
                  breadcrumb: t('createDepot.breadcrumb.addNewDepot'),
                  path: '#',
                },
              ]}
            />
          </Stack>
          <Stack direction="row" spacing={3} alignItems="center">
            <CtaLoadingButton
              buttonText={t('createDepot.saveButton')}
              type="submit"
              variant="cta"
              data-testid="submit-create-depot-btn"
              startIcon={<Check />}
              onClick={handleSubmit(addNewGaugeDepot)}
              disabled={
                methods.formState.isLoading ||
                !methods.formState.isValid ||
                !!Object.keys(methods.formState.errors).length
              }
            />
          </Stack>
        </Stack>
        <GaugeDepotInformations />
      </FormProvider>
    </ContainerOutlet>
  );
}
