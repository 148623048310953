import { FormControlLabel, FormGroup, Switch, SwitchProps } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type FormSwitchProps = {
  label: string;
  defaultValue?: any;
} & SwitchProps;

export const FormSwitchField: FC<FormSwitchProps> = ({
  name,
  label,
  color,
  defaultValue,
  ...otherProps
}: FormSwitchProps) => {
  // 👇 Utilizing useFormContext to have access to the form Context
  const { control } = useFormContext();

  return (
    <FormGroup>
      <FormControlLabel
        classes={{
          labelPlacementStart: 'flex-justify-start !tw-ml-0',
        }}
        labelPlacement="start"
        label={label}
        control={
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field }) => <Switch color={color || 'primary'} {...field} {...otherProps} />}
          />
        }
      />
    </FormGroup>
  );
};
