import { LabelBox } from '@digital-at-vallourec/steel-design-system-react';
import { ReactElement } from 'react';

import { $grey5, $grey30, $primaryWhite } from '../../../styles/colors';
import { backgroundActiveNode } from '../../../styles/common';

interface StatusLabelBoxProps {
  label: string;
  isActive: boolean;
}

export function StatusLabelBox({ label, isActive }: StatusLabelBoxProps): ReactElement {
  return isActive ? (
    <LabelBox key={label} label={label} background={backgroundActiveNode} color={$primaryWhite} />
  ) : (
    <LabelBox key={label} label={label} background={$grey5} color={$grey30} />
  );
}
