import { SetConnectionManagementFilter } from '../../interfaces/set-creation';
import { ProductFilter } from '../../interfaces/veg';
import { strToNumber } from './object';

export function mapToProductFilter(
  valueChanges: Record<string, any>,
  objectKeysExtended: string[] = []
): ProductFilter {
  return Object.entries(valueChanges).reduce((currentItem, [key, value]) => {
    const addValue = {};
    if (
      [
        'connection',
        'od_inch',
        'end',
        'application',
        'option',
        'min_weight',
        'max_weight',
        ...objectKeysExtended,
      ].includes(key)
    ) {
      addValue[key] = value;
    } else if (key === 'weight_thickness' && value) {
      const result = gettingWeightThickness(value);
      addValue['weight'] = result?.weight;
      addValue['thickness'] = result?.thickness;
    } else if (key === 'min_weight_max_weight' && value) {
      // value = "29.7 # to 29.7 #",
      const v = value.replace(/#/g, '').split('to');
      addValue['min_weight'] = strToNumber(v[0]);
      addValue['max_weight'] = strToNumber(v[1]);
    } else if (key === 'applicabilities_connection' && value) {
      addValue['connection'] = value;
    } else if (key === 'applicabilities_od' && value) {
      addValue['od_inch'] = value;
    }

    return {
      ...currentItem,
      ...addValue,
    };
  }, {}) as ProductFilter;
}

export function gettingWeightThickness(value: string): { weight: number; thickness: number } {
  const addValue = {};
  'weight_thickness'.split('_').forEach((key, index) => {
    if (value) {
      const v = value.split('/');
      const conv_v = strToNumber(v[index]?.replace('#', '')?.replace('"', ''));
      addValue[key] = conv_v;
    }
  });

  return addValue as { weight: number; thickness: number };
}

export function mapToProductFilterFromArrays(
  valueChanges: Record<string, any>,
  objectKeysExtended: string[] = []
): SetConnectionManagementFilter {
  return Object.entries(valueChanges).reduce((currentItem, [key, value]) => {
    const addValue = {};
    if (
      [
        'connection',
        'od_inch',
        'end',
        'application',
        'option',
        'min_weight',
        'max_weight',
        ...objectKeysExtended,
      ].includes(key)
    ) {
      if (key === 'set_ids' && value?.length) {
        const listSetIdsNumber: number[] = value
          .split(',')
          .map((element: string) => {
            const num = strToNumber(element) as number;
            return isNaN(num) ? null : num;
          })
          .filter((element: number) => !!element);
        addValue[key] = listSetIdsNumber;
      } else if (key === 'business_number' && value?.length) {
        const listBusinessNumber: string[] = value
          .split(',')
          .filter((element: string) => !!element);
        addValue[key] = listBusinessNumber;
      } else {
        addValue[key] = value;
      }
    } else if (key === 'min_weight_max_weight' && value && value?.length) {
      addValue['min_weight'] = [];
      addValue['max_weight'] = [];
      value.forEach((minMaxWeight) => {
        // minMaxWeight = "29.7 # to 29.7 #",
        const v = minMaxWeight.replace(/#/g, '').split('to');
        addValue['min_weight'].push(strToNumber(v[0]));
        addValue['max_weight'].push(strToNumber(v[1]));
      });
    }

    return {
      ...currentItem,
      ...addValue,
    };
  }, {}) as SetConnectionManagementFilter;
}
