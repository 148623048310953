import { Typo } from '@digital-at-vallourec/steel-design-system-react';
import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackingStatus } from '../../../interfaces/shipping';
import { getExpeditionStatus } from '../../../services/api/shipping-api';
import { $lightBlue, $primaryNavy } from '../../../styles/colors';

interface ExpeditionStatusProps {
  bookingId: number;
}

const Container = styled.div`
  background-color: ${$lightBlue};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
`;

const ExpeditionStatus: React.FC<ExpeditionStatusProps> = ({ bookingId }) => {
  const { t } = useTranslation('gauge', { keyPrefix: 'manageBookingDetail.shipment' });
  const [expeditionStatus, setExpeditionStatus] = React.useState<TrackingStatus>({
    status: '',
    description: '',
    trackingUrl: '',
  });

  useEffect(() => {
    getExpeditionStatus(bookingId)
      .then(({ data }) => {
        setExpeditionStatus(data || { status: '', description: '', trackingUrl: '' });
      })
      .catch((error) => {
        console.error('🚀 ~ file: ExpeditionStatus.tsx ~ fetch ~ error', error);
      });
  }, [bookingId]);

  return (
    <Stack spacing={4} direction="column" className="tw-px-8">
      <Container>
        <div className="tw-flex tw-justify-center">
          <Typo
            fontSize="14px"
            fontWeight="500"
            textTransform="uppercase"
            color={$primaryNavy}
            className="text-4xl font-bold"
          >
            {t('shipmentStatus')}
          </Typo>
        </div>
        <div className="tw-my-1">
          <Typo variant="body2">
            {t('trackingStatus')} :&nbsp;
            <span data-testid="expedition-tracking-status" className="tw-font-bold tw-uppercase">
              {expeditionStatus?.status}
            </span>
          </Typo>
        </div>
        <div className="tw-my-1">
          <Typo variant="body2">
            {t('shipmentEstimatedDate')} :&nbsp;
            <span
              data-testid="tracking-estimated-deliverydate"
              className="tw-font-bold tw-uppercase"
            >
              {expeditionStatus?.estimatedDeliveryDate || '-'}
            </span>
          </Typo>
        </div>
        <div className="tw-my-1">
          <Typo variant="body2">{t('shipmentDetail')}</Typo>
          <ul className="tw-p-0">
            <li>
              <Typo variant="subtitle4" data-testid="details-tracking-shipment-timestamp">
                {t('shipmentTimeStamp', { timestamp: expeditionStatus?.shipmentTimestamp })}
              </Typo>
            </li>
          </ul>
        </div>
      </Container>
    </Stack>
  );
};

export default ExpeditionStatus;
