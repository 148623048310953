import { SimpleButton, Typo } from '@digital-at-vallourec/steel-design-system-react';
import { Add, DeleteOutlined } from '@mui/icons-material';
import { Grid, Switch } from '@mui/material';
import {
  GridPaginationModel,
  GridRowSelectionModel,
  GridSortModel,
  getGridStringOperators,
} from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import GaugeModelIcon from '../../assets/icons/icon-gauge.svg';
import { useAppSelector } from '../../hooks';
import { GaugeModel as GaugeModelType } from '../../interfaces/set-model';
import { getGaugeModels, saveGaugeModel } from '../../services/api/set-model-api';
import { selectUser } from '../../services/store/userSlice';
import { DEFAULT_PAGINATION_MODEL, VegWebsiteStatus } from '../../utils';
import { handleFilterSubmit } from '../../utils/functions';
import { VamDataGrid } from '../data-grid/VamDataGrid';
import { GridStatus } from '../data-grid/utils';
import { ContainerCard, FilterInput, GridOverlayContainer, TitleBar } from '../shared';
import { AddGaugeModel } from './AddGaugeModel';
import { DialogComponent } from './DialogComponent';
import { gaugeModelColumns, searchGaugeModelColumns } from './utils';

interface GaugeModelData {
  designation_vam: string;
  drawing_number: string;
  reference_number: string;
  immobilisable: boolean;
  weight: number;
  price: number;
}

export interface GaugeModelProps {
  gaugeModels?: GaugeModelType[];
  websiteStatus?: VegWebsiteStatus;
}

export const GaugeModel = (props: GaugeModelProps) => {
  const { t } = useTranslation('gauge', { keyPrefix: 'setModel.gaugeModel' });
  const { t: translateCommon } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const isWebStatusNotDraft = React.useMemo(
    () => props.websiteStatus !== VegWebsiteStatus.Draft,
    [props.websiteStatus]
  );
  const user = useAppSelector(selectUser);
  const [isAddDialogOpen, setIsAddDialogOpen] = React.useState(false);
  const [isSearchDialogOpen, setIsSearchDialogOpen] = React.useState(false);
  // Need to be initialized with BDD after save model done
  const [gaugeModels, setGaugeModels] = React.useState([]);
  const [totalGaugeModels, setTotalGaugeModels] = React.useState(0);
  const [selectedGaugeModels, setSelectedGaugeModels] = React.useState([]);
  const [confirmedGaugeModels, setConfirmedGaugeModels] = React.useState([]);
  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>(DEFAULT_PAGINATION_MODEL);
  const [sortModel, setSortModel] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  const formProps = useForm<GaugeModelData>();
  const { control } = useFormContext();
  const { replace } = useFieldArray({
    control,
    name: 'gauge_models',
  });
  // Initialize a set to store added gauge IDs
  const existentGaugeIds = props.gaugeModels
    ? new Set<number>(props.gaugeModels.map((gauge) => gauge.id))
    : new Set<number>();

  useEffect(() => {
    if (props.gaugeModels?.length) {
      setConfirmedGaugeModels(
        props.gaugeModels.map((gauge) => ({
          ...gauge,
          initialGaugeRequired: gauge.is_gauge_required,
        }))
      );
    }
  }, [props.gaugeModels]);

  const PAGE_SIZE = 5;

  useEffect(() => {
    fetchData();
  }, [paginationModel.page, sortModel, filters]);

  const fetchData = () => {
    getGaugeModels({
      filters: filters,
      sort: sortModel,
      limit: PAGE_SIZE,
      offset: paginationModel.page * PAGE_SIZE,
    })
      .then(({ data: { gauge_models, count } }) => {
        /* istanbul ignore next */
        setGaugeModels(
          gauge_models.map((gaugeModel) => ({
            grid_status: GridStatus.NAVY,
            ...gaugeModel,
          }))
        );
        /* istanbul ignore next */
        setTotalGaugeModels(count);
      })
      .catch((error) => {
        console.error('🚀 ~ file: GaugeModel.tsx ~ fetch ~ getGaugeModels ~ error', error);
      });
  };

  /* istanbul ignore next */
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
    setPaginationModel({ ...paginationModel, page: 0 });
  }, []);

  /* istanbul ignore next */
  const onSubmit: SubmitHandler<GaugeModelData> = ({
    designation_vam,
    drawing_number,
    reference_number,
    immobilisable,
    weight,
    price,
  }) => {
    const vegData = {
      designation_vam,
      drawing_number,
      reference_number,
      immobilisable: immobilisable || false,
      weight,
      price,
      creation_date: new Date(),
      created_by: `${user.lastName} ${user.firstName}`,
    };
    saveGaugeModel(vegData)
      .then(() => {
        setIsAddDialogOpen(false);
        enqueueSnackbar(t('gaugeModelCreationSuccess'), {
          preventDuplicate: true,
          variant: 'success',
        });
      })
      .catch(function (error) {
        console.error(error);
        if (error.response.status === 409) {
          enqueueSnackbar(t('gaugeModelAlreadyExists'), {
            preventDuplicate: true,
            variant: 'error',
          });
        } else {
          enqueueSnackbar(translateCommon('notification.error'), {
            preventDuplicate: true,
            variant: 'error',
          });
        }
      });
  };

  /* istanbul ignore next */
  const handleConfirmSelection = () => {
    const gaugeModelsSelected = confirmedGaugeModels.concat(
      selectedGaugeModels.filter((row) => !confirmedGaugeModels.find((item) => item.id === row.id))
    );

    const finalGaugeModels = gaugeModelsSelected.map((item) => {
      const isGaugeRequired = isWebStatusNotDraft
        ? false
        : item.is_gauge_required === null
        ? true
        : item.is_gauge_required;

      return {
        ...item,
        is_gauge_required: isGaugeRequired,
      };
    });
    setConfirmedGaugeModels(finalGaugeModels);
    replace(
      finalGaugeModels.map((item) => ({
        gauge_model_id: item.id,
        required: item.is_gauge_required,
      }))
    );
    setIsSearchDialogOpen(false);
  };

  const SearchGaugeModel = (
    <Grid container columnSpacing={5} rowGap={1}>
      <Grid item xs={6}>
        <TitleBar title={t('searchGaugeModel', { context: 'lower' })}>
          <SimpleButton
            startIcon={<Add />}
            variant="secondary-dark"
            size="small"
            onClick={
              /* istanbul ignore next */ () => {
                setSelectedGaugeModels(
                  selectedGaugeModels.concat(
                    gaugeModels
                      .filter(
                        (row) =>
                          selectionModel.includes(row.id) &&
                          !selectedGaugeModels.find((elem) => elem.id === row.id)
                      )
                      .map((row) => ({ ...row, grid_status: GridStatus.OK }))
                  )
                );
                setSelectionModel([]);
              }
            }
          >
            <Typo variant="subtitle2" data-testid="add-to-selection-btn">
              {t('addToSelection')}
            </Typo>
          </SimpleButton>
        </TitleBar>
      </Grid>
      <Grid item xs={6} display="grid" alignItems="center">
        <TitleBar title={t('gaugeModelsSelected')} />
      </Grid>
      <Grid item xs={6}>
        <VamDataGrid
          width={200}
          showRowLeftThickBorder
          columns={searchGaugeModelColumns.map((column) => ({
            ...column,
            filterOperators: getGridStringOperators()
              .filter((operator) => operator.value === 'contains')
              .map((operator) => ({
                ...operator,
                InputComponent: FilterInput,
                InputComponentProps: {
                  value: filters.find(
                    /* istanbul ignore next */
                    (filter) => filter.field === column.field
                  )?.value,
                  handleSubmit: (column, value) =>
                    /* istanbul ignore next */
                    handleFilterSubmit(
                      column,
                      value,
                      filters,
                      setFilters,
                      setPaginationModel,
                      paginationModel
                    ),
                },
              })),
          }))}
          rows={gaugeModels}
          height={400}
          disableRowSelectionOnClick
          checkboxSelection
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={
            /* istanbul ignore next */
            (selectionModel: GridRowSelectionModel) => setSelectionModel(selectionModel)
          }
          paginationModel={{ ...paginationModel, pageSize: PAGE_SIZE }}
          onPaginationModelChange={
            /* istanbul ignore next */ (gridModelPagination: GridPaginationModel) => {
              if (!selectionModel.length) {
                setPaginationModel(gridModelPagination);
              } else {
                enqueueSnackbar(t('changePageError'), {
                  preventDuplicate: true,
                  variant: 'error',
                });
              }
            }
          }
          rowCount={totalGaugeModels}
          pageSize={PAGE_SIZE}
          paginationMode="server"
          keepNonExistentRowsSelected
          sortingMode="server"
          filterMode="server"
          onSortModelChange={handleSortModelChange}
          sortModel={sortModel}
        />
      </Grid>
      <Grid item xs={6}>
        <VamDataGrid
          showRowLeftThickBorder
          columns={searchGaugeModelColumns.concat([
            {
              field: 'action',
              align: 'center',
              flex: 0.5,
              renderHeader: () => <></>,
              /* istanbul ignore next */
              renderCell(params) {
                return (
                  <SimpleButton
                    size="small"
                    variant="remove-red"
                    onClick={
                      /* istanbul ignore next */
                      () => {
                        const gaugeModels = selectedGaugeModels.filter(
                          (item) => item.id !== params.row.id
                        );
                        setSelectedGaugeModels(gaugeModels);
                      }
                    }
                  >
                    <DeleteOutlined fontSize="small" />
                  </SimpleButton>
                );
              },
            },
          ])}
          rows={selectedGaugeModels}
          height={400}
          pageSize={PAGE_SIZE}
          disableColumnFilter
        />
      </Grid>
    </Grid>
  );

  return (
    <ContainerCard
      title={t('title', { count: 1 })}
      icon={GaugeModelIcon}
      subTitle={t('subtitle')}
      width="100%"
      maxWidth="100%"
      dataTestId="gauge-model-card"
      actionHeaderNode={
        <DialogComponent
          title={t('addGaugeModelTitle')}
          subTitle={t('addGaugeModelSubtitle')}
          searchLabelBtn={t('createGaugeModel')}
          icon={<Add />}
          handleOpenDialog={/* istanbul ignore next */ () => setIsAddDialogOpen(true)}
          buttonVariant="secondary-dark"
          isOpen={isAddDialogOpen}
          confirmSelection={formProps.handleSubmit(onSubmit)}
          handleCloseDialog={/* istanbul ignore next */ () => setIsAddDialogOpen(false)}
          confirmLabelBtn={t('createGaugeModel')}
          headerIcon={<img src={GaugeModelIcon} alt="gauge-model-icon" />}
          withCloseButton
        >
          <FormProvider {...formProps}>
            <AddGaugeModel />
          </FormProvider>
        </DialogComponent>
      }
    >
      <DialogComponent
        title={t('title', { count: 2 })}
        subTitle={t(isWebStatusNotDraft ? 'addOptionalGaugeModel' : 'searchGaugeModel', {
          context: 'lower',
        })}
        searchLabelBtn={t(isWebStatusNotDraft ? 'addOptionalGaugeModel' : 'searchGaugeModel', {
          context: 'upper',
        })}
        isOpen={isSearchDialogOpen}
        fullWidth
        withCloseButton
        handleOpenDialog={() => /* istanbul ignore next */ setIsSearchDialogOpen(true)}
        handleCloseDialog={() => /* istanbul ignore next */ setIsSearchDialogOpen(false)}
        confirmSelection={handleConfirmSelection}
      >
        {SearchGaugeModel}
      </DialogComponent>

      <VamDataGrid
        data-testid="grid-gauge-model"
        hideFooter
        disableRowSelectionOnClick
        disableColumnFilter
        columns={gaugeModelColumns.concat([
          {
            field: 'is_gauge_required',
            headerName: 'required',
            flex: 1,
            /* istanbul ignore next */
            renderCell(params) {
              return (
                <Switch
                  checked={params.row.is_gauge_required}
                  disabled={
                    props.websiteStatus !== VegWebsiteStatus.Draft &&
                    !params.row.is_gauge_required &&
                    !params.row.initialGaugeRequired
                  }
                  onChange={(event) => {
                    const gaugeModels = confirmedGaugeModels.map((item) => {
                      if (item.id === params.row.id) {
                        return { ...item, is_gauge_required: event.target.checked };
                      }
                      return item;
                    });
                    setConfirmedGaugeModels(gaugeModels);
                    replace(
                      gaugeModels.map((item) => ({
                        gauge_model_id: item.id,
                        required: item.is_gauge_required,
                      }))
                    );
                  }}
                />
              );
            },
          },
          {
            field: 'action',
            align: 'center',
            flex: 0.5,
            renderHeader: () => <></>,
            renderCell: (params) => (
              <SimpleButton
                size="small"
                variant="remove-red"
                disabled={existentGaugeIds.has(params.row.id)}
                onClick={() => {
                  const gaugeModels = confirmedGaugeModels.filter(
                    (item) => item.id !== params.row.id
                  );
                  setConfirmedGaugeModels(gaugeModels);
                  setSelectedGaugeModels(gaugeModels);
                  replace(
                    gaugeModels.map((item) => ({
                      gauge_model_id: item.id,
                      required: item.is_gauge_required,
                    }))
                  );
                }}
              >
                <DeleteOutlined fontSize="small" />
              </SimpleButton>
            ),
          },
        ])}
        rows={confirmedGaugeModels}
        showRowLeftThickBorder
        height={400}
        slots={{
          noRowsOverlay: () => <GridOverlayContainer text={t('noRowsLabel')} />,
        }}
      />
    </ContainerCard>
  );
};
