import { Stack } from '@mui/material';
import React from 'react';

import { QuickAccessButtonsContainer } from '../../../styles/common';
import { BreadcrumbRoute, VamPageTitle } from '../../shared';
import { VamQuickAccessButton } from '../../shared/VamQuickAccessButton/VamQuickAccessButton';

export interface QuickAccessButtonProps {
  dataTestId?: string;
  handleClick: Function;
  subtitle: string;
  title: string;
}

interface ManageBookingCommonProps {
  title: string;
  subtitle: string;
  breadcrumbRoutes: BreadcrumbRoute[];
  accessButtons: QuickAccessButtonProps[];
}

export function ManageBookingCommon({
  title,
  subtitle,
  breadcrumbRoutes,
  accessButtons,
}: ManageBookingCommonProps) {
  return (
    <Stack direction="row" justifyContent="space-between" className="tw-mb-6">
      <VamPageTitle subTitle={subtitle} title={title} breadcrumbRoutes={breadcrumbRoutes} />
      <QuickAccessButtonsContainer>
        {accessButtons?.map((accessButton, idx) => (
          <VamQuickAccessButton
            key={idx}
            title={accessButton.title}
            subTitle={accessButton.subtitle}
            width={250}
            right={0}
            onClick={() => accessButton.handleClick()}
            data-testid={accessButton.dataTestId}
          />
        ))}
      </QuickAccessButtonsContainer>
    </Stack>
  );
}
