import { Grid } from '@digital-at-vallourec/steel-design-system-react';

import { VamDataGridProps } from './utils/interfaces';

// note: by default headerAlign and align are in center for columns
export function VamDataGrid({
  height,
  pageSize,
  autoRowheight,
  initialStatePaginationModel,
  initialStateSortingModel,
  initialStateFilterModel,
  initialStateColumns,
  initialStatePreferencePanel,
  ...othersProps
}: VamDataGridProps) {
  const gridPageSize = pageSize || 50;

  return (
    <Grid
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: gridPageSize,
            page: 0,
            ...initialStatePaginationModel,
          },
        },
        sorting: { sortModel: initialStateSortingModel },
        filter: { filterModel: initialStateFilterModel },
        columns: initialStateColumns,
        preferencePanel: initialStatePreferencePanel,
      }}
      {...othersProps}
      showRowBackground={true}
      pageSizeOptions={[gridPageSize]}
      getRowHeight={autoRowheight ? /* istanbul ignore next */ () => 'auto' : null}
      heightGrid={height}
    />
  );
}
