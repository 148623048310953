import { CircleStatus, SimpleButton } from '@digital-at-vallourec/steel-design-system-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Grid, Stack } from '@mui/material';
import { saveAs } from 'file-saver';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import ShipIcon from '../../../assets/icons/prepare-shipment.svg';
import { useAppSelector } from '../../../hooks';
import { ManageBookingGrid, SetBookingInformations } from '../../../interfaces/veg';
import { getSetsInformations } from '../../../services/api/set-creation-api';
import { exportDispatchAllDocuments, saveDispatch } from '../../../services/api/shipping-api';
import { selectUserId } from '../../../services/store/userSlice';
import { $lightBlue } from '../../../styles/colors';
import { BOOKING_STATUS_COLORS, BookingStatus } from '../../../utils';
import { redirectTo } from '../../../utils/functions/helpers';
import { ContainerOutlet } from '../../layout/ContainerOutlet/ContainerOutlet';
import { ContainerCard, VamPageTitle } from '../../shared';
import Comments from '../../shared/Comments/Comments';
import { mapDispatchFormValues } from '../utils';
import { FormSchemaType, defaultValues, dispatchFormSchema } from './DispatchForm';
import { ShippingInformations } from './ShippingInformations';
import { ShippingOrderInformations } from './ShippingOrderInformations';

export function Dispatch() {
  const { t } = useTranslation('gauge');
  const { t: tCommon } = useTranslation('common');
  const location = useLocation();
  const [setsInformations, setSetsInformations] = React.useState<SetBookingInformations[]>(null);
  const [disableSaveDispatch, setDisableSaveDispatch] = React.useState<boolean>(false);

  const currentBookings: ManageBookingGrid[] = location?.state?.currentSelectedBookings;
  const licenseeNumber = currentBookings?.[0]?.licensee_number;
  const userId = useAppSelector(selectUserId);
  const setIds: number[] = currentBookings?.map((booking) => booking.set_id);
  const bookingIds: number[] = currentBookings?.map((booking) => booking.booking_id);

  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchSetsInformations();
      } catch (error) {
        /* istanbul ignore next */
        console.error('🚀 ~ file: Dispatch.tsx ~ line 36 ~ fetchData ~ error', error);
      }
    };
    fetchData();
  }, [currentBookings]);

  const fetchSetsInformations = async () => {
    const { data } = await getSetsInformations(setIds);
    setSetsInformations(data);
  };

  const methods = useForm<FormSchemaType>({
    defaultValues: defaultValues,
    resolver: zodResolver(dispatchFormSchema),
    mode: 'onChange',
  });

  const { handleSubmit, getValues, formState } = methods;

  /* istanbul ignore next */
  const onSubmit: SubmitHandler<FormSchemaType> = (data) => {
    const vegData = mapDispatchFormValues(data, currentBookings, licenseeNumber, userId);
    saveDispatch(vegData)
      .then(() => {
        setDisableSaveDispatch(true);
        enqueueSnackbar(t('dispatchDetail.success'), {
          preventDuplicate: true,
          variant: 'success',
        });
        exportDispatchAllDocuments(bookingIds)
          .then(({ data }) => {
            saveAs(data, `dispatch_documents_licensee_nb_${licenseeNumber}.zip`);
            redirectTo(`../`, navigate, {
              state: { from: location },
            });
          })
          .catch((error) => {
            enqueueSnackbar(tCommon('notification.printError'), {
              preventDuplicate: true,
              variant: 'error',
            });
            console.error(error);
          });
      })
      .catch(function (error) {
        enqueueSnackbar(tCommon('notification.error'), {
          preventDuplicate: true,
          variant: 'error',
        });
        console.error(error);
      });
  };

  return (
    <ContainerOutlet>
      <FormProvider {...methods}>
        <Stack direction="row" justifyContent="space-between" className="tw-mb-6">
          <Stack direction="row" justifyContent="space-between">
            <VamPageTitle
              subTitle={t('tag')}
              title={t('dispatchDetail.title')}
              detailsLabelBox={[
                {
                  label: `#${licenseeNumber}`,
                },
              ]}
              breadcrumbRoutes={[
                {
                  breadcrumb: 'Dispatch Summary',
                  path: '/admin/vam-easy-gauge/dispatch',
                },
                {
                  breadcrumb: 'Dispatch',
                  path: '#',
                },
              ]}
            />
          </Stack>
          <Stack direction="row" spacing={3} alignItems="center">
            <CircleStatus
              label={t('manageBookingDetail.changeStatusDialog.status.under_preparation')}
              color={BOOKING_STATUS_COLORS[BookingStatus.UnderPreparation]}
            />
            <SimpleButton
              type="submit"
              variant="cta"
              endIcon={<img src={ShipIcon} alt="shipment-icon" />}
              data-testid="update-reservation-details-btn"
              disabled={
                formState.isLoading ||
                !formState.isValid ||
                !!Object.keys(formState.errors)?.length ||
                !getValues('sets')?.length ||
                disableSaveDispatch
              }
              onClick={handleSubmit(onSubmit)}
            >
              {t('dispatchDetail.submit')}
            </SimpleButton>
          </Stack>
        </Stack>
        <Grid
          container
          rowSpacing={4}
          columnSpacing={{ xs: 2, sm: 3, md: 4 }}
          style={{
            backgroundColor: $lightBlue,
          }}
        >
          <Grid item xs={12}>
            <ContainerCard
              width="100%"
              maxWidth="100%"
              title={t('dispatchDetail.shippingInformations.title')}
              stepNumber={1}
              subTitle={t('dispatchDetail.shippingInformations.subtitle')}
              dataTestId="dispatch-shipping-informations"
            >
              <ShippingInformations />
            </ContainerCard>
          </Grid>
          <Grid item xs={12}>
            <ContainerCard
              width="100%"
              maxWidth="100%"
              title={t('dispatchDetail.orderInformations.title')}
              stepNumber={2}
              subTitle={t('dispatchDetail.orderInformations.subtitle')}
              dataTestId="dispatch-order-informations"
            >
              <ShippingOrderInformations bookings={currentBookings} sets={setsInformations} />
            </ContainerCard>
          </Grid>
          <Grid item xs={12}>
            <Comments
              inputTestId="dispatch-comments-textfield"
              triggerOnBlur
              title={t('dispatchDetail.comments.title')}
              subtitle={t('dispatchDetail.comments.subtitle')}
            />
          </Grid>
        </Grid>
      </FormProvider>
    </ContainerOutlet>
  );
}
