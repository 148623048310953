import { OrderPersonalInfos } from '../../components/booking/manage-booking/OrderInformations';
import { Address } from '../../interfaces/address';

export function addressToObject(address: Address) {
  return {
    email: address?.email || '-',
    address: {
      street: address?.street || '-',
      zipCode: address?.zip_code || '-',
      town: address?.town || '-',
      state: address?.country || '-',
    },
  };
}

export function orderInformationsToObject(informations: OrderPersonalInfos) {
  return {
    firstname: informations?.firstname,
    lastname: informations?.lastname,
    email: informations?.email,
    phoneNumber: informations?.phoneNumber,
    job: informations?.job,
    address: {
      street: informations?.deliveryAddress?.street,
      zipCode: informations?.deliveryAddress?.zip_code,
      town: informations?.deliveryAddress?.town,
      state: informations?.deliveryAddress?.country,
    },
  };
}
