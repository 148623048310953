export function formatWithFixedDecimals(value: number | null, decimal: number): number | null {
  if (value === null || value === undefined || isNaN(value)) {
    return null;
  }
  return parseFloat(value.toFixed(decimal));
}

export function convertStringToNumber(value: string): number {
  const parsedValue = parseFloat(value);
  // Check if the parsed value is NaN (Not-a-Number)
  if (isNaN(parsedValue)) {
    return 0;
  }

  return parsedValue; // Return the parsed number
}
