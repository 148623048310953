// istanbul ignore file

export const endpoints = {
  address_by_id: (address_id) => `/addresses/${address_id}`,
  address: '/address',
  addresses_by_licensee_and_address_type: (licensee_number, address_type) =>
    `/licensees/${licensee_number}/addresses/${address_type}`,
  addresses: '/addresses',
  booking_prices: '/bookings-prices',
  booking_status_by_id: (booking_id) => `/booking-status/${booking_id}`,
  booking_status: '/booking-status',
  booking: '/booking',
  booking_depot: '/booking-depot',
  bookings_by_id: (booking_id) => `/bookings/${booking_id}`,
  bookings_by_status: '/bookings-by-status',
  bookings_count: '/bookings-count',
  bookings_distinct: '/bookings/distinct',
  bookings_stocks_by_location: '/locations/bookings-stocks',
  bookings_stocks: '/bookings-stocks',
  bookings: '/bookings',
  cases: '/cases',
  cases_listing: '/cases-listing',
  cases_by_id: (case_id) => `/cases/${case_id}`,
  confirm_return_shipment_informations: (shipment_id) =>
    `/confirm-return-shipment-informations/${shipment_id}`,
  confirm_save_shipment: '/confirm-return-shipment-informations',
  dispatch: '/dispatch',
  distinct_product: '/distinct-products',
  distinct_set_models: '/distinct-set-models',
  distinct_sets: '/distinct-sets',
  dhl_price: '/dhl-price',
  export_calibration_certificate: '/export/calibration-certificate',
  export_set_packing_list: '/export/set-packing-list',
  export_packing_list: '/export/packing-list',
  export_dispatch_documents: '/export/dispatch-documents',
  export_invoice_generel2: '/export/generel2',
  export_and_zip_invoice_generel2: '/export/split-generel2',
  export_gauging_summary: '/export/gauging-summary',
  gauge_model: '/gauge-model',
  gauge_models: '/gauge-models',
  gauges: '/gauges',
  invoice_by_id: (invoice_id) => `/invoices/${invoice_id}`,
  invoices_count: '/invoices-count',
  invoices_listing: '/invoices-listing',
  invoices: '/invoices',
  licensees_bookings: (licensee_number) => `/licensees/${licensee_number}/bookings`,
  licensees_gauges: (licensee_number) => `/licensees/${licensee_number}/current-rentals`,
  licensees_gauges_count: (licensee_number) =>
    `/licensees/${licensee_number}/current-rentals-count`,
  pickup_address_by_id: (pickup_address_id) => `pickup-addresses/${pickup_address_id}`,
  pickup_address: '/pickup-address',
  pickup_addresses: '/pickup-addresses',
  products: '/products',
  renewal_booking: '/booking/renewal-ltr',
  replacement_booking: '/booking/replacement',
  set_creation: '/set-creation',
  set_exists_by_set_model: (set_model_id) => `/set-models/${set_model_id}/sets-exists`,
  set_model_content: '/set-model-content',
  set_model: '/set-model',
  set_model_total_weight: '/set-model-total-weight',
  set_models_by_id: (set_model_id) => `/set-models/${set_model_id}`,
  set_models: '/set-models',
  set: '/set',
  sets_by_booking_id: (booking_id) => `/bookings/${booking_id}/sets`,
  sets_by_id: (set_id) => `/sets/${set_id}`,
  sets_by_product: '/products/sets',
  sets_informations: '/sets-informations',
  sets_status: '/sets-status',
  sets: '/sets',
  shipment_return: '/return',
  shipments_by_id: (shipment_id) => `/shipments/${shipment_id}`,
  shipments_return_informations: '/shipments-returns-informations',
  shipments: '/shipments',
  tracking_by_booking_id: (booking_id) => `/shipments/${booking_id}/tracking`,
  users: '/users',
};
