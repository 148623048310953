/* istanbul ignore file */
import axios from 'axios';

import { handleAxiosError, handleAxiosRequest, handleAxiosResponse } from './functions';

// It is not recommended to use getAccessTokenSilently()
// globally or outside of the component where it is needed.
// The getAccessTokenSilently() function is designed to be used
// within a specific
// just we have to test if refresh token ll work to BE TESTED

const contextHttp = axios.create({
  baseURL: process.env.REACT_APP_CONTEXT_API,
  withCredentials: true,
});

const contextHttpWithSpinner = axios.create({
  baseURL: process.env.REACT_APP_CONTEXT_API,
  withCredentials: true,
});

// Do something before request is sent
contextHttpWithSpinner.interceptors.request.use(
  // TODO handle this
  (config) => handleAxiosRequest(config),
  (error) => handleAxiosError(error)
);

// Do something with response
contextHttpWithSpinner.interceptors.response.use(
  (response) => handleAxiosResponse(response),
  (error) => handleAxiosError(error)
);

export { contextHttp, contextHttpWithSpinner };
