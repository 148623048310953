import React from 'react';
import { useTranslation } from 'react-i18next';

import EyeIcon from '../../../assets/icons/eye-icon.svg';
import { ManageShipmentsResult, ShipmentDetailResult } from '../../../interfaces/shipping';
import { getDetailShipment } from '../../../services/api/shipping-api';
import { $primaryNavy } from '../../../styles/colors';
import { VamDataGrid } from '../../data-grid/VamDataGrid';
import { VamDialog } from '../../shared';
import { showDetailShipmentColumns } from './utils';

interface ShipmentDetailProps {
  isOpen: boolean;
  setIsOpen: Function;
  shipmentRow: ManageShipmentsResult;
}

function ShowShipmentDetail({ isOpen, setIsOpen, shipmentRow }: ShipmentDetailProps) {
  const { t } = useTranslation('shipment', { keyPrefix: 'manageShipments.detailShipmentPopin' });

  const [searchDetails, setSearchDetails] = React.useState<ShipmentDetailResult[]>([]);
  const [loadingGrid, setLoadingGrid] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoadingGrid(true);
      getDetailShipment(shipmentRow.id)
        .then(({ data }) => {
          const dataGrid = data.map((item) => ({
            ...item,
            id: item.booking_id,
            comments: item.comments || '-',
          }));
          setSearchDetails(dataGrid);
        })
        .catch((error) => {
          /* istanbul ignore next */
          console.error('🚀 ~ file: ShowShipmentDetail.tsx ~ fetch ~ error', error);
        })
        .finally(() => {
          setLoadingGrid(false);
        });
    };

    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  return (
    <VamDialog
      isOpen={isOpen}
      title={t('title')}
      subTitle={t('subtitle')}
      fullWidth
      headerIcon={<img width="48" src={EyeIcon} alt="eye-detail-icon" color={$primaryNavy} />}
      handleClose={
        /* istanbul ignore next */ () => {
          setIsOpen(false);
          setSearchDetails([]);
        }
      }
      withCloseButton
      data-testid="show-detail-shipment-dialog"
    >
      <VamDataGrid
        showRowLeftThickBorder
        disableColumnFilter
        height={400}
        disableRowSelectionOnClick
        columns={showDetailShipmentColumns}
        rows={searchDetails}
        loading={loadingGrid}
      />
    </VamDialog>
  );
}

export default ShowShipmentDetail;
