import { SectionTitle, SimpleButton } from '@digital-at-vallourec/steel-design-system-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Edit } from '@mui/icons-material';
import { DialogActions, Grid } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { UserDetails, UserStore } from '../../interfaces/user';
import { updateUserInformations } from '../../services/api/user-api';
import { selectUser, setUser } from '../../services/store/userSlice';
import { mappingUserToStore } from '../../utils/functions/user';
import { ContainerCard, FormPhoneNumber, FormTextField, VamDialog } from '../shared';
import { AvatarNameComponent } from '../shared/AvatarName/AvatarNameComponent';
import { FormSchemaType, updateUserInformationsFormSchema } from './UpdateUserInformationsForm';

interface GeneralInformationsProps {
  user: UserDetails;
}

export default function GeneralInformations({ user }: GeneralInformationsProps) {
  const { t } = useTranslation('user', { keyPrefix: 'manageSingleUser' });
  const { t: translateCommon } = useTranslation('common');
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [displayedUser, setDisplayedUser] = React.useState<UserDetails>(user);
  const dispatch = useAppDispatch();
  const connectedUser = useAppSelector(selectUser);

  const closeDialog = () => setIsOpen(false);
  const openDialog = () => setIsOpen(true);

  const defaultValues: FormSchemaType = {
    first_name: displayedUser?.firstName,
    last_name: displayedUser?.lastName,
    phone_number: displayedUser.phoneNumber,
    job: displayedUser.job,
  };

  const methods = useForm<FormSchemaType>({
    defaultValues: defaultValues,
    resolver: zodResolver(updateUserInformationsFormSchema),
    mode: 'onChange',
  });

  const { handleSubmit } = methods;

  /* istanbul ignore next */
  const onSubmit: SubmitHandler<FormSchemaType> = (data) => {
    const dataApi = {
      ...data,
      phone_number: `+${data.phone_number}`,
    };
    updateUserInformations(displayedUser.idUserMyVam, { ...dataApi, id: displayedUser.idUserMyVam })
      .then(() => {
        if (displayedUser.idUserMyVam === connectedUser.idUserMyVam) {
          dispatch(
            setUser({
              ...mappingUserToStore(connectedUser),
              ...dataApi,
            } as UserStore)
          );
        }
        setDisplayedUser((prevState) => ({ ...prevState, ...dataApi }));
        enqueueSnackbar(t('generalInformations.notification.success'), {
          preventDuplicate: true,
          variant: 'success',
        });
        closeDialog();
      })
      .catch(function (error) {
        enqueueSnackbar(t('generalInformations.notification.error'), {
          preventDuplicate: true,
          variant: 'error',
        });
        console.error(error);
      });
  };

  return (
    <ContainerCard
      width="100%"
      maxWidth="100%"
      height="100%"
      minHeight={220}
      avatarHeaderNode={
        <SectionTitle
          title={t('generalInformations.title')}
          description={t('generalInformations.subtitle')}
        />
      }
      actionHeaderNode={
        <SimpleButton
          variant="icon-secondary-dark-blue"
          size="small"
          onClick={openDialog}
          data-testid="button-edit-user"
        >
          <Edit fontSize="small" />
        </SimpleButton>
      }
      dataTestId="manage-user-general-informations"
    >
      {isOpen && (
        <FormProvider {...methods}>
          <VamDialog
            title={t('generalInformations.updateDialog.title')}
            subTitle={t('generalInformations.updateDialog.subtitle')}
            isOpen={isOpen}
            handleClose={closeDialog}
            withCloseButton
            fullWidth
            maxWidth="sm"
            data-testid="dialog-update-user"
            dialogActions={
              <DialogActions className="!tw-justify-center">
                <SimpleButton
                  data-testid="update-user-information-dialog-cancel"
                  variant="outlined"
                  size="small"
                  onClick={closeDialog}
                >
                  {translateCommon('confirmDialog.cancel')}
                </SimpleButton>
                <SimpleButton
                  size="small"
                  data-testid="update-user-information-dialog-confirm"
                  variant="cta"
                  onClick={handleSubmit(onSubmit)}
                >
                  {translateCommon('confirmDialog.update')}
                </SimpleButton>
              </DialogActions>
            }
          >
            <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 3, md: 4 }} padding={4}>
              <Grid item xs={6}>
                <FormTextField
                  label="First name"
                  name="first_name"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField label="Last name" name="last_name" variant="outlined" size="small" />
              </Grid>
              <Grid item xs={6}>
                <FormTextField label="Job" name="job" variant="outlined" size="small" />
              </Grid>
              <Grid item xs={12}>
                <FormPhoneNumber
                  name="phone_number"
                  label="Phone number"
                  inputDataTestId="phone-textfield"
                />
              </Grid>
            </Grid>
          </VamDialog>
        </FormProvider>
      )}
      <AvatarNameComponent
        userName={`${displayedUser.firstName} ${displayedUser.lastName.toUpperCase()}`}
        job={displayedUser.job}
        email={displayedUser.email}
        phoneNumber={displayedUser.phoneNumber}
        avatarSize={96}
      />
    </ContainerCard>
  );
}
