/* istanbul ignore file */
import { theme } from '@digital-at-vallourec/steel-design-system-react';
import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import App from './App';
import i18n from './assets/i18n/config';
import { Auth0ProviderControl } from './components/guards/Auth0ProviderControl';
import reportWebVitals from './reportWebVitals';
import store from './services/store/store';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    {/* <!-- add theming for vallourc design--> */}
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <Auth0ProviderControl>
          <Provider store={store}>
            <I18nextProvider i18n={i18n}>
              <App />
            </I18nextProvider>
          </Provider>
        </Auth0ProviderControl>
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
