import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { CompanyRole, CompanyType, Qualifications } from '../../interfaces/context';
import * as contextAPI from '../api/context-api';
import { RootState } from './store';

/**
 * state model
 */
export interface ContextState {
  qualifications: Qualifications;
  companyTypes: CompanyType[];
  companyRolesByType: CompanyRole[];
}

/**
 * set initial state
 */
const initialState: ContextState = {
  qualifications: {
    connections: [],
    processes: [],
    documentations: [],
  },
  companyTypes: [],
  companyRolesByType: [],
};

/**
 * selectors for this slice
 */
export const selectQualification = (state: RootState) => state.context?.qualifications;
export const selectConnectionQualification = (state: RootState) =>
  state.context?.qualifications.connections;
export const selectProcessQualification = (state: RootState) =>
  state.context?.qualifications.processes;
export const selectDocumentationQualification = (state: RootState) =>
  state.context?.qualifications.documentations;
export const selectCompanyTypes = (state: RootState) => state.context?.companyTypes;
export const selectCompanyRolesByType = (state: RootState) => state.context?.companyRolesByType;

export const fetchQualificationThunk = createAsyncThunk<
  Qualifications,
  null,
  {
    rejectValue: string;
  }
>('qualification/fetchQualification', async (_, thunkApi) => {
  try {
    const response = await contextAPI.fetchQualifications();
    // return value becomes the `fulfilled` action payload
    return response.data;
  } catch (error) {
    // return error becomes the `rejected` action payload
    // axios error?
    if (error instanceof AxiosError && error.response) {
      // return error message
      return thunkApi.rejectWithValue(`${error.response.data}`);
    }
    // return error
    throw error;
  }
});

export const fetchCompanyTypesThunk = createAsyncThunk<
  CompanyType[],
  null,
  {
    rejectValue: string;
  }
>('companyTypes/fetchCompanyType', async (_, thunkApi) => {
  try {
    const response = await contextAPI.getCompanyTypes();
    // return value becomes the `fulfilled` action payload
    return response.data;
  } catch (error) {
    // return error becomes the `rejected` action payload
    // axios error?
    if (error instanceof AxiosError && error.response) {
      // return error message
      return thunkApi.rejectWithValue(`${error.response.data}`);
    }
    // return error
    throw error;
  }
});

export const fetchCompanyRolesByTypeThunk = createAsyncThunk<
  CompanyRole[],
  string,
  {
    rejectValue: string;
  }
>('companyRolesByType/fetchCompanyRolesByType', async (type, thunkApi) => {
  try {
    const response = await contextAPI.getCompanyRolesByType(type);
    // return value becomes the `fulfilled` action payload
    return response.data;
  } catch (error) {
    // return error becomes the `rejected` action payload
    // axios error?
    if (error instanceof AxiosError && error.response) {
      // return error message
      return thunkApi.rejectWithValue(`${error.response.data}`);
    }
    // return error
    throw error;
  }
});

/**
 * slice
 */
export const contextSlice = createSlice({
  name: 'context',
  initialState,
  reducers: {
    setQualification: (state, action: PayloadAction<Qualifications>) => {
      state.qualifications = action.payload;
    },
    setCompanyType: (state, action: PayloadAction<CompanyType[]>) => {
      state.companyTypes = action.payload;
    },
    setCompanyRolesByType: (state, action: PayloadAction<CompanyRole[]>) => {
      state.companyRolesByType = action.payload;
    },
  },
  extraReducers: (builder) => {
    // -- successfull
    builder.addCase(fetchQualificationThunk.fulfilled, (state, action) => {
      // update state
      state.qualifications = action.payload;
    });
    builder.addCase(fetchCompanyTypesThunk.fulfilled, (state, action) => {
      // update state
      state.companyTypes = action.payload;
    });
    builder.addCase(fetchCompanyRolesByTypeThunk.fulfilled, (state, action) => {
      // update state
      state.companyRolesByType = action.payload;
    });
    // -- failure
    builder.addCase(fetchQualificationThunk.rejected, (state) => {
      // reset state
      state.qualifications = {
        connections: [],
        processes: [],
        documentations: [],
      };
    });
    builder.addCase(fetchCompanyTypesThunk.rejected, (state) => {
      // reset state
      state.companyTypes = [];
    });
    builder.addCase(fetchCompanyRolesByTypeThunk.rejected, (state) => {
      // reset state
      state.companyRolesByType = [];
    });
  },
});

/**
 * actions for context slice
 */
export const { setQualification, setCompanyType, setCompanyRolesByType } = contextSlice.actions;
