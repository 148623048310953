import { LabelBox } from '@digital-at-vallourec/steel-design-system-react';
import { JSXElementConstructor, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { $darkBerry, $primaryBlack, $primaryNavy, $primaryWhite } from '../../../styles/colors';
import { RentalType } from '../../../utils/constants/booking';

export function RentalTerm(value: string): ReactElement<any, string | JSXElementConstructor<any>> {
  const { t } = useTranslation('gauge');
  const background =
    value === RentalType.Short
      ? $darkBerry
      : value === RentalType.Long
      ? $primaryNavy
      : $primaryBlack;

  return (
    <LabelBox background={background} color={$primaryWhite} label={t(`deliveryTerm.${value}`)} />
  );
}
