import { SimpleButton } from '@digital-at-vallourec/steel-design-system-react';
import { Add, DeleteOutlined } from '@mui/icons-material';
import { DialogActions, Grid } from '@mui/material';
import { GridPaginationModel, GridRowSelectionModel } from '@mui/x-data-grid';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useEffect, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ApplicationIcon from '../../assets/icons/icon-gauge-application.svg';
import { useIsFirstMount } from '../../hooks';
import { DistinctConnectionLabelValue } from '../../interfaces/distinct-product-label-value';
import { Applicability } from '../../interfaces/set-model';
import { Products } from '../../interfaces/veg';
import { fetchDistinctProduct, getProducts } from '../../services/api/veg-api';
import { VegWebsiteStatus } from '../../utils';
import { DEFAULT_PAGINATION_MODEL } from '../../utils/constants/pagination';
import { mapToProductFilter, removeFalsyProperty, toOptionsFilters } from '../../utils/functions';
import { VamDataGrid } from '../data-grid/VamDataGrid';
import { GridStatus } from '../data-grid/utils';
import { ContainerCard, FormSelect, GridOverlayContainer } from '../shared';
import { DialogComponent } from './DialogComponent';
import { applicabilitiesCols } from './utils';

export interface ApplicabilitiesProps {
  applicabilities?: Applicability[];
  websiteStatus?: VegWebsiteStatus;
}

export function Applicabilities(props: ApplicabilitiesProps) {
  const { t } = useTranslation('gauge', { keyPrefix: 'setModel.applicabilities' });
  const [isOpen, setIsOpen] = React.useState(false);
  const [searchApplications, setSearchApplications] = React.useState<Products[]>([]);
  const [applyApplications, setApplyApplications] = React.useState<Products[]>([]);
  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>(DEFAULT_PAGINATION_MODEL);
  const [count, setCountSize] = React.useState(0);
  const [sort, setSort] = React.useState<GridSortModel>([]);
  const [distinctProductLabelValue, setDistinctProduct] = React.useState(
    {} as DistinctConnectionLabelValue
  );

  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const { enqueueSnackbar } = useSnackbar();

  const selectedApplications = useRef<Products[]>([]);

  const { control, setValue, getValues } = useFormContext();
  const firstMount = useIsFirstMount();
  const { replace } = useFieldArray({
    control,
    name: 'applications',
  });

  useEffect(() => {
    if (props.applicabilities?.length) {
      setApplyApplications(props.applicabilities);
    }
  }, [props.applicabilities]);

  /* istanbul ignore next */
  useEffect(() => {
    const connection = getValues('connection');
    const od_inch = getValues('od_inch');
    const end = getValues('end');
    if (isOpen) {
      if (connection?.length) {
        setValue('applicabilities_connection', connection);
      }
      if (od_inch) {
        setValue('applicabilities_od', od_inch);
      }
    }

    if (!firstMount && isOpen) {
      fetchData({
        data_filters: removeFalsyProperty({
          connection,
          od_inch,
          end,
        }),
        pagination: {
          skip: skipPage,
          limit: paginationModel.pageSize,
        },
        sort,
      });
    }
  }, [paginationModel, isOpen, sort]);

  const skipPage = paginationModel.page * paginationModel.pageSize;

  const fetchData = (params: Record<string, any>) => {
    getProducts(params)
      .then(({ data: { count, products } }) => {
        setCountSize(count);
        setSearchApplications(products);
      })
      .catch((error) => {
        console.error('🚀 ~ file: Applicabilities.tsx ~ fetch ~ getProducts ~ error', error);
      });
  };

  /* istanbul ignore next */
  const handleCloseDialog = () => {
    setPaginationModel(DEFAULT_PAGINATION_MODEL);
    setSelectionModel(
      selectionModel.filter((id) => applyApplications.find((product) => id === product.id))
    );
    setIsOpen(false);
  };

  /* istanbul ignore next */
  const handleAddSelection = () => {
    const applyApplicationsIds = applyApplications.map((product) => product.id);
    const selection = [
      ...applyApplications,
      ...searchApplications.filter(
        (product) =>
          selectionModel.includes(product.id) && !applyApplicationsIds.includes(product.id)
      ),
    ];
    setApplyApplications(selection);
    setSelectionModel(
      selectionModel.filter((id) => !selection.find((product) => product.id === id))
    );
    replace(selection);
  };

  /* istanbul ignore next */
  const handleRemoveRow = (id: number) => {
    const appls = applyApplications.filter((item) => item.id !== id);
    const selectedIds = selectionModel.filter((value) => id !== value);
    setApplyApplications(appls);
    setSelectionModel(selectedIds);
    replace(appls);
  };

  useEffect(() => {
    const fetchConnectionInfos = async () => {
      try {
        await handleChangeDistinctProduct();
      } catch (error) {
        /* istanbul ignore next */
        console.error(error);
      }
    };
    fetchConnectionInfos();
  }, [isOpen]);

  const handleChangeDistinctProduct = useCallback(async (_?: any, controlName?: string) => {
    const filter = removeFalsyProperty(
      mapToProductFilter({
        connection: getValues('applicabilities_connection'),
        od_inch: getValues('applicabilities_od'),
      })
    );
    if (controlName === 'applicabilities_connection') {
      setValue('applicabilities_od', null);
    }
    try {
      const { data } = await fetchDistinctProduct(filter);
      /* istanbul ignore next */
      const labelValueOptions = toOptionsFilters(data);
      /* istanbul ignore next */
      setDistinctProduct(labelValueOptions);
    } catch (error) {
      /* istanbul ignore next */
      console.error(error);
    }
  }, []);

  return (
    <ContainerCard
      title={t('title')}
      icon={ApplicationIcon}
      subTitle={t('subtitle')}
      width="100%"
      maxWidth="100%"
      containerCardClass="bg-color-white"
      actionHeaderNode={
        <DialogComponent
          isOpen={isOpen}
          searchLabelBtn={t('searchApplications')}
          title={t('dialogTitle')}
          subTitle={t('dialogSubTitle')}
          fullWidth
          handleCloseDialog={handleCloseDialog}
          handleOpenDialog={() => setIsOpen(true)}
          withCloseButton
          dialogActions={
            <DialogActions className="!tw-justify-center">
              <SimpleButton
                data-testid="dialog-add-selection-btn"
                variant="cta"
                size="small"
                id="add-button"
                startIcon={<Add />}
                onClick={handleAddSelection}
              >
                {t('addSelection')}
              </SimpleButton>
            </DialogActions>
          }
        >
          <Grid container spacing={4} lineHeight={2}>
            <Grid item xs={3}>
              <FormSelect
                variant="standard"
                label={t('connection')}
                name="applicabilities_connection"
                options={distinctProductLabelValue?.connection || []}
                handleChange={handleChangeDistinctProduct}
              />
            </Grid>
            <Grid item xs={3}>
              <FormSelect
                variant="standard"
                label={t('od')}
                name="applicabilities_od"
                options={distinctProductLabelValue?.od_inch || []}
              />
            </Grid>
            <Grid item xs={3} display="flex" alignItems="end">
              <div className="tw-flex tw-gap-2">
                <SimpleButton
                  data-testid="apply-set-model-applicabilities-filters-btn"
                  size="small"
                  onClick={() => {
                    fetchData({
                      data_filters: removeFalsyProperty({
                        connection: getValues('applicabilities_connection'),
                        od_inch: getValues('applicabilities_od'),
                        end: getValues('end'),
                      }),
                      pagination: {
                        skip: skipPage,
                        limit: paginationModel.pageSize,
                      },
                      sort,
                    });
                  }}
                >
                  {t('apply')}
                </SimpleButton>
                <SimpleButton
                  size="small"
                  variant="secondary-dark"
                  data-testid="reset-set-model-applicabilities-filter-btn"
                  onClick={() => {
                    setValue('applicabilities_connection', '');
                    setValue('applicabilities_od', null);
                    handleChangeDistinctProduct();
                    fetchData({
                      data_filters: removeFalsyProperty({
                        connection: getValues('applicabilities_connection'),
                        od_inch: getValues('applicabilities_od'),
                        end: getValues('end'),
                      }),
                      pagination: {
                        skip: skipPage,
                        limit: paginationModel.pageSize,
                      },
                      sort,
                    });
                  }}
                >
                  {t('reset')}
                </SimpleButton>
              </div>
            </Grid>
            <Grid item xs={12}>
              <VamDataGrid
                showRowLeftThickBorder
                disableColumnFilter
                height={400}
                checkboxSelection={true}
                disableRowSelectionOnClick
                paginationMode="server"
                pagination
                rowCount={count}
                paginationModel={paginationModel}
                onPaginationModelChange={(gridModelPagination) => {
                  if (!selectionModel.length) {
                    return setPaginationModel;
                  } else {
                    enqueueSnackbar(t('changePageError'), {
                      preventDuplicate: true,
                      variant: 'error',
                    });
                  }
                }}
                columns={applicabilitiesCols}
                rows={searchApplications}
                // function for myVam
                onRowSelectionModelChange={(selectionIndex: GridRowSelectionModel) => {
                  const selectedSet = new Set(selectionIndex);
                  const products = searchApplications.filter(({ id }) => selectedSet.has(id));
                  selectedApplications.current = products;
                  setSelectionModel(selectionIndex);
                }}
                rowSelectionModel={selectionModel}
                keepNonExistentRowsSelected
                sortingMode="server"
                sortModel={sort}
                onSortModelChange={setSort}
              />
            </Grid>
          </Grid>
        </DialogComponent>
      }
    >
      <VamDataGrid
        showRowLeftThickBorder
        rows={applyApplications.map((row) => ({ ...row, grid_status: GridStatus.OK }))}
        columns={applicabilitiesCols.concat([
          {
            field: 'action',
            align: 'center',
            flex: 0.5,
            renderHeader: () => <></>,
            renderCell: (params) => (
              <SimpleButton
                data-testid="remove-applicabilites-selected-btn"
                size="small"
                variant="remove-red"
                disabled={
                  props.websiteStatus !== VegWebsiteStatus.Draft &&
                  !!props.applicabilities?.find((applicability) => applicability.id === params.id)
                }
                onClick={() => handleRemoveRow(params.id as number)}
              >
                <DeleteOutlined fontSize="small" />
              </SimpleButton>
            ),
          },
        ])}
        disableRowSelectionOnClick
        pageSize={25}
        height={340}
        pagination
        slots={{
          noRowsOverlay: () => <GridOverlayContainer text={t('noRowsLabel')} />,
        }}
      ></VamDataGrid>
    </ContainerCard>
  );
}
