import styled from '@emotion/styled';

import { $grey20 } from '../../../styles/colors';
import { VamDataGrid } from '../VamDataGrid';

export enum GridStatus {
  OK = 'OK',
  KO = 'KO',
  NAVY = 'NAVY',
  STANDARD = 'N/A',
  EXPIRE = 'EXPIRE',
  YELLOW = 'YELLOW',
  GREY = 'GREY',
}

export const DataGridStyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const DataGridStyledGrid = styled(VamDataGrid)`
  & .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer {
    display: none;
  }
  .unselectable-row {
    color: ${$grey20};
  }
`;

export const UNSELECTED_ROW_CLASSNAME = 'unselectable-row ';
