import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LabelValue } from '../../../interfaces/label-value';
import { $grey20 } from '../../../styles/colors';
import { getError } from './utils';

type FormInputProps = {
  name: string;
  options: LabelValue[];
  label?: string;
  handleChange?: Function;
  nsTranslate?: string;
  defaultValue?: any;
  removeEmptyValue?: boolean;
  disableFullWith?: boolean;
  placeholder?: string;
} & SelectProps;

export const FormSelect: FC<FormInputProps> = ({
  name,
  label,
  options,
  variant,
  handleChange,
  nsTranslate,
  defaultValue,
  removeEmptyValue,
  disableFullWith,
  placeholder,
  ...otherProps
}) => {
  const { t } = useTranslation(nsTranslate);
  // 👇 Utilizing useFormContext to have access to the form Context
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext();
  const idLabel = name.trim() + '_label_id';

  const generateOptions = () => {
    return options.map((option, idx) => {
      return (
        <MenuItem key={idx} value={option.value} disabled={option.disabled}>
          {option?.labelKey ? t(option.labelKey) : option.label}
        </MenuItem>
      );
    });
  };

  /* istanbul ignore next */
  const onChangeSelect = (e: any) => {
    const controlValue = getValues(name);
    if (handleChange) {
      handleChange(controlValue, name);
    }
    return e;
  };

  const error = getError(errors, name);
  const topSpace = otherProps.size === 'small' ? '-10px' : '-8px';

  return (
    <FormControl variant={variant} fullWidth={!disableFullWith} error={!!error}>
      {!placeholder ? (
        <InputLabel
          data-testid={`input-label-${name}-test-id`}
          sx={{ top: variant === 'outlined' ? topSpace : 0 }}
          id={idLabel}
        >
          {label}
        </InputLabel>
      ) : null}
      <Controller
        render={({ field: { onChange, value } }) => {
          return (
            <Select
              onChange={(e) => onChangeSelect(onChange(e))}
              value={value !== '' && !isNaN(value) && value % 1 === 0 ? Math.floor(value) : value}
              defaultValue={defaultValue || ''}
              displayEmpty={!!placeholder}
              label={label}
              labelId={idLabel}
              notched={variant !== 'outlined'}
              id={`${name.trim()}_id`}
              inputProps={{
                'data-testid': `form-select-${name}-data-testid`,
              }}
              {...otherProps}
            >
              {placeholder && (
                <MenuItem value="" sx={{ color: $grey20 }}>
                  {placeholder}
                </MenuItem>
              )}
              {!removeEmptyValue && !placeholder && (
                <MenuItem key="default_value" value=""></MenuItem>
              )}
              {options && generateOptions()}
            </Select>
          );
        }}
        control={control}
        name={name}
      />
      <FormHelperText className="color-error-red">{error ? error.message : ''}</FormHelperText>
    </FormControl>
  );
};
