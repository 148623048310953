import { LabelValue } from '../../../interfaces/label-value';

export const isoCountriesLabelValue = [
  { label: 'Algeria', value: 'DZ' },
  { label: 'Angola', value: 'AO' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Bosnia And Herzegovina', value: 'BA' },
  { label: 'Brazil', value: 'BR' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Canada', value: 'CA' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'China', value: 'CN' },
  { label: 'Columbia', value: 'CO' },
  { label: 'Congo', value: 'CG' },
  { label: "Côte d'Ivoire", value: 'CI' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Czechia', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Greece', value: 'GR' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Iran, Islamic Republic of', value: 'IR' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Italy', value: 'IT' },
  { label: 'Japan', value: 'JP' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Libya', value: 'LY' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Romania', value: 'RO' },
  { label: 'Russian Federation', value: 'RU' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Singapore', value: 'SG' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Syrian Arab Republic', value: 'SY' },
  { label: 'Taiwan, Province of China', value: 'TW' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Trinidad and Tobago', value: 'TT' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Türkiye', value: 'TR' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'United States', value: 'US' },
  { label: 'Venezuela, Bolivarian Republic of', value: 'VE' },
  { label: 'Viet Nam', value: 'VN' },
];

export const isoCountries = {
  DZ: 'Algeria',
  AO: 'Angola',
  AR: 'Argentina',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BH: 'Bahrain',
  BO: 'Bolivia',
  BA: 'Bosnia And Herzegovina',
  BR: 'Brazil',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  CM: 'Cameroon',
  CA: 'Canada',
  CF: 'Central African Republic',
  CN: 'China',
  CO: 'Columbia',
  CG: 'Congo',
  CI: "Côte d'Ivoire",
  HR: 'Croatia',
  CZ: 'Czechia',
  DK: 'Denmark',
  EC: 'Ecuador',
  EG: 'Egypt',
  GQ: 'Equatorial Guinea',
  EE: 'Estonia',
  FI: 'Finland',
  FR: 'France',
  GA: 'Gabon',
  DE: 'Germany',
  GH: 'Ghana',
  GR: 'Greece',
  GY: 'Guyana',
  HT: 'Haiti',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IN: 'India',
  ID: 'Indonesia',
  IQ: 'Iraq',
  IR: 'Iran, Islamic Republic of',
  IE: 'Ireland',
  IT: 'Italy',
  JP: 'Japan',
  KZ: 'Kazakhstan',
  KW: 'Kuwait',
  LY: 'Libya',
  MY: 'Malaysia',
  MR: 'Mauritania',
  MU: 'Mauritius',
  MX: 'Mexico',
  MA: 'Morocco',
  MM: 'Myanmar',
  NL: 'Netherlands',
  NZ: 'New Zealand',
  NG: 'Nigeria',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PL: 'Poland',
  PT: 'Portugal',
  QA: 'Qatar',
  RO: 'Romania',
  RU: 'Russian Federation',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  SG: 'Singapore',
  ZA: 'South Africa',
  ES: 'Spain',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan, Province of China',
  TH: 'Thailand',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Türkiye',
  TM: 'Turkmenistan',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'United States',
  VE: 'Venezuela, Bolivarian Republic of',
  VN: 'Viet Nam',
};

export const zones: LabelValue[] = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
];
