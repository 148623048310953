import React from 'react';

import { LabelValue } from '../interfaces/label-value';
import { UserMyVamRole } from '../interfaces/user';
import { fetchAuth0RolesThunk, selectAuth0Roles } from '../services/store/userSlice';
import { arrayToLabelValuesByRef } from '../utils/functions';
import { useAppDispatch, useAppSelector } from './redux';

export function useSelectOrFetchAuth0Roles() {
  const [auth0RolesLabelValue, setAuth0RolesLabelValue] = React.useState([] as LabelValue[]);
  const auth0Roles: UserMyVamRole[] = useAppSelector(selectAuth0Roles);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const fetchData = async () => {
      const roles: UserMyVamRole[] = auth0Roles?.length
        ? auth0Roles
        : await dispatch(fetchAuth0RolesThunk()).unwrap();

      const rolesLabelValue = arrayToLabelValuesByRef(roles, 'name', 'name');
      setAuth0RolesLabelValue(rolesLabelValue);
    };

    // fetch auth0 roles
    fetchData();
  }, []);

  return { auth0RolesLabelValue, auth0Roles };
}
