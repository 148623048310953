import { SimpleButton } from '@digital-at-vallourec/steel-design-system-react';
import { Checkbox, DialogActions } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { isBefore } from 'date-fns';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useIsFirstMount } from '../../../hooks';
import { useSelectOrFetchPuAddress } from '../../../hooks/useSelectOrFetchPuAddress';
import { ReservationDetailSetsGrid } from '../../../interfaces/veg';
import { getMatchedSets } from '../../../services/api/veg-api';
import { selectUser } from '../../../services/store/userSlice';
import { $orange } from '../../../styles/colors';
import { formatDate, getDateFromString, getODTxt, getTownById } from '../../../utils/functions';
import { DataGridStyledGrid, GridStatus, UNSELECTED_ROW_CLASSNAME } from '../../data-grid/utils';
import {
  columnRenderHeader,
  handleUniqueSelectionModelChange,
} from '../../data-grid/utils/functions';
import { GridOverlayContainer, VamDialog } from '../../shared';
import { checkSearchSetsParams, mappingStockAvailable } from '../utils';

interface ShowChangeSetProps {
  isOpen: boolean;
  setIsOpen: Function;
  handleChangeSet: Function;
  preSelectedSet: number;
}

export const setsCols: GridColDef[] = [
  {
    field: 'selected',
    headerName: '',
    renderCell: ({ value }) => <Checkbox checked={value} />,
    disableColumnMenu: true,
    sortable: false,
    flex: 0.5,
  },
  {
    field: 'set_id',
    headerName: 'SET #',
    flex: 1,
  },
  {
    field: 'connection',
    headerName: 'CONNECTION',
    flex: 1,
  },
  {
    field: 'od_inch',
    headerName: 'OD',
    flex: 1,
    renderCell: /* istanbul ignore next */ (params: GridRenderCellParams<any, number>) =>
      getODTxt(params.value),
  },
  {
    field: 'min_weight_max_weight',
    headerName: 'Weight',
    flex: 1.5,
  },
  { field: 'end', headerName: 'END', flex: 1.5 },
  {
    field: 'application',
    headerName: 'APPLICATION',
    flex: 1.5,
  },
  {
    field: 'option',
    headerName: 'OPTION',
    flex: 1.5,
  },
  {
    field: 'next_set_calibration_date',
    renderHeader: () => columnRenderHeader('NEXT', 'CALIBRATION DATE'),
    flex: 2,
  },
  {
    field: 'location',
    headerName: 'LOCATION',
    flex: 1.5,
  },
];

function ShowChangeSet({ isOpen, setIsOpen, handleChangeSet, preSelectedSet }: ShowChangeSetProps) {
  const { t } = useTranslation('gauge', { keyPrefix: 'manageBookingDetail.shipment' });
  const user = useAppSelector(selectUser);
  const { getValues, formState } = useFormContext();
  const { pickupAddress } = useSelectOrFetchPuAddress();

  const [searchMatchedSets, setSearchMatchedSets] = React.useState<ReservationDetailSetsGrid[]>([]);
  const [hasRequiredFields, setHasRequiredFields] = React.useState(false);
  const [sort, setSort] = React.useState<GridSortModel>([]);
  const [loadingGrid, setLoadingGrid] = React.useState(false);
  const { defaultValues } = formState;

  const firstMount = useIsFirstMount();

  React.useEffect(() => {
    const canFetchMatchedSets = checkSearchSetsParams(getValues());
    setHasRequiredFields(canFetchMatchedSets);
  }, [defaultValues]);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoadingGrid(true);
      const params = mappingStockAvailable(getValues());
      try {
        const response = await getMatchedSets(params);
        const dataGrid = response?.data?.map((row, idx) => ({
          ...row,
          set_id: row.id,
          id: idx,
          selected: row.id === preSelectedSet ? true : false,
          next_set_calibration_date: row.next_set_calibration_date
            ? formatDate(row.next_set_calibration_date, false)
            : null,
          location: getTownById(pickupAddress, row.location_id),
          grid_status: isBefore(
            getDateFromString(row.next_set_calibration_date),
            getDateFromString(getValues().end_date)
          )
            ? GridStatus.EXPIRE
            : GridStatus.STANDARD,
        }));
        setSearchMatchedSets(dataGrid);
      } catch (error) {
        console.error('🚀 ~ file: ShowChangeSet.tsx ~ fetch ~ error', error);
      } finally {
        setLoadingGrid(false);
      }
    };

    if ((isOpen || firstMount) && hasRequiredFields) {
      fetchData();
    }
  }, [isOpen, firstMount, hasRequiredFields]);

  /* istanbul ignore next */
  const handleCloseDialog = () => {
    setSearchMatchedSets([]);
    setIsOpen(false);
  };

  /* istanbul ignore next */
  const handleConfirmChangeSet = () => {
    const selectedRow = searchMatchedSets.filter((el) => el.selected)[0];
    selectedRow && handleChangeSet(selectedRow);
  };

  return (
    <VamDialog
      isOpen={isOpen}
      title={t('changeSetTitle')}
      subTitle={t('changeSetSubTitle')}
      fullWidth
      handleClose={
        /* istanbul ignore next */ () => {
          handleCloseDialog();
        }
      }
      withCloseButton
      dialogActions={
        <DialogActions className="!tw-justify-center">
          <SimpleButton
            data-testid="confirm-change-set-button"
            variant="cta"
            size="small"
            id="confirm-change-set-button"
            onClick={
              /* istanbul ignore next */ () => {
                handleConfirmChangeSet();
                handleCloseDialog();
              }
            }
          >
            {t('changeSetButton')}
          </SimpleButton>
        </DialogActions>
      }
    >
      <DataGridStyledGrid
        showRowLeftThickBorder
        disableColumnFilter
        height={500}
        columns={setsCols.map((column) => ({
          ...column,
          cellClassName: (params) => {
            let className = '';
            if (
              params.field === 'next_set_calibration_date' &&
              params.row.grid_status === GridStatus.EXPIRE
            ) {
              className += 'status-expire ';
            }
            if (params.row.location_id !== user.depotId) {
              className += UNSELECTED_ROW_CLASSNAME;
            }
            return className;
          },
        }))}
        rows={searchMatchedSets}
        loading={loadingGrid}
        onRowSelectionModelChange={(selectionIndex: GridRowSelectionModel) =>
          handleUniqueSelectionModelChange(searchMatchedSets, selectionIndex)
        }
        slots={{
          noRowsOverlay: () => (
            <GridOverlayContainer
              color={!hasRequiredFields && $orange}
              text={hasRequiredFields ? t('emptyResults') : t('fieldsSetRequired')}
            />
          ),
        }}
        sortModel={sort}
        onSortModelChange={setSort}
        isRowSelectable={(params) => params.row.location_id === user.depotId}
      />
    </VamDialog>
  );
}

export default ShowChangeSet;
