import { Currency } from '../constants';

const MAP_ES_CURRENCY = {
  [Currency.DOLLAR]: 'USD',
  [Currency.EURO]: 'EUR',
};

export function computeTotalPrice(
  withBident: boolean,
  totalPrice: number,
  bidentPrice: number
): number {
  return withBident ? totalPrice + bidentPrice : totalPrice - bidentPrice;
}

export function formatPrice(price: number, currency: Currency): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: MAP_ES_CURRENCY[currency],
  }).format(price);
}
