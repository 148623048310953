import { AxiosResponse } from 'axios';

import { VegCase, VegCaseResult } from '../../interfaces/case';
import { CaseStatus } from '../../utils/constants/case';
import { endpoints } from '../../utils/constants/endpoints/veg-endpoints';
import { vegHttp, vegHttpWithSpinner } from '../axios-services/http-veg';

/**
 * Get case by case_id
 * @param case_id number
 * @returns Promise Case
 */
export function getCase(case_id: string): Promise<AxiosResponse<VegCase, any>> {
  return vegHttp.get<VegCase>(endpoints.cases_by_id(case_id));
}

/**
 * Get all cases by status
 * @param params CaseStatus[]
 */
export function getCasesListing(params: CaseStatus[]): Promise<AxiosResponse<VegCaseResult, any>> {
  return vegHttpWithSpinner.post<VegCaseResult>(endpoints.cases_listing, params);
}

/**
 * Create a New Case
 * @param params VegCase
 * @returns Promise number
 */
export function createNewCase(data: VegCase): Promise<AxiosResponse<number, any>> {
  return vegHttpWithSpinner.post<number>(endpoints.cases, data);
}

/**
 * Update case by case_id
 * @param case_id number
 * @param data VegCase
 * @returns Promise Case
 */
export function updateCase(case_id: string, data: VegCase): Promise<AxiosResponse<number, any>> {
  return vegHttpWithSpinner.put<number>(endpoints.cases_by_id(case_id), data);
}
