import { Invoice } from '../../../interfaces/invoice';
import { ChangeStatusApi } from '../../../interfaces/veg';
import { InvoiceType } from '../../../utils/constants/invoice';

export function mapGpsInvoice(data: ChangeStatusApi, firstName: string, lastName: string): Invoice {
  return {
    booking_id: data.id,
    invoice_type: InvoiceType.GPS,
    created_by: `${firstName} ${lastName}`,
  };
}
