import { SectionTitle, SimpleButton, Typo } from '@digital-at-vallourec/steel-design-system-react';
import { GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import ShipIcon from '../../../assets/icons/prepare-shipment.svg';
import { useAppSelector } from '../../../hooks';
import { useFetchGaugesBookingByStatus } from '../../../hooks/useFetchGaugesBookingByStatus';
import { ManageBookingGrid } from '../../../interfaces/veg';
import { selectLicenseeNumber } from '../../../services/store/userSlice';
import { $grey20 } from '../../../styles/colors';
import {
  BOOKING_STATUS_COLORS,
  BookingStatus,
  DISPATCH_SUMMARY_SORT,
  DeliveryMode,
} from '../../../utils';
import {
  groupBy,
  sortByMultipleKeys,
  strToNumber,
  stringDateComparator,
} from '../../../utils/functions';
import { redirectTo } from '../../../utils/functions/helpers';
import {
  DataGridStyledContainer,
  DataGridStyledGrid,
  UNSELECTED_ROW_CLASSNAME,
} from '../../data-grid/utils';
import { ContainerOutlet } from '../../layout/ContainerOutlet/ContainerOutlet';
import { ContainerCard, VamMoreOptions } from '../../shared';
import { ManageBookingCommon, QuickAccessButtonProps } from '../manage-booking/ManageBookingCommon';
import { dispatchSummaryColumns, getLicenseeNumberByGridRowId } from '../utils';

export function DispatchSummary() {
  const { t } = useTranslation('gauge');
  const licenseeNumber = useAppSelector(selectLicenseeNumber);
  const { datagrid: bookings } = useFetchGaugesBookingByStatus(
    { all_statuses: [], current_status: BookingStatus.UnderPreparation },
    DISPATCH_SUMMARY_SORT,
    licenseeNumber,
    0,
    DeliveryMode.Delivery
  );

  const location = useLocation();
  const navigate = useNavigate();

  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const [currentSelectedBookings, setCurrentSelectedBookings] = React.useState<ManageBookingGrid[]>(
    []
  );
  const [currentSelectedLicensee, setCurrentSelectedLicensee] = React.useState<number>(null);
  const [currentSelectedAddress, setCurrentSelectedAddress] = React.useState<string>(null);

  const groupByLicenseeNumber = groupBy(bookings, 'licensee_number');
  const licenseeNumbers = Object.keys(groupByLicenseeNumber);
  // FIXME as unknown as ManageBookingGrid[] ? break typescript typings.
  const licenseeBookings = Object.values(groupByLicenseeNumber) as unknown as ManageBookingGrid[];

  async function redirectToDispatchDetail() {
    redirectTo(`./dispatch-detail`, navigate, {
      state: { from: location, currentSelectedBookings },
    });
  }

  /* istanbul ignore next */
  const accessButtons: QuickAccessButtonProps[] = [
    {
      title: t('manage_booking.quickAccessButtons.search.title'),
      subtitle: t('manage_booking.quickAccessButtons.search.subtitle'),
      dataTestId: 'vam-global-search-quick-access-button',
      handleClick: () => redirectTo('../rental-data', navigate, { state: { from: location } }),
    },
    {
      title: t('manage_booking.quickAccessButtons.booking.title', { context: 'manageBooking' }),
      subtitle: t('manage_booking.quickAccessButtons.booking.subtitle', {
        context: 'manageBooking',
      }),
      dataTestId: 'vam-manageBooking-quick-access-button',
      handleClick: () => redirectTo('../current-rentals', navigate, { state: { from: location } }),
    },
    {
      title: t('manage_booking.quickAccessButtons.booking.title', { context: 'currentRentals' }),
      subtitle: t('manage_booking.quickAccessButtons.booking.subtitle', {
        context: 'currentRentals',
      }),
      dataTestId: 'vam-currentRentals-quick-access-button',
      handleClick: () =>
        redirectTo('../reservation-management', navigate, { state: { from: location } }),
    },
  ];

  return (
    <ContainerOutlet>
      <ManageBookingCommon
        title={t('manage_booking.title', { context: 'dispatch' })}
        subtitle={t('tag')}
        breadcrumbRoutes={[
          {
            breadcrumb: t('manage_booking.title', { context: 'dispatch' }),
            path: '#',
          },
        ]}
        accessButtons={accessButtons}
      />
      <DataGridStyledContainer>
        {licenseeNumbers.map((licenseeNumber, idx) => (
          <ContainerCard
            key={licenseeNumber}
            width="100%"
            maxWidth="100%"
            containerCardClass="tw-pt-3"
            dataTestId={`${licenseeNumber}-dispatch-container-card`}
            avatarHeaderNode={
              <SectionTitle
                title={`#${licenseeNumber} - ${licenseeBookings[idx][0].licensee_name}`}
              />
            }
            actionHeaderNode={
              <SimpleButton
                variant="cta"
                size="small"
                endIcon={<img src={ShipIcon} alt="shipment-icon" />}
                disabled={strToNumber(licenseeNumber) !== currentSelectedLicensee}
                onClick={redirectToDispatchDetail}
                data-testid={`dispatch-summary-${licenseeNumber}-prepare-btn`}
              >
                {t('manage_booking.prepareShipmentButton')}
              </SimpleButton>
            }
          >
            <DataGridStyledGrid
              initialStateColumns={{
                columnVisibilityModel: {
                  start_date: true,
                },
              }}
              columns={dispatchSummaryColumns
                .map((column) => ({
                  ...column,
                  cellClassName: /* istanbul ignore next */ (params) =>
                    currentSelectedAddress && params.row.address_name !== currentSelectedAddress
                      ? UNSELECTED_ROW_CLASSNAME
                      : '',
                }))
                .concat([
                  {
                    field: 'status',
                    headerName: 'STATUS',
                    cellClassName: /* istanbul ignore next */ () => '',
                    renderCell: /* istanbul ignore next */ (
                      params: GridRenderCellParams<any, BookingStatus>
                    ) => (
                      <Typo
                        variant="body2"
                        color={
                          currentSelectedAddress &&
                          currentSelectedAddress !== params.row.address_name
                            ? $grey20
                            : BOOKING_STATUS_COLORS[params.value]
                        }
                        fontWeight={500}
                        className="text-ellipsis"
                      >
                        {t(
                          `manageBookingDetail.changeStatusDialog.status.${params.value}`
                        ).toUpperCase()}
                      </Typo>
                    ),
                    minWidth: 120,
                    flex: 1.5,
                    sortable: false,
                    disableColumnMenu: true,
                  },
                  {
                    field: 'action',
                    align: 'right',
                    flex: 0.5,
                    cellClassName: /* istanbul ignore next */ () => '',
                    renderHeader: /* istanbul ignore next */ () => <></>,
                    renderCell: /* istanbul ignore next */ ({ row }) => {
                      return (
                        <VamMoreOptions
                          disabled={
                            currentSelectedAddress && currentSelectedAddress !== row.address_name
                          }
                          size="small"
                          options={[]}
                          row={row}
                        />
                      );
                    },
                    sortable: false,
                    hideable: false,
                    disableColumnMenu: true,
                  },
                ])}
              rows={sortByMultipleKeys(licenseeBookings[idx] as any, [
                { key: 'address_name', comparator: (a, b) => a.localeCompare(b) },
                { key: 'creation_date', comparator: stringDateComparator },
              ])}
              disableColumnFilter
              showRowLeftThickBorder
              checkboxSelection
              onRowSelectionModelChange={
                /* istanbul ignore next */ (rowSelectionModel: GridRowSelectionModel) => {
                  setCurrentSelectedLicensee(
                    getLicenseeNumberByGridRowId(bookings, rowSelectionModel[0] as number)
                  );
                  setCurrentSelectedAddress(bookings[rowSelectionModel[0] as number]?.address_name);
                  setCurrentSelectedBookings(
                    bookings.filter((booking) => rowSelectionModel.includes(booking.id))
                  );
                  setSelectionModel(rowSelectionModel);
                }
              }
              rowSelectionModel={selectionModel}
              keepNonExistentRowsSelected
              hideFooter
              autoHeight
              isRowSelectable={(params) =>
                !currentSelectedAddress || params.row.address_name === currentSelectedAddress
              }
            />
          </ContainerCard>
        ))}
      </DataGridStyledContainer>
    </ContainerOutlet>
  );
}
