import { useTranslation } from 'react-i18next';

import { HelpAndSupport } from '../HelpAndSupport/HelpAndSupport';
import styles from './Footer.module.scss';

export const Footer = () => {
  const { t } = useTranslation('common', { keyPrefix: 'footer' });

  const countries: string[][] = [
    [t('countries.canada'), t('countries.usa'), t('countries.mexico'), t('countries.brazil')],
    [t('countries.uk'), t('countries.uae'), t('countries.nigeria'), t('countries.angola')],
    [
      t('countries.china'),
      t('countries.azerbaijan'),
      t('countries.singapore'),
      t('countries.australia'),
    ],
  ];

  return (
    <div data-testid="testid-footer">
      <HelpAndSupport />
      <div className={`${styles.footer} bg-color-light-blue tw-flex tw-content-between`}>
        <div className="w40 tw-pl-16">
          <span className="w70 tw-text-3xl">
            {t('text_part1')} <br />
            {t('text_part2')} <br />
            {t('text_part3')}
          </span>
        </div>
        <div className="tw-flex tw-flex-col">
          <div className="color-grey-60 tw-font-bold tw-ml-10">
            <span>{t('assistance')}</span>
          </div>
          <div className="tw-flex tw-leading-8">
            {countries.map((items, idx) => {
              return (
                <ul key={idx.toString()} className={styles.footerCountries}>
                  {items.map((country) => (
                    <li key={country.toString()}>
                      <a href="#1">{country}</a>
                    </li>
                  ))}
                </ul>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
