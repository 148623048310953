// istanbul ignore file

export const contextEndpoints = {
  companies: '/companies',
  companies_roles_by_type: (companyType: string) => `/companies-roles/${companyType}`,
  companies_type: '/companies-types',
  licensee_status: '/licensee-status',
  licensees_by_type: '/licensees-by-type/',
  licensees: '/licensees',
  licensees_documentation_qualifications: (licenseeNumber: number) =>
    `/licensees/${licenseeNumber}/documentations-qualification`,
  licensees_process_qualifications: (licenseeNumber: number) =>
    `/licensees/${licenseeNumber}/processes-qualification`,
  licensees_product_qualifications: (licenseeNumber: number) =>
    `/licensees/${licenseeNumber}/products-qualification`,
  licensee_by_number: (licenseeNumber: number) => `/licensees/${licenseeNumber}`,
  licensees_stamp: '/licensees/stamp',
  licensees_check_unique_ids: '/licensees/check-unique',
  qualifications: '/qualifications',
  licensees_search_by_name: (query) => `/licensees/name/${query}`,
  companies_roles: 'companies-roles',
};
