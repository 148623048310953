import { Typo } from '@digital-at-vallourec/steel-design-system-react';
import { AccountCircle, MailOutline, PhoneOutlined } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import React from 'react';

import { useAppSelector } from '../../../hooks';
import { selectUser } from '../../../services/store/userSlice';
import { $grey50, $primaryBrand } from '../../../styles/colors';

// TODO: Add picture src if we need it then
interface AvatarNameComponentProps {
  userName: string;
  job: string;
  phoneNumber?: string;
  email?: string;
  avatarSize?: number;
}

export function AvatarNameComponent({
  userName,
  job,
  email,
  phoneNumber,
  avatarSize,
}: AvatarNameComponentProps) {
  const connectedUser = useAppSelector(selectUser);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '30px',
        alignItems: 'center',
      }}
      data-testid="avatar-main-container"
    >
      <Grid container rowSpacing={3} sx={{ backgroundColor: 'transparent' }}>
        <Grid item xs={4} height="100%">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <AccountCircle
              sx={{ fontSize: avatarSize ? `${avatarSize}px` : '38px' }}
              data-testid="avatar-icon"
              htmlColor={$primaryBrand}
            />
            <Box
              sx={{
                display: 'flex',
                height: avatarSize / 2 || '100%',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '30%',
              }}
              data-testid="avatar-name-container"
            >
              <Typo fontWeight={300} lineHeight={1} variant="body2">
                {userName}
              </Typo>
              <Typo variant="overline" fontWeight={500} lineHeight={1} color={$primaryBrand}>
                {job}
              </Typo>
            </Box>
          </Box>
        </Grid>
        {(email || phoneNumber) && (
          <Grid item xs={3} height="100%">
            <Box
              sx={{
                display: 'flex',
                height: avatarSize || '100%',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '20%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  alignItems: 'center',
                }}
              >
                <MailOutline sx={{ fontSize: '12px', color: $grey50 }} />
                <Typo fontWeight={300} variant="body2" lineHeight={0}>
                  {email}
                </Typo>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  alignItems: 'center',
                }}
              >
                <PhoneOutlined sx={{ fontSize: '12px', color: $grey50 }} />
                <Typo variant="body2" fontWeight={300} lineHeight={0}>
                  {connectedUser?.phoneNumber || '-'}
                </Typo>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
