import { TypeOf, date, number, object, string } from 'zod';

export const formSchema = object({
  connection: string().optional().array(),
  od_inch: number().optional().array(),
  end: string().optional().array(),
  weight_thickness: string().optional().array(),
  application: string().optional().array(),
  option: string().optional().array(),
  status: string().optional().array(),
  licensee_number: number().optional().array(),
  gauge_location_id: number().optional().array(),
  period_start: date().optional().nullable(),
  period_end: date().optional().nullable(),
  business_number: string().optional(),
});

export const defaultValues: FormSchemaType = {
  connection: [],
  od_inch: [],
  end: [],
  weight_thickness: [],
  application: [],
  option: [],
  status: [],
  licensee_number: [],
  gauge_location_id: [],
  period_start: null,
  period_end: null,
  business_number: '',
};

// infer schema to generate typescript type
export type FormSchemaType = TypeOf<typeof formSchema>;
