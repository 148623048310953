import { SimpleButton } from '@digital-at-vallourec/steel-design-system-react';
import styled from '@emotion/styled';
import { Clear, Forward } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { GridFilterInputValueProps } from '@mui/x-data-grid';
import React from 'react';

interface FilterInputProps extends GridFilterInputValueProps {
  handleSubmit?: Function;
  value?: string;
}

const FilterInputContainer = styled.div`
  align-items: end;
  display: flex;
  gap: 10px;
  height: 100%;
`;

const StyledClearIcon = styled(Clear)`
  cursor: pointer;
`;

export const FilterInput = (props: FilterInputProps) => {
  const [value, setValue] = React.useState(props.value || '');

  return (
    <FilterInputContainer data-testid="filter-input">
      <TextField
        label="Value"
        variant="standard"
        data-testid="filter-input-textfield"
        inputProps={{ 'data-testid': 'filter-input-textfield-input' }}
        InputProps={{
          endAdornment: value.length ? (
            <InputAdornment position="end">
              <StyledClearIcon data-testid="filter-input-clear-icon" onClick={() => setValue('')} />
            </InputAdornment>
          ) : null,
        }}
        size="small"
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
      />
      <SimpleButton
        size="small"
        onClick={() => props.handleSubmit(props.item.field, value)}
        variant="icon-secondary-dark-blue"
        data-testid="filter-input-submit-btn"
      >
        <Forward />
      </SimpleButton>
    </FilterInputContainer>
  );
};
