import { AxiosResponse } from 'axios';

import {
  AxiosSetDetailsManagement,
  SaveSetApi,
  SearchGaugeParams,
  SetConnectionManagementFilter,
  SetCreationResult,
  SetDetailsManagement,
  SetGauge,
  SetsCreationManagementParams,
  SetsManagementResult,
  UpdateSetApi,
} from '../../interfaces/set-creation';
import { DistinctConnectionApi, SetModelConnectionFilter } from '../../interfaces/set-model';
import { SetBookingInformations } from '../../interfaces/veg';
import { VegWebsiteStatus } from '../../utils/constants/booking';
import { endpoints } from '../../utils/constants/endpoints/veg-endpoints';
import { vegHttp, vegHttpWithSpinner } from '../axios-services/http-veg';

/**
 * get set creation models
 * @param data SetModelConnectionFilter
 * @return Promise SetCreationResult
 */
export function getSetCreation(
  params: SetModelConnectionFilter
): Promise<AxiosResponse<SetCreationResult, any>> {
  // fetch only active set model
  const setModelParams = { status: VegWebsiteStatus.Active, ...params };
  return vegHttpWithSpinner.get<SetCreationResult>(endpoints.set, {
    params: setModelParams,
  });
}

/**
 * check if gauge serial number exists
 * @param params SearchGaugeParams
 * @returns Promise SetGauge
 */
export function checkSerialNumberExists(
  params: SearchGaugeParams
): Promise<AxiosResponse<SetGauge, any>> {
  return vegHttp.get<SetGauge>(endpoints.gauges, { params });
}

/**
 * post save new set
 * @param data SaveSetApi
 * @returns Promise id number
 */
export function saveSetCreation(data: SaveSetApi): Promise<AxiosResponse<number, any>> {
  return vegHttpWithSpinner.post<number>(endpoints.set, data);
}

/**
 * get all sets creation.
 * @param params Pagination
 * @return Promise SetsManagementResult
 */
export function getSetsCreationManagement(
  dataParams: SetsCreationManagementParams
): Promise<AxiosResponse<SetsManagementResult, any>> {
  return vegHttp.post<SetsManagementResult>(endpoints.sets, dataParams);
}

/**
 * fetch connection by apply filter if exist
 * @param params?: DistinctSetFilterApi all fields are Arrays
 * @returns DistinctConnectionApi
 */
export function getDistinctSetFilter(
  data: SetConnectionManagementFilter
): Promise<AxiosResponse<DistinctConnectionApi, any>> {
  return vegHttpWithSpinner.post<DistinctConnectionApi>(endpoints.distinct_sets, data);
}

/**
 * Get Detail of set by set_id
 * @param set_id string
 * @returns Promise SetDetails
 */
export function getSetDetails(set_id: string): Promise<AxiosSetDetailsManagement> {
  return vegHttpWithSpinner.get<SetDetailsManagement>(endpoints.sets_by_id(set_id));
}

/**
 * Update set
 * @param setId string
 * @param data UpdateSetApi
 * @returns Promise id number
 */
export function updateSet(setId: string, data: UpdateSetApi): Promise<AxiosResponse<number, any>> {
  return vegHttpWithSpinner.put<number>(endpoints.sets_by_id(setId), data);
}

/**
 * Get sets informations
 * @param data number[]
 * @returns Promise SetBookingInformations[]
 */
export function getSetsInformations(
  setIds: number[]
): Promise<AxiosResponse<SetBookingInformations[], any>> {
  return vegHttpWithSpinner.post<SetBookingInformations[]>(endpoints.sets_informations, setIds);
}
