import { Box, Button, ButtonProps, CircularProgress } from '@mui/material';
import React from 'react';

export interface CtaLoadingButtonProps extends ButtonProps {
  loading?: boolean;
  buttonText: string;
}

export const CtaLoadingButton = ({ loading, buttonText, ...rest }: CtaLoadingButtonProps) => (
  <Box sx={{ m: 1, position: 'relative' }}>
    <Button {...rest}>{buttonText}</Button>
    {loading && (
      <CircularProgress
        data-testid="circular-progress"
        size={24}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-12px',
          marginLeft: '-12px',
        }}
      />
    )}
  </Box>
);
