/* istanbul ignore file */
// SonarQube ignore file
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import { SHOW_SPINNER } from '../../utils/constants/custom-event';

const showSpinnerEvent = new CustomEvent(SHOW_SPINNER, { detail: true });
const hideSpinnerEvent = new CustomEvent(SHOW_SPINNER, { detail: false });

export function handleAxiosRequest(
  config: InternalAxiosRequestConfig<any>
): InternalAxiosRequestConfig<any> {
  window.dispatchEvent(showSpinnerEvent);

  return config;
}

export function handleAxiosResponse(response: AxiosResponse<any, any>): AxiosResponse<any, any> {
  window.dispatchEvent(hideSpinnerEvent);
  return response;
}

export function handleAxiosError(error: any): Promise<any> {
  window.dispatchEvent(hideSpinnerEvent);
  return Promise.reject(error);
}
