import styles from './VamTitleDialog.module.scss';

export function VamTitleDialog(props: { title: string; subTitle?: string }) {
  return (
    <div className={styles.vamDialog}>
      <div className={styles.vamDialogTitle}>{props.title}</div>
      <div className={styles.vamDialogSubTitle}>{props.subTitle}</div>
    </div>
  );
}
