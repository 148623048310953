import styled from '@emotion/styled';
import { Box, BoxProps } from '@mui/material';

import { $contentCardShadow, $primaryWhite } from '../../../styles/colors';

export function BoxContainer({ children, ...otherProps }: BoxProps) {
  const StyledGrid = styled(Box)`
    background-color: ${$primaryWhite};
    box-shadow: 0px 2px 4px ${$contentCardShadow};
    border-radius: 0 0 6px 6px;
    padding: 16px;
  `;

  return <StyledGrid {...otherProps}>{children}</StyledGrid>;
}
