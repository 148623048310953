import { Typo } from '@digital-at-vallourec/steel-design-system-react';
import styled from '@emotion/styled';
//import { Search } from '@mui/icons-material';
import {
  Button,
  FormControl, // IconButton,
  // InputAdornment,
  // OutlinedInput,
  Stack,
} from '@mui/material';
import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { $grey20, $primaryBrand, $primaryWhite } from '../../../styles/colors';
import { LeftBar } from '../LeftBar/LeftBar';
import './VamFilter.scss';

interface VamFilterProps {
  title: string;
  subTitle: string;
  handleApplyFilter: Function;
  children: ReactNode;
  minHeight?: number;
  disableApplyBtn?: boolean;
  handleApplyReset?: Function;
  defaultValues?: any;
}

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

export function VamFilter({
  title,
  subTitle,
  handleApplyFilter,
  minHeight,
  children,
  disableApplyBtn,
  handleApplyReset,
  defaultValues,
}: VamFilterProps) {
  const { t } = useTranslation('common', { keyPrefix: 'filter' });
  const minHeightFilter = minHeight || 308;
  const { reset } = useFormContext();

  const handleReset = () => {
    defaultValues ? reset(defaultValues) : reset();
    handleApplyReset && handleApplyReset();
    handleApplyFilter();
  };

  return (
    <div className="VamFilter tw-flex-col" style={{ minHeight: minHeightFilter }}>
      <Stack className="tw-py-6" direction="row" justifyContent="space-between">
        <Stack direction="row">
          <LeftBar color={$primaryWhite} />
          <Stack direction="column">
            <Typo color={$primaryWhite} variant="sectionTitle">
              {title}
            </Typo>
            <Typo color={$grey20} variant="sectionTitleDescription">
              {subTitle}
            </Typo>
          </Stack>
        </Stack>
        <Stack direction="row" justifyItems="center">
          <FormControl
            sx={{
              width: '460',
              borderRadius: '4px',
              margin: '0 16px',
            }}
            variant="outlined"
          >
            {/* <OutlinedInput
              // TODO create an event after 4 chars
              id="outlined-global-search-filter"
              startAdornment={
                <InputAdornment position="start">
                  <IconButton edge="start">{<Search />}</IconButton>
                </InputAdornment>
              }
              placeholder={t('search')}
              size="small"
              sx={{
                backgroundColor: $primaryWhite,
              }}
            /> */}
          </FormControl>
        </Stack>
      </Stack>
      <Stack justifyContent="space-between" direction="column" minHeight={minHeightFilter - 100}>
        <div className="tw-px-4">{children}</div>
        <ButtonsContainer>
          <Button
            variant="outlined"
            data-testid="vam-filter-reset-btn"
            sx={{
              backgroundColor: $primaryWhite,
              color: $primaryBrand,
              width: '200px',
            }}
            onClick={handleReset}
          >
            {t('reset')}
          </Button>
          <Button
            data-testid="vam-filter-apply-btn"
            onClick={() => handleApplyFilter()}
            disabled={disableApplyBtn}
            sx={{
              width: '200px',
            }}
          >
            {t('apply')}
          </Button>
        </ButtonsContainer>
      </Stack>
    </div>
  );
}
