import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { RouteProps, useLocation, useNavigate } from 'react-router-dom';

import { IdTokenClaims } from '../../interfaces/auth0';
import { checkAuthorizationAccess, redirectTo } from '../../utils/functions/helpers';

type AuthorizedRoleGuardProps = RouteProps & {
  requiredPermissions: string[]; // Define the roles in props
  element: React.ReactNode;
};

export function AuthorizedRoleGuard({ element, requiredPermissions }: AuthorizedRoleGuardProps) {
  const { getIdTokenClaims, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthorized, setIsAuthorized] = React.useState(false);

  useEffect(() => {
    async function getPermissionsFromClaims(): Promise<Array<string>> {
      const claims = (await getIdTokenClaims()) as IdTokenClaims;
      return claims?.mypermissions || [];
    }

    async function checkAuthorization(requiredPermissions: string[]) {
      // Check if the user is authenticated
      if (!isAuthenticated) {
        // Redirect to home page if not authenticated
        redirectTo('/', navigate, {
          state: { from: location },
        });
        return;
      }

      try {
        // Get user claims including a permissions
        const permissionsFromClaims = await getPermissionsFromClaims();
        const isAuthorized = checkAuthorizationAccess(permissionsFromClaims, requiredPermissions);

        if (!isAuthorized) {
          redirectTo('/not-authorized', navigate);
        } else {
          setIsAuthorized(true);
        }
      } catch (error) {
        // Handle error if unable to get user claims
        console.error('Error getting user claims:', error);
      }
    }
    checkAuthorization(requiredPermissions);
  }, [getIdTokenClaims, isAuthenticated, navigate]);

  return isAuthorized ? element : <div>You are not authorized to access this page.</div>;
}
