import { Grid } from '@mui/material';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useIsFirstMount } from '../../../hooks';
import { useSelectOrFetchPuAddress } from '../../../hooks/useSelectOrFetchPuAddress';
import { DistinctConnectionLabelValue } from '../../../interfaces/distinct-product-label-value';
import { getDistinctBookingFilters } from '../../../services/api/veg-api';
import { globalDistinctConnection } from '../../../services/functions/globalDistinctConnection';
import { selectUser } from '../../../services/store/userSlice';
import { $primaryWhite } from '../../../styles/colors';
import { BookingStatus, businessNumberListRegex } from '../../../utils';
import {
  mapToProductFilter,
  mapToProductFilterFromArrays,
  removeFalsyProperty,
  removeFalsyPropertyFromArrays,
  toLabelValues,
} from '../../../utils/functions';
import { FormDatePicker, FormTextField } from '../../shared';
import { FormMultiSelect } from '../../shared/form-components/FormMultiSelect';

export function GlobalSearchFilter() {
  const { t } = useTranslation('gauge');
  const user = useAppSelector(selectUser);
  const firstMount = useIsFirstMount();
  const { puAddressesLabelValue } = useSelectOrFetchPuAddress();
  const { control } = useFormContext();
  const { replace } = useFieldArray({
    control: control,
    name: 'gauge_location_id',
  });
  const [distinctBookingConnectionOptions, setDistinctBookingConnectionOptions] = React.useState({
    connection: [],
    od_inch: [],
    end: [],
    weight_thickness: [],
    application: [],
    option: [],
    status: [],
    licensee_number: [],
    gauge_location_id: [],
    period_end: null,
    period_start: null,
  } as DistinctConnectionLabelValue);

  const statusOptions = toLabelValues(
    BookingStatus,
    'manageBookingDetail.changeStatusDialog.status.'
  );

  React.useEffect(() => {
    if (firstMount && user.depotId) {
      replace([user.depotId]);
    }
    const fetchData = async () => {
      try {
        await handleChangeDistinctConnection();
      } catch (error) {
        /* istanbul ignore next */
        console.error('🚀 ~ file: GlobalSearchFilter.tsx ~ fetchData ~ error', error);
      }
    };
    fetchData();
  }, [control._formValues]);

  const handleChangeDistinctConnection = React.useCallback(async () => {
    const { _formValues } = control;
    let filter = {
      ...removeFalsyPropertyFromArrays(
        mapToProductFilterFromArrays(_formValues, [
          'licensee_number',
          'status',
          'weight_thickness',
          'gauge_location_id',
        ]) as Record<string, any[]>
      ),
    };
    filter = {
      ...filter,
      ...removeFalsyProperty(
        mapToProductFilter(
          { period_start: _formValues.period_start, period_end: _formValues.period_end },
          ['period_start', 'period_end']
        )
      ),
    };

    const connection = (await globalDistinctConnection(getDistinctBookingFilters, filter))
      ?.connection;
    setDistinctBookingConnectionOptions(connection);
  }, []);

  return (
    <Grid
      sx={{
        '&.MuiGrid-root': {
          backgroundColor: 'inherit',
        },
        '.MuiTextField-root .MuiOutlinedInput-root': {
          height: 39,
          backgroundColor: $primaryWhite,
        },
      }}
      container
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      <Grid item xs={2}>
        <FormMultiSelect
          id="licensee-number-select"
          name="licensee_number"
          label={`${t('details.licensee')}`}
          options={distinctBookingConnectionOptions?.licensee_number}
          onClose={handleChangeDistinctConnection}
        />
      </Grid>
      <Grid item xs={2}>
        <FormDatePicker
          id="period-start-select"
          inputLabel="Period start"
          variant="outlined"
          name="period_start"
          dataTestId="period_start-test-id"
          onClose={handleChangeDistinctConnection}
        />
      </Grid>
      <Grid item xs={2}>
        <FormDatePicker
          id="period-end-select"
          inputLabel="Period end"
          variant="outlined"
          name="period_end"
          dataTestId="period_end-test-id"
          onClose={handleChangeDistinctConnection}
        />
      </Grid>
      <Grid item xs={2}>
        <FormMultiSelect
          id="connection-select"
          name="connection"
          label={`${t('details.connection')}`}
          options={distinctBookingConnectionOptions?.connection}
          onClose={handleChangeDistinctConnection}
        />
      </Grid>

      <Grid item xs={2}>
        <FormMultiSelect
          id="od-select"
          name="od_inch"
          label={`${t('details.od')}`}
          options={distinctBookingConnectionOptions?.od_inch}
          onClose={handleChangeDistinctConnection}
        />
      </Grid>

      <Grid item xs={2}>
        <FormMultiSelect
          id="weight-thickness-select"
          name="weight_thickness"
          label={`${t('details.weightTickness')}`}
          options={distinctBookingConnectionOptions?.weight_thickness}
          onClose={handleChangeDistinctConnection}
        />
      </Grid>

      <Grid item xs={2}>
        <FormMultiSelect
          id="end-select"
          name="end"
          label={t('details.end')}
          options={distinctBookingConnectionOptions?.end}
          onClose={handleChangeDistinctConnection}
        />
      </Grid>

      <Grid item xs={2}>
        <FormMultiSelect
          id="application-select"
          name="application"
          label={t('details.application')}
          options={distinctBookingConnectionOptions?.application}
          onClose={handleChangeDistinctConnection}
        />
      </Grid>

      <Grid item xs={2}>
        <FormMultiSelect
          id="status-select"
          name="status"
          label={t('globalSearchBooking.status')}
          options={statusOptions}
          nsTranslate="gauge"
        />
      </Grid>

      <Grid item xs={2}>
        <FormMultiSelect
          id="depot-select"
          name="gauge_location_id"
          label={t('details.depot')}
          options={puAddressesLabelValue}
          defaultSelectedOptions={[user.depotId]}
        />
      </Grid>

      <Grid item xs={2}>
        <FormTextField
          id="business-number-text-fields"
          withFixedLabel
          inputProps={{
            'data-testid': 'list-business-number-input',
          }}
          labelValue={t('details.businessNumber')}
          name="business_number"
          fullWidth
          patternValue={businessNumberListRegex}
          patternMessage="Error syntax"
          placeholder={t('details.businessNumberPlaceholder')}
        />
      </Grid>
    </Grid>
  );
}
