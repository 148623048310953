import { Spinner } from '@digital-at-vallourec/steel-design-system-react';
import { Backdrop } from '@mui/material';

import { $primaryWhite } from '../../../styles/colors';

export function PageLoader() {
  return (
    <Backdrop
      sx={{ color: $primaryWhite, zIndex: (theme) => theme.zIndex.drawer + 1000 }}
      open={true}
      data-testid="page-loader-date-testid"
    >
      <Spinner />
    </Backdrop>
  );
}
