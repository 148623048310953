import { SimpleButton } from '@digital-at-vallourec/steel-design-system-react';
import { DeleteOutlined } from '@mui/icons-material';
import { GridColDef, GridRowId, GridRowSelectionModel } from '@mui/x-data-grid';
import { useEffect, useRef } from 'react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CaseIcon from '../../assets/icons/icon-case.svg';
import { VegCase } from '../../interfaces/case';
import { getCasesListing } from '../../services/api/case-api';
import { VegWebsiteStatus } from '../../utils';
import { CaseStatus } from '../../utils/constants/case';
import { formatDate } from '../../utils/functions';
import { VamDataGrid } from '../data-grid/VamDataGrid';
import { GridStatus } from '../data-grid/utils';
import { ContainerCard, GridOverlayContainer } from '../shared';
import { DialogComponent } from './DialogComponent';
import { updateSelectedCase } from './utils';

export interface CaseProps {
  case?: VegCase;
  websiteStatus?: VegWebsiteStatus;
}

export function CaseModel(props: CaseProps) {
  const { t } = useTranslation('gauge', { keyPrefix: 'setModel.caseModel' });
  const [searchCases, setSearchCases] = React.useState<VegCase[]>([]);
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
  const [applyCases, setApplyCases] = React.useState<VegCase[]>(
    props.case ? [{ ...props.case, id: props.case?.id || props.case?.case_id }] : []
  );
  const { setValue, trigger } = useFormContext();

  const [count, setCountSize] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);

  const selectedCases = useRef<VegCase>(null);

  useEffect(() => {
    if (props.case) {
      setApplyCases([{ ...props.case, id: props.case?.id || props.case?.case_id }]);
    }
  }, [props.case]);

  /* istanbul ignore next */
  const handleOpenDialog = () => {
    setIsOpen(true);
    selectedCases.current = null;
    const fetchData = async () => {
      getCasesListing([CaseStatus.Active])
        .then(({ data: { count, cases } }) => {
          setCountSize(count);
          const dataGrid = cases.map((row, idx) => ({
            ...row,
            modification_date: formatDate(row.modification_date),
            creation_date: formatDate(row.creation_date),
            id: idx,
          }));
          setSearchCases(dataGrid);
        })
        .catch((error) => {
          console.error('🚀 ~ file: CaseModel.tsx ~ fetch ~ getCasesListing ~ error', error);
        });
    };

    fetchData();
  };

  /* istanbul ignore next */
  const caseCols: GridColDef[] = [
    {
      field: 'designation',
      headerName: 'DESIGNATION',
      flex: 1,
    },
    { field: 'length', headerName: 'LENGTH (m)', flex: 1 },
    { field: 'height', headerName: 'HEIGHT (m)', flex: 1 },
    { field: 'width', headerName: 'WIDTH (m)', flex: 1 },
    {
      field: 'weight',
      headerName: 'WEIGHT (kg)',
      flex: 1,
    },
  ];

  /* istanbul ignore next */
  const handleCloseDialog = () => {
    setIsOpen(false);
    setSelectionModel([]);
  };

  /* istanbul ignore next */
  const handleConfirmSelection = () => {
    if (selectedCases.current) {
      const appliedApp = [selectedCases.current].map((item, idx) => ({
        ...item,
        id: idx,
      }));
      updateSelectedCase(setApplyCases, setValue, trigger, appliedApp);
    }
    selectedCases.current = null;
    handleCloseDialog();
  };

  /* istanbul ignore next */
  const handleRemoveRow = (id: number) => {
    const appls = applyCases.filter((item) => item.id !== id);
    updateSelectedCase(setApplyCases, setValue, trigger, appls);
  };

  /* istanbul ignore next */
  const handleSelectionModelChange = (selectionIndex: GridRowSelectionModel) => {
    if (selectionIndex.length > 1) {
      const selectionSet = new Set(selectionModel);
      const result = selectionIndex.filter((s) => !selectionSet.has(s));
      setSelectionModel(result);
    } else {
      setSelectionModel(selectionIndex);
    }
    const lastCaseSelected: VegCase = searchCases[selectionIndex[selectionIndex.length - 1]];
    selectedCases.current = lastCaseSelected;
  };

  return (
    <ContainerCard
      title={t('title')}
      icon={CaseIcon}
      subTitle={t('subtitle')}
      width="100%"
      maxWidth="100%"
      containerCardClass="bg-color-white"
      actionHeaderNode={
        <DialogComponent
          isOpen={isOpen}
          searchLabelBtn={t('searchCases')}
          title={t('dialogTitle')}
          subTitle={t('dialogSubTitle')}
          fullWidth
          confirmSelection={handleConfirmSelection}
          handleCloseDialog={handleCloseDialog}
          handleOpenDialog={handleOpenDialog}
          buttonProps={{ disabled: props.websiteStatus !== VegWebsiteStatus.Draft }}
        >
          <VamDataGrid
            sx={{
              '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                display: 'none',
              },
            }}
            height={400}
            checkboxSelection={true}
            disableRowSelectionOnClick
            showRowLeftThickBorder
            pageSize={50}
            disableColumnFilter
            rowCount={count}
            columns={caseCols}
            rows={searchCases}
            rowSelectionModel={selectionModel}
            // function for myVam
            onRowSelectionModelChange={handleSelectionModelChange}
          />
        </DialogComponent>
      }
    >
      <VamDataGrid
        rows={applyCases.map((row) => ({ ...row, grid_status: GridStatus.OK }))}
        showRowLeftThickBorder
        columns={caseCols.concat([
          {
            field: 'action',
            align: 'center',
            flex: 0.5,
            renderHeader: () => <></>,
            renderCell: (params) => (
              <SimpleButton
                size="small"
                variant="remove-red"
                disabled={props.websiteStatus !== VegWebsiteStatus.Draft}
                onClick={() => handleRemoveRow(params.id as number)}
              >
                <DeleteOutlined fontSize="small" />
              </SimpleButton>
            ),
          },
        ])}
        height={180}
        disableRowSelectionOnClick
        hideFooter
        slots={{
          noRowsOverlay: () => <GridOverlayContainer text={t('noRowsLabel')} />,
        }}
      ></VamDataGrid>
    </ContainerCard>
  );
}
