import { SectionTitle } from '@digital-at-vallourec/steel-design-system-react';
import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PickupAddress } from '../../interfaces/address';
import { getDateFormat } from '../../utils/functions';
import { ContainerCard, FormTextField } from '../shared';

interface GeneralInformationsProps {
  data: PickupAddress;
}

export function GeneralInformations({ data }: GeneralInformationsProps) {
  const { t } = useTranslation('gauge_depot');

  return (
    <ContainerCard
      width="100%"
      maxWidth="100%"
      height="100%"
      avatarHeaderNode={
        <SectionTitle
          title={t('updateDepot.generalInfosTitle')}
          description={t('updateDepot.generalInfosTag')}
        />
      }
    >
      <Grid container spacing={2} direction="column" lineHeight={0}>
        <Grid item xs={6}>
          <FormTextField
            id="depot-created-by"
            name="created_by"
            label={t('createDepot.depotInformation.form.createdBy')}
            inputDataTestId="depot-created-by-textfield"
            disabled={true}
            value={data.created_by || '-'}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            id="depot-created-on"
            name="creation_date"
            label={t('createDepot.depotInformation.form.creationDate')}
            inputDataTestId="creation-date-depot-textfield"
            disabled={true}
            value={data.creation_date ? getDateFormat(new Date(data.creation_date), false) : '-'}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            id="depot-modified-by"
            name="modified_by"
            label={t('createDepot.depotInformation.form.modifiedBy')}
            inputDataTestId="depot-modified-by-textfield"
            disabled={true}
            value={data.modified_by || '-'}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            id="depot-modified-on"
            name="modification_date"
            label={t('createDepot.depotInformation.form.modificationDate')}
            inputDataTestId="modification-date-depot-textfield"
            disabled={true}
            value={
              data.modification_date ? getDateFormat(new Date(data.modification_date), false) : '-'
            }
          />
        </Grid>
      </Grid>
    </ContainerCard>
  );
}
