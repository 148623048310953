import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import SupportIcon from '../../../assets/icons/help-support.svg';
import { $primaryNavy, $primaryWhite } from '../../../styles/colors';
import { CONTACT_US_LINK } from '../../../utils/constants/footer';
import { ContainerOutlet } from '../ContainerOutlet/ContainerOutlet';
import styles from './HelpAndSupport.module.scss';

export function HelpAndSupport() {
  const { t } = useTranslation('common', { keyPrefix: 'help' });

  return (
    <ContainerOutlet>
      <div className={`${styles.HelpAndSupport} tw-flex-col tw-text-center`}>
        <div className="tw-pt-2">
          <img src={SupportIcon} alt="support-icon-vam" />
        </div>
        <div>{t('title')}</div>
        <div>{t('text')}</div>
        <div className="tw-inline-flex tw-py-6 tw-m-auto">
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              size="small"
              sx={{
                backgroundColor: $primaryWhite,
                color: $primaryNavy,
                borderColor: $primaryWhite,
              }}
            >
              {t('access')}
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{
                color: $primaryWhite,
                borderColor: $primaryWhite,
              }}
              onClick={() => window.open(CONTACT_US_LINK, '_blank')}
            >
              {t('contact')}
            </Button>
          </Stack>
        </div>
      </div>
    </ContainerOutlet>
  );
}
