/* istanbul ignore file */
import axios from 'axios';

import { handleAxiosError, handleAxiosRequest, handleAxiosResponse } from './functions';

// It is not recommended to use getAccessTokenSilently()
// globally or outside of the component where it is needed.
// The getAccessTokenSilently() function is designed to be used
// within a specific
// just we have to test if refresh token ll work to BE TESTED

const vegHttp = axios.create({
  baseURL: process.env.REACT_APP_VEG_API,
  withCredentials: true,
});

const vegHttpWithSpinner = axios.create({
  baseURL: process.env.REACT_APP_VEG_API,
  withCredentials: true,
});

// Do something before request is sent
vegHttpWithSpinner.interceptors.request.use(
  // TODO handle this
  (config) => handleAxiosRequest(config),
  (error) => handleAxiosError(error)
);

// Do something with response
vegHttpWithSpinner.interceptors.response.use(
  (response) => handleAxiosResponse(response),
  (error) => handleAxiosError(error)
);

export { vegHttp, vegHttpWithSpinner };
