import { Grid, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useAppSelector } from '../../hooks';
import { selectUser } from '../../services/store/userSlice';
import { $lightBlue } from '../../styles/colors';
import { mappingUserToState } from '../../utils/functions/user';
import { ContainerOutlet } from '../layout/ContainerOutlet/ContainerOutlet';
import { VamPageTitle } from '../shared';
import AllowedPayment from './AllowedPayment';
import GeneralInformations from './GeneralInformations';
import UserRights from './UserRights';

export function ManageSingleUser() {
  const location = useLocation();
  const selectedUser = location?.state?.user ? mappingUserToState(location.state.user) : null;
  const connectedUser = useAppSelector(selectUser);
  const [user, setUser] = React.useState(selectedUser || connectedUser);
  const { t } = useTranslation('user', { keyPrefix: 'manageSingleUser' });

  const isKeyUser: boolean = connectedUser.keyContactId === connectedUser.idUserMyVam;
  const isMyProfile: boolean =
    connectedUser && (!selectedUser || selectedUser.idUserMyVam === connectedUser.idUserMyVam);

  // Display My Users in breadcrumb only if key user
  const breadcrumbRoutes = [
    {
      breadcrumb: t('title', {
        context: isKeyUser && !isMyProfile ? 'keyUser' : 'user',
      }),
      path: '#',
    },
  ];

  if (isKeyUser) {
    breadcrumbRoutes.unshift({
      // unshift add element at beginning of list
      breadcrumb: 'My Users',
      path: '/vam-easy-gauge/licensee-users',
    });
  }

  return (
    <ContainerOutlet>
      <Stack direction="row" justifyContent="space-between" className="tw-mb-6">
        <Stack direction="row" spacing={2}>
          <VamPageTitle
            subTitle={t('tag')}
            title={t('title', {
              context: isKeyUser && !isMyProfile ? 'keyUser' : 'user',
            })}
            breadcrumbRoutes={breadcrumbRoutes}
          />
        </Stack>
      </Stack>
      <Grid
        container
        rowSpacing={4}
        columnSpacing={{ xs: 2, sm: 3, md: 4 }}
        style={{
          backgroundColor: $lightBlue,
        }}
      >
        <Grid item xs={6}>
          <div className="tw-flex tw-flex-col tw-gap-4 tw-h-full">
            <GeneralInformations user={user} />
            <AllowedPayment />
          </div>
        </Grid>
        <Grid item xs={6}>
          <UserRights user={user} updateUser={setUser} />
        </Grid>
      </Grid>
    </ContainerOutlet>
  );
}

export default ManageSingleUser;
