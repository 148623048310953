import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { LicenseeQualifications } from '../../interfaces/context';
import * as contextAPI from '../api/context-api';
import { RootState } from './store';

/**
 * state model
 */
export interface LicenseeContextState {
  qualifications: LicenseeQualifications;
  areQualificationsLoaded: boolean;
}

/**
 * set initial state
 */
const initialState: LicenseeContextState = {
  qualifications: {
    products: [],
    processes: [],
    documentations: [],
  },
  areQualificationsLoaded: false,
};

/**
 * selectors for this slice
 */
export const selectAreQualificationsLoaded = (state: RootState) =>
  state.licenseeContext?.areQualificationsLoaded;
export const selectQualifications = (state: RootState) => state.licenseeContext?.qualifications;
export const selectProductQualifications = (state: RootState) =>
  state.licenseeContext?.qualifications.products;
export const selectProcessQualifications = (state: RootState) =>
  state.licenseeContext?.qualifications.processes;
export const selectDocumentationQualifications = (state: RootState) =>
  state.licenseeContext?.qualifications.documentations;

export const fetchQualificationsThunk = createAsyncThunk<
  LicenseeQualifications,
  number,
  {
    rejectValue: string;
  }
>('qualification/fetchLicenseeQualifications', async (licenseeNumber, thunkApi) => {
  try {
    const products = await contextAPI.getProductQualifications(licenseeNumber);
    const processes = await contextAPI.getProcessQualifications(licenseeNumber);
    const documentations = await contextAPI.getDocumentationQualifications(licenseeNumber);
    // return value becomes the `fulfilled` action payload
    return {
      products: products.data,
      processes: processes.data,
      documentations: documentations.data,
    };
  } catch (error) {
    // return error becomes the `rejected` action payload
    // axios error?
    if (error instanceof AxiosError && error.response) {
      // return error message
      return thunkApi.rejectWithValue(`${error.response.data}`);
    }
    // return error
    throw error;
  }
});

/**
 * slice
 */
export const licenseeQualificationsSlice = createSlice({
  name: 'licenseeContext',
  initialState,
  reducers: {
    setLicenseeQualifications: (state, action: PayloadAction<LicenseeQualifications>) => {
      state.qualifications = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetch consignee addresses request
    // -- successfull
    builder.addCase(fetchQualificationsThunk.fulfilled, (state, action) => {
      // update state
      state.qualifications = action.payload;
      state.areQualificationsLoaded = true;
    });
    // -- failure
    builder.addCase(fetchQualificationsThunk.rejected, (state) => {
      // reset state
      state.qualifications = {
        products: [],
        processes: [],
        documentations: [],
      };
      state.areQualificationsLoaded = false;
    });
  },
});

/**
 * actions for this slice
 */
export const { setLicenseeQualifications } = licenseeQualificationsSlice.actions;
