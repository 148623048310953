import {
  InformationBox,
  SectionTitle,
  Switch,
} from '@digital-at-vallourec/steel-design-system-react';
import { Box, CircularProgress } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks';
import { useSelectOrFetchAuth0Roles } from '../../hooks/useSelectOrFetchAuth0Roles';
import { UserDetails, UserMyVamRole } from '../../interfaces/user';
import { updateUserRole } from '../../services/api/user-api';
import { selectUser } from '../../services/store/userSlice';
import { $grey40, $primaryNavy } from '../../styles/colors';
import { ContainerCard } from '../shared';

interface UserRightsProps {
  user: UserDetails;
  updateUser: Function;
}

export default function UserRights({ user, updateUser }: UserRightsProps) {
  const { t } = useTranslation('user', { keyPrefix: 'manageSingleUser' });
  const { t: tCommon } = useTranslation('common');
  // Used to display a loader during auth0 call (to grant, revoke roles to user)
  const [loader, setLoader] = React.useState<any>({ loading: false, role: '' });
  const connectedUser = useAppSelector(selectUser);
  const isKeyUser = connectedUser.keyContactId === connectedUser.idUserMyVam;
  // Key user cannot update its roles himself
  const keyUserUpdateHimself = isKeyUser && user.idUserMyVam === connectedUser.idUserMyVam;
  const { auth0Roles } = useSelectOrFetchAuth0Roles();

  /* istanbul ignore next */
  const handleSwitchChange = (checked: boolean, role: UserMyVamRole) => {
    let updatedRoles = [...user.roles];

    if (checked) {
      if (!updatedRoles.includes(role.name)) {
        updatedRoles.push(role.name);
      }
    } else {
      updatedRoles = updatedRoles.filter((r) => r !== role.name);
    }
    setLoader({ loading: true, role: role.name });
    updateUserRole({
      auth0_id: user.auth0Id,
      licensee_number: user.licenseeNumber,
      role_id: role.id,
      granted: checked,
    })
      .then(() => {
        setLoader({ loading: false, role: '' });
        updateUser({ ...user, roles: updatedRoles });
        enqueueSnackbar(
          t('userRights.notification.success', {
            role: tCommon(`roles.${role.name}`),
            granted: checked ? 'granted' : 'removed',
            prefix: checked ? 'to' : 'from',
            user: `${user.firstName} ${user.lastName.toUpperCase()}`,
          }),
          {
            preventDuplicate: true,
            variant: 'success',
          }
        );
      })
      .catch(function (error) {
        setLoader({ loading: false, role: '' });
        enqueueSnackbar(t('userRights.notification.error'), {
          preventDuplicate: true,
          variant: 'error',
        });
        console.error('🚀 ~ file: UserRights.tsx ~ handleSwitchChange ~ error', error);
      });
  };

  return (
    <ContainerCard
      width="100%"
      maxWidth="100%"
      height="100%"
      avatarHeaderNode={
        <SectionTitle
          title={t('userRights.title')}
          description={t('userRights.subtitle', {
            verb: isKeyUser ? 'manage' : 'see',
          })}
        />
      }
      dataTestId="manage-user-user-rights"
    >
      {!auth0Roles ? (
        <div className="tw-flex tw-w-full tw-items-center tw-justify-center">
          <CircularProgress />
        </div>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            maxHeight: '350px',
            overflowY: 'auto',
          }}
        >
          {/* First we display all the user's assigned roles */}
          {auth0Roles
            ?.filter((role) => user.roles?.includes(role.name))
            ?.map((role, idx) => (
              <InformationBox
                key={idx}
                data={[
                  {
                    title: tCommon(`roles.${role.name}`),
                    name: role.description,
                  },
                ]}
                justifyContentChildren="space-between"
                color={$primaryNavy}
              >
                {/* The switch is already checked because the role is included
                  in the user roles. The switch is only displayed to the key user */}
                {isKeyUser &&
                  role.name !== 'licensee-key-user' &&
                  !keyUserUpdateHimself &&
                  (loader.loading && loader.role === role.name ? (
                    <CircularProgress size={30} />
                  ) : (
                    <Switch
                      checked
                      onChange={
                        /* istanbul ignore next */ (_, checked: boolean) =>
                          handleSwitchChange(checked, role)
                      }
                      disabled={loader.loading}
                    />
                  ))}
              </InformationBox>
            ))}
          {/* Then here, for the key user, we display all his roles and so
            the ones he can assign to the user */}
          {isKeyUser &&
            user &&
            auth0Roles
              ?.filter(
                (role) =>
                  connectedUser.roles.includes(role.name) &&
                  !user.roles.includes(role.name) &&
                  role.name !== 'licensee-key-user'
              )
              .map((role, idx) => (
                <InformationBox
                  key={idx}
                  data={[
                    {
                      title: tCommon(`roles.${role.name}`),
                      name: role.description,
                    },
                  ]}
                  justifyContentChildren="space-between"
                  color={$grey40}
                >
                  {loader.loading && loader.role === role.name ? (
                    <CircularProgress size={30} />
                  ) : (
                    <Switch
                      onChange={(_, checked: boolean) => handleSwitchChange(checked, role)}
                      disabled={!user.auth0Id || loader.loading}
                    />
                  )}
                </InformationBox>
              ))}
        </Box>
      )}
    </ContainerCard>
  );
}
