import { GridStatus } from '../../components/data-grid/utils';
import { $darkGreen, $orange, $primaryBrand, $yellow } from '../../styles/colors';

export enum PaymentStatus {
  SUCCEEDED = 'succeeded',
  PROCESSING = 'processing',
  DECLINED = 'declined',
  CANCELED = 'canceled',
}

export enum InvoiceType {
  NEW_TOKEN = 'new_token',
  BOOKING = 'booking',
  ROYALTIES = 'royalties',
  GPS = 'gps',
}

export enum PaymentMethod {
  CREDIT_CARD = 'credit_card',
  CLASSIC = 'classic',
}

export enum InvoiceStatus {
  TO_BE_TREATED = 'to_be_treated',
  L2_GENERATED = 'l2_generated',
  PAID = 'paid',
  READY = 'ready',
}

export const INVOICE_STATUS_COLORS = {
  [InvoiceStatus.TO_BE_TREATED]: $yellow,
  [InvoiceStatus.READY]: $orange,
  [InvoiceStatus.L2_GENERATED]: $primaryBrand,
  [InvoiceStatus.PAID]: $darkGreen,
};

export const INVOICE_STATUS_GRID_STATUS = {
  [InvoiceStatus.TO_BE_TREATED]: GridStatus.YELLOW,
  [InvoiceStatus.READY]: GridStatus.EXPIRE,
  [InvoiceStatus.L2_GENERATED]: GridStatus.STANDARD,
  [InvoiceStatus.PAID]: GridStatus.OK,
};

export const ADMIN_FEE = 245;

export interface StatusFilters {
  all_statuses?: InvoiceStatus[];
  current_status?: InvoiceStatus;
}
