import { SectionTitle } from '@digital-at-vallourec/steel-design-system-react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ContainerCard, FormTextField } from '..';

export interface CommentsProps {
  inputTestId?: string;
  title?: string;
  subtitle?: string;
  triggerOnBlur?: boolean;
}

const Comments = ({ inputTestId, title, subtitle, triggerOnBlur }: CommentsProps) => {
  const { t } = useTranslation('gauge', { keyPrefix: 'setCreation.commentSection' });
  const methods = useFormContext();

  return (
    <ContainerCard
      width="100%"
      maxWidth="100%"
      noPaddingTop
      dataTestId="comments-container"
      avatarHeaderNode={
        <SectionTitle title={title || t('title')} description={subtitle || t('subtitle')} />
      }
    >
      <FormTextField
        name="comments"
        label={t('label')}
        multiline
        variant="outlined"
        rows={4}
        fullWidth
        data-testid="comments-textfield"
        inputDataTestId={inputTestId}
        onBlur={triggerOnBlur ? () => methods.trigger('comments') : null}
      />
    </ContainerCard>
  );
};

export default Comments;
