import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, { useEffect } from 'react';

import './ScrollToTop.scss';

export const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = React.useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  return (
    <div className="top-to-btm" data-testid="window-scroll-in-top">
      {showTopBtn && (
        <KeyboardArrowUpIcon
          fontSize="large"
          className="icon-position icon-style"
          data-testid="scroll-top-data-testid"
          onClick={goToTop}
        />
      )}
    </div>
  );
};
