import { GridRowSelectionModel } from '@mui/x-data-grid';

export function handleUniqueSelectionModelChange(
  gridData: any[],
  selectionIndex: GridRowSelectionModel
): void {
  // set the checkbox
  gridData.forEach((row) => {
    row.selected = row.id === selectionIndex[selectionIndex.length - 1];
  });
}
