import styled from '@emotion/styled';
import React from 'react';

const LeftBarStyled = styled.div(({ color }) => ({
  width: '8px',
  height: '32px',
  background: `${color} 0% 0% no-repeat padding-box`,
  opacity: 1,
  marginRight: '8px',
}));

export function LeftBar({ color, ...otherProps }: { color: string }) {
  return <LeftBarStyled color={color} {...otherProps} />;
}
