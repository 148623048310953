import { SimpleButton } from '@digital-at-vallourec/steel-design-system-react';
import { MuiButtonProps } from '@digital-at-vallourec/steel-design-system-react/dist/components/atoms/SimpleButton/SimpleButton';
import styled from '@emotion/styled';
import { Close, Search } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

import { $primaryBlack } from '../../styles/colors';
import { ButtonVariantType } from '../data-grid/utils/interfaces/button';
import { VamTitleDialog } from '../shared';

interface DialogComponentProps {
  searchLabelBtn: string;
  title: string;
  subTitle: string;
  confirmLabelBtn?: string;
  handleOpenDialog?: Function;
  handleCloseDialog?: Function;
  confirmSelection?: Function;
  children?: React.ReactNode;
  isOpen?: boolean;
  fullWidth?: boolean;
  icon?: React.ReactNode;
  buttonVariant?: ButtonVariantType;
  buttonProps?: MuiButtonProps;
  withCloseButton?: boolean;
  headerIcon?: React.ReactNode;
  dialogActions?: React.ReactNode;
  dataTestIdButton?: string;
  buttonSize?: 'small' | 'medium' | 'large';
}

const CloseIconButton = styled(IconButton)`
  color: ${$primaryBlack};
  position: absolute;
  right: 0;
  top: 0;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0 6px 0;
`;

export function DialogComponent({
  searchLabelBtn,
  title,
  subTitle,
  handleOpenDialog,
  confirmLabelBtn,
  confirmSelection,
  handleCloseDialog,
  children,
  isOpen,
  fullWidth,
  icon,
  buttonVariant,
  buttonProps,
  withCloseButton,
  headerIcon,
  dialogActions,
  dataTestIdButton,
}: DialogComponentProps) {
  const { t } = useTranslation('common', { keyPrefix: '' });

  /* istanbul ignore next */
  const handleClickOpen = () => {
    handleOpenDialog && handleOpenDialog();
  };

  /* istanbul ignore next */
  const handleClose = () => {
    handleCloseDialog && handleCloseDialog();
  };

  return (
    <div>
      <div>
        <SimpleButton
          data-testid={dataTestIdButton || 'dialog-selection-btn-open'}
          variant={buttonVariant || 'cta'}
          onClick={handleClickOpen}
          startIcon={icon || <Search />}
          {...buttonProps}
        >
          {searchLabelBtn}
        </SimpleButton>
      </div>

      <Dialog
        open={isOpen}
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth="lg"
        PaperProps={{
          style: {
            backgroundColor: '#fff',
          },
        }}
      >
        <DialogTitle>
          {headerIcon ? <IconContainer>{headerIcon}</IconContainer> : null}
          <VamTitleDialog title={title} subTitle={subTitle}></VamTitleDialog>
        </DialogTitle>

        <DialogContent>{children}</DialogContent>
        {withCloseButton ? (
          <CloseIconButton data-testid="dialog-close-button" onClick={handleClose}>
            <Close />
          </CloseIconButton>
        ) : null}

        {dialogActions ? (
          dialogActions
        ) : (
          <DialogActions
            sx={{
              display: 'block',
            }}
          >
            <div className="tw-text-center">
              <SimpleButton
                data-testid="dialog-confirm-selection-btn"
                variant="cta"
                size="small"
                id="confirm-selection-id"
                onClick={() => {
                  confirmSelection();
                }}
              >
                {confirmLabelBtn || t('validation.confirmSelection')}
              </SimpleButton>
            </div>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
