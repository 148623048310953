import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';

import { IdTokenClaims } from '../interfaces/auth0';

export function useSelectRoleBasedAccessControl() {
  const { getIdTokenClaims, isAuthenticated } = useAuth0();
  const [myroles, setMyroles] = React.useState<string[]>([]);
  const [mypermissions, setMypermissions] = React.useState<string[]>([]);

  React.useEffect(() => {
    async function getRbacFromClaims(): Promise<void> {
      const claims = (await getIdTokenClaims()) as IdTokenClaims;
      setMyroles(claims?.myroles || []);
      setMypermissions(claims?.mypermissions || []);
    }

    getRbacFromClaims();
  }, [isAuthenticated, getIdTokenClaims]);

  return { isAuthenticated, myroles, mypermissions };
}
