import { AsyncFunction } from '../../interfaces/api';
import { toOptionsFilters } from '../../utils/functions';

export async function globalDistinctConnection(
  fetchDistinctAsync: AsyncFunction<any, any>,
  filter: Record<string, any>
) {
  const { data } = await fetchDistinctAsync(filter);
  const connection = toOptionsFilters(data);

  return { connection };
}
