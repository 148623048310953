import { GridStatus } from '../../components/data-grid/utils';
import {
  $darkGreen,
  $darkRed,
  $grey50,
  $orange,
  $primaryBrand,
  $primaryNavy,
} from '../../styles/colors';

export enum BookingStatus {
  NewOrder = 'new_order',
  UnderPreparation = 'under_preparation',
  WaitingForCollection = 'waiting_for_collection',
  ShipmentOngoing = 'shipment_ongoing',
  Delivered = 'delivered',
  ReturnOngoing = 'return_ongoing',
  ReturnReceived = 'return_received',
  AvailableInOtherSite = 'available_in_other_site',
  ManufacturingGauge = 'manufacturing_gauge',
  ToBeExtended = 'to_be_extended',
  Terminated = 'terminated',
  // other status for some reasons
  WaitForInformation = 'wait_for_information',
  NotAvailable = 'not_available',
  GpsUnderPreparation = 'gps_under_preparation',
  Gps = 'gps',
  Canceled = 'canceled',
  Undefined = 'undefined',
  // status for replacement
  ReplacementRequested = 'replacement_requested',
  ReplacementDone = 'replacement_done',
}

export const BOOKING_STATUS_COLORS = {
  new_order: $orange, // expire in grid status
  under_preparation: $primaryBrand,
  waiting_for_collection: $primaryNavy,
  shipment_ongoing: $primaryNavy,
  delivered: $darkGreen, // ok
  return_ongoing: $primaryNavy,
  return_received: $darkGreen,
  available_in_other_site: $orange,
  manufacturing_gauge: $orange,
  terminated: $grey50,
  // other status for some reason: '',
  wait_for_information: $orange,
  not_available: $orange,
  gps_under_preparation: $orange,
  gps: $primaryNavy,
  canceled: $darkRed, // ko
  undefined: $darkRed,
  to_be_extended: $orange,
  replacement_requested: $orange,
  replacement_done: $primaryBrand,
};

export const BOOKING_STATUS_GRID_MAPPING = {
  new_order: GridStatus.EXPIRE,
  under_preparation: GridStatus.STANDARD,
  waiting_for_collection: GridStatus.NAVY,
  shipment_ongoing: GridStatus.NAVY,
  delivered: GridStatus.OK,
  return_ongoing: GridStatus.NAVY,
  return_received: GridStatus.OK,
  available_in_other_site: GridStatus.EXPIRE,
  manufacturing_gauge: GridStatus.EXPIRE,
  terminated: GridStatus.GREY,
  // other status for some reason: '',
  wait_for_information: GridStatus.EXPIRE,
  not_available: GridStatus.EXPIRE,
  gps_under_preparation: GridStatus.EXPIRE,
  gps: GridStatus.NAVY,
  canceled: GridStatus.KO,
  undefined: GridStatus.KO,
};

export interface StatusFilters {
  all_statuses?: BookingStatus[];
  current_status?: BookingStatus | BookingStatus[];
}
