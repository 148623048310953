import { QuickAccessButton } from '@digital-at-vallourec/steel-design-system-react';
import styled from '@emotion/styled';
import { ButtonBaseProps } from '@mui/material';
import React from 'react';

export interface VamQuickAccessButtonProps extends ButtonBaseProps {
  width?: number;
  height?: number;
  left?: number;
  top?: number;
  right?: number;
  bottom?: number;
  title: string;
  subTitle: string;
}

export const VamQuickAccessButton = ({
  width,
  height,
  left,
  right,
  bottom,
  top,
  ...rest
}: VamQuickAccessButtonProps) => {
  const StyledQuickAccessButton = styled(QuickAccessButton)`
    .div-container-hover {
      width: ${`${width}px` || 'auto'};
      height: ${`${height}px` || 'auto'};
      left: ${`${left}px` || 'auto'};
      right: ${`${right}px` || 'auto'};
      bottom: ${`${bottom}px` || 'auto'};
      top: ${`${top}px` || 'auto'};
    }
  `;
  return <StyledQuickAccessButton {...rest} />;
};
