import { Avatar } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { ReactNode } from 'react';

import { $primaryNavy } from '../../../styles/colors';

interface CardProps {
  title?: string;
  stepNumber?: number;
  subTitle?: string;
  width?: number | string;
  maxWidth?: number | string;
  height?: number | string;
  minHeight?: number | string;
  icon?: string;
  children?: ReactNode;
  actionHeaderNode?: ReactNode;
  containerCardClass?: string;
  avatarHeaderNode?: ReactNode;
  noPaddingTop?: boolean;
  dataTestId?: string;
  noHeader?: boolean;
}

export function ContainerCard({
  title,
  stepNumber,
  subTitle,
  width,
  maxWidth,
  minHeight,
  height,
  icon,
  children,
  actionHeaderNode,
  containerCardClass,
  avatarHeaderNode,
  noPaddingTop,
  dataTestId,
  noHeader,
}: CardProps) {
  const avatarHeader = (): ReactNode => {
    if (!noHeader) {
      if (avatarHeaderNode) {
        return avatarHeaderNode;
      } else if (stepNumber) {
        return (
          <Avatar
            sx={{
              bgcolor: $primaryNavy,
              width: 24,
              height: 24,
              fontSize: '0.75rem',
            }}
            aria-label="recipe"
          >
            {stepNumber}
          </Avatar>
        );
      } else {
        return <img src={icon} alt="support-icon-vam" />;
      }
    }
  };

  return (
    <Card
      sx={{
        minHeight: minHeight || 'auto',
        maxWidth: maxWidth || 1308,
        width: width || 'auto',
        height: height || 'auto',
        backgroundColor: '#fff',
        '.MuiCardHeader-root': {
          marginLeft: avatarHeaderNode && '-16px',
        },
        '.MuiCardContent-root': {
          paddingTop: noPaddingTop ? 0 : '',
        },
      }}
      className={containerCardClass}
      data-testid={dataTestId}
    >
      <CardHeader
        avatar={avatarHeader()}
        sx={{
          color: $primaryNavy,
          '.MuiCardHeader-avatar': {
            marginBottom: stepNumber ? '20px' : '10px',
            marginRight: '8px',
          },
          'span.MuiCardHeader-title': {
            fontSize: '1rem',
            fontWeight: 'bold',
          },
          'span.MuiCardHeader-subheader': {
            fontSize: '0.75rem',
          },
        }}
        title={title}
        subheader={subTitle}
        action={actionHeaderNode}
      />
      <CardContent>{children}</CardContent>
      <CardActions disableSpacing></CardActions>
    </Card>
  );
}
