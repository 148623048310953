import { useEffect, useState } from 'react';

import { getGaugesBookingCountByStatus } from '../services/api/veg-api';
import { BookingStatus, DeliveryMode } from '../utils';

export function useSelectOrFetchStatusCount(
  statusFilters: BookingStatus[],
  licenseeNumber: number,
  deliveryMode?: DeliveryMode
) {
  const [statusCount, setStatusCount] = useState<Record<BookingStatus, number> | {}>({});

  useEffect(() => {
    const fetchData = async () => {
      getGaugesBookingCountByStatus(statusFilters, licenseeNumber, deliveryMode).then(
        ({ data }) => {
          const statusCount: Record<BookingStatus, number> | {} = {};
          data?.booking_status_count?.forEach((value) => {
            statusCount[value.booking_status] = value.count;
          });
          statusCount['all_reservations'] = data.total_count;
          setStatusCount(statusCount);
        }
      );
    };

    // fetch booking count
    fetchData();
  }, []);

  return { statusCount };
}
