import { Tooltip } from '@digital-at-vallourec/steel-design-system-react';
import styled from '@emotion/styled';
import InfoIcon from '@mui/icons-material/Info';
import { Box } from '@mui/material';

import { $primaryBlack, $secondaryLessBlue } from '../../../styles/colors';

interface VamInformationBoxProps {
  title: string;
  value: string;
  infoIconTitle?: string;
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: end;
  font-size: 14px;
  color: ${$primaryBlack};
  font-weight: 500;
  letter-spacing: 0;
  margin-top: 4px;
`;

export function VamInformationBox({ title, value, infoIconTitle }: VamInformationBoxProps) {
  return (
    <Box
      sx={{
        width: 416,
        height: 52,
        backgroundColor: $secondaryLessBlue,
        borderRadius: '4px',
      }}
      className="tw-text-xs color-black tw-px-4"
    >
      <div className="tw-py-2 tw-flex tw-justify-end">
        <div className="tw-flex tw-flex-col tw-justify-end">
          <div className="tw-flex tw-justify-end">
            {title}
            {infoIconTitle && (
              <Tooltip arrow placement="top" title={infoIconTitle} variant="light">
                <InfoIcon className="tw-ml-2" fontSize="small" />
              </Tooltip>
            )}
          </div>
          <StyledDiv>{value}</StyledDiv>
        </div>
      </div>
    </Box>
  );
}
