export const OD_MAPPING = {
  1.315: '1.315"',
  1.9: '1.900"',
  2.375: '2 3/8"',
  2.5: '2 1/2"',
  2.75: '2 3/4"',
  2.875: '2 7/8"',
  3.5: '3 1/2"',
  4: '4"',
  4.5: '4 1/2"',
  4.522: '4.522"',
  5: '5"',
  5.167: '5 1/6"',
  5.333: '5 1/3"',
  5.5: '5 1/2"',
  5.528: '5.528"',
  5.55: '5.550"',
  5.75: '5 3/4"',
  6: '6"',
  6.625: '6 5/8"',
  6.875: '6 7/8"',
  7: '7"',
  7.017: '7.017"',
  7.035: '7.035"',
  7.063: '7.063"',
  7.07: '7.070"',
  7.078: '7.078"',
  7.25: '7 1/4"',
  7.625: '7 5/8"',
  7.644: '7.644"',
  7.75: '7 3/4"',
  7.875: '7 7/8"',
  8: '8"',
  8.125: '8 1/8"',
  8.625: '8 5/8"',
  8.75: '8 3/4"',
  9: '9"',
  9.125: '9 1/8"',
  9.375: '9 3/8"',
  9.625: '9 5/8"',
  9.673: '9.673"',
  9.75: '9 3/4"',
  9.875: '9 7/8"',
  9.9: '9.900"',
  9.924: '9 7/8"',
  9.934: '9.934"',
  9.973: '9.973"',
  9.974: '9.974"',
  9.982: '9.982"',
  10: '10"',
  10.05: '10.05"',
  10.125: '10 1/8"',
  10.175: '10.175"',
  10.185: '10.185"',
  10.25: '10 1/4"',
  10.375: '10 3/8"',
  10.5: '10 1/2"',
  10.526: '10.526"',
  10.715: '10.715"',
  10.75: '10 3/4"',
  10.777: '10.777"',
  10.858: '10.858"',
  10.875: '10 7/8"',
  10.947: '10.947"',
  10.951: '10.951"',
  11: '11"',
  11.055: '11.055"',
  11.25: '11.250"',
  11.267: '11.267"',
  11.5: '11 1/2"',
  11.55: '11 1/4"',
  11.75: '11 3/4"',
  11.875: '11 7/8"',
  12: '12"',
  12.063: '12 1/16"',
  12.125: '12 1/8"',
  12.25: '12 1/4"',
  12.75: '12 3/4"',
  12.875: '12 7/8"',
  13.375: '13 3/8"',
  13.408: '13 3/8"',
  13.5: '13 1/2"',
  13.625: '13 5/8"',
  13.75: '13 3/4"',
  13.875: '13 7/8"',
  14: '14"',
  14.025: '14.025"',
  14.15: '14.15"',
  14.34: '14.34"',
  14.5: '14 1/2"',
  14.58: '14.58"',
  15: '15"',
  16: '16"',
  16.08: '16.08"',
  16.15: '16.15"',
  16.2: '16.20"',
  16.25: '16 1/4"',
  17: '17"',
  17.875: '17 7/8"',
  17.9375: '17 15/16"',
  18: '18"',
  18.625: '18 5/8"',
  20: '20"',
  20.891: '20.891"',
  21.5: '21 1/2"',
  22.268: '22.268"',
  24: '24"',
  24.5: '24 1/2"',
  26: '26"',
};
